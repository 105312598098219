import React, { useContext, useState } from "react";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LogoRanus from "../../_assets/image/LogoRanus.png";
import Common from "../../based/Common";
import AccountServices from "../../based/services/AccountServices";
import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "../../hooks/form-hook";
import ToastMessage from "../components/ToastMessage";

const initialFormLogin = {
    email: {
        value: "",
        placeHolder: "Email",
        type: "email",
        isTouch: false,
    },
    password: {
        value: "",
        placeHolder: "Mật khẩu",
        type: "password",
        isTouch: false,
    },
};
const Login = (props) => {
    const history = useHistory();
    const deviceInfo = Common.detectDeviceInfo();
    const authContext = useContext(AuthContext);
    const { form, inputChange, inputClear } = useForm(initialFormLogin, false);
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState({
        isShow: false,
        message: "",
        type: "",
    });
    /** handle login */
    const loginHandler = async (e) => {
        e?.preventDefault();
        if (form.isValid) {
            setIsLoading(true);
            let redirect = GetRedirect(props.location.search);
            let model = {
                username: form.inputs.email.value,
                password: form.inputs.password.value,
                isRemember: false,
                rUUID: +Common.GetRanusUUID() || 0,
                redirect: redirect,
            };
            const [err, data] = await AccountServices.Login(model, redirect);
            if (!err && data) {
                // Save token to cookie
                let today = new Date();
                let expireDay = new Date();
                expireDay.setDate(today.getDate() + 365);
                cookie.save("token", data.token, { path: "/", expires: expireDay });
                // Save user info to local storage
                const auth = { ...data.user };
                authContext.login(auth);
                window.location.href = "/";
            } else {
                setShowToast({
                    isShow: true,
                    message: err && err.errors ? err.errors[0].value : "Có lỗi xảy ra. Vui lòng thử lại sau!",
                    type: "ERROR",
                });
            }
            setIsLoading(false);
        } else {
            setShowToast({
                isShow: true,
                message: "Vui lòng điền đầy đủ thông tin",
                type: "WARNING",
            });
        }
    };

    return (
        <LoginStyle>
            <ToastMessage
                show={showToast.isShow}
                message={showToast.message}
                type={showToast.type}
                onClose={() => {
                    setShowToast({
                        isShow: false,
                        message: "",
                        type: "",
                    });
                }}
            />
            <div className="box-logo">
                <img src={LogoRanus} />
                <span>Partner</span>
            </div>
            <div className="box-login">
                <div className="box-login-form">
                    <h4>Đăng nhập</h4>
                    {Object.keys(form.inputs).map((id, idx) => {
                        return (
                            <div className="w-100" key={idx}>
                                <input
                                    className="input-login"
                                    value={form.inputs[id].value}
                                    type={form.inputs[id].type}
                                    placeholder={form.inputs[id].placeHolder}
                                    onChange={(event) => inputChange(id, event.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key == "Enter") loginHandler(e);
                                    }}
                                />
                                {!form.inputs[id].value && form.inputs[id].isTouch && <span className="d-inline-block text-danger m-2">Vui lòng điền đầy đủ thông tin</span>}
                            </div>
                        );
                    })}
                    <button className={`btn-login ${isLoading ? "disabled" : ""}`} onClick={loginHandler}>
                        Đăng nhập
                    </button>
                </div>
            </div>
        </LoginStyle>
    );
};

export const GetRedirect = (redirect) => {
    let findText = "?redirect=";
    let indexOf = redirect.indexOf(findText);
    if (indexOf >= 0) {
        let splitString = redirect.substring(indexOf + findText.length, redirect.length + 1);
        return splitString;
    } else {
        return redirect;
    }
};
const LoginStyle = styled.div`
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    height: 100svh;
    font-family: "Lexend";
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    .box-logo {
        img {
            width: auto;
            height: auto;
        }

        span {
            color: #b3282d;
            font-family: Lexend;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            display: block;
        }
    }

    .box-login {
        height: 70svh;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .box-login-form {
            padding-top: 30px;

            h4 {
                color: #000;
                font-family: Lexend;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.2px;
                margin: 0 auto;
                text-align: center;
            }

            input {
                width: 100%;
                height: 45px;
                border: 1px solid #d3d3d3;
                border-radius: 10px;
                margin-top: 15px;
                padding-left: 15px;
                outline: none;

                ::placeholder {
                    color: #444;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: -0.14px;
                }
            }

            span {
                color: #b3282d;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.14px;
                margin-top: 15px;
                display: block;
            }
            button {
                border-radius: 10px;
                background: #b3282d;
                width: 100%;
                height: 42px;
                border: none;
                outline: none;
                color: #fff;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                line-height: 42px;
                letter-spacing: -0.16px;
                margin-top: 30px;
            }
        }

        .box-login-social {
            text-align: center;

            span {
                color: #a0a0a0;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: block;
                margin-bottom: 10px;
            }

            .box-login-social-list {
                display: flex;
                align-items: center;
                justify-content: center;

                .box-login-social-item {
                    cursor: pointer;
                    margin: 0 10px;
                }
            }
        }
    }
`;
export default Login;
