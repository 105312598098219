import BaseEcomServices from "./BaseEcomServices";

const SpinnerServices = {
    GetSpinnerLinkByPaging: async (pageModel) => {
        return await BaseEcomServices.Post(`/api/spinner/get-spinner-link-by-paging`, pageModel);
    },
    CreateSpinnerLink: async (model) => {
        return await BaseEcomServices.Post(`/api/spinner/create-spinner-link`, model);
    },
    GetPresentByCampaignPartner: async (campaignId) => {
        return await BaseEcomServices.Get(`/api/spinner/get-present-by-cam-partner-id/${campaignId}`);
    },
    GetPresentByCampaignPartner: async (campaignId) => {
        return await BaseEcomServices.Get(`/api/spinner/get-present-by-cam-partner-id/${campaignId}`);
    },
    CreateQRShare: async (model) => {
        return await BaseEcomServices.Post(`/api/spinner/create-qr-share`, model);
    },
};

export default SpinnerServices;
