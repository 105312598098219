import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BaseBottomBarModal from "../../based/BaseBottomBarModal";
import { NotifyDialog } from "../../based/NotifyDialog";
import SpinnerServices from "../../based/services/SpinnerServices";
import { camSpinnerLinkDTO } from "./MainPage";
function CreateQRModal({ show, onClose, campaignId, onSave }) {
    const bodyRef = useRef(null);
    const [note, setNote] = useState("");
    const [maxHeight, setmaxHeight] = useState(80);
    const [credit, setCredit] = useState(1);

    useEffect(() => {
        if (bodyRef.current) {
            const bodyHeight = bodyRef.current.clientHeight;
            let percentage = ((bodyHeight + 46) * 100) / window.innerHeight;
            setmaxHeight(percentage);
        }
    }, []);

    const onCreate = async (model) => {
        const [err, data] = await SpinnerServices.CreateSpinnerLink(model);
        if (!err && data) {
            onSave();
            NotifyDialog("QR code đã được tạo thành công");
        } else {
            NotifyDialog("Tạo mã QR thất bại");
        }
    };

    return (
        <BaseBottomBarModal isShow={show} onClose={onClose} title={"Tạo mã QR"} maxHeight={maxHeight}>
            <Wrapper ref={bodyRef}>
                <div className="body-section">
                    <div className="note-edit my-1">
                        <label htmlFor="" className="required">
                            Thêm ghi chú
                        </label>
                        <textarea
                            name=""
                            id=""
                            rows={5}
                            value={note}
                            onChange={(e) => {
                                //maximum 500
                                if (e.target.value.length > 500) {
                                    return;
                                }
                                setNote(e.target.value);
                            }}
                        ></textarea>
                    </div>
                    <div className="note-edit my-1">
                        <label htmlFor="" className="required">
                            Số lượng Credit cho QR
                        </label>
                        <input
                            type="number"
                            onChange={(e) => {
                                setCredit(+e.target.value);
                            }}
                            value={credit}
                            className="form-control"
                        />
                    </div>
                </div>
                <div
                    className="bottom-btn"
                    onClick={(e) => {
                        let model = { ...camSpinnerLinkDTO };
                        model.campaignId = campaignId;
                        model.note = note;
                        model.credit = credit;
                        onCreate(model);
                    }}
                >
                    <div className="creat-btn">Tạo mã</div>
                </div>
            </Wrapper>
        </BaseBottomBarModal>
    );
}

export default CreateQRModal;

const Wrapper = styled.div`
    padding: 16px;

    textarea {
        color: black;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 12px;
    }

    .note-edit {
        display: flex;
        flex-wrap: wrap;
    }

    .note-edit label.required {
        color: #ce0000;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
    }

    .note-edit textarea {
        border-radius: 10px;
        background: var(--mu-vin-nn, #f3f3f3);
        width: 100%;
        margin: 10px 0;
    }

    .creat-btn {
        border-radius: 12px;
        background: #b3282d;
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        padding: 15px;
    }
`;
