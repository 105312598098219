var CONSTANTS = {
    MSG_ERROR: "Có lỗi xảy ra. Vui lòng thử lại sau ít phút.",
    MSG_MIN_LENGTH_INVALID: "Tối thiểu phải có ## ký tự.",
    MSG_MAX_LENGTH_INVALID: "Tối đa cho phép ## ký tự.",
    MSG_MAX_INVALID: "Giá trị phải nhỏ hơn hoặc bằng ##.",
    MSG_MIN_INVALID: "Giá trị phải lớn hơn hoặc bằng ##.",
    MSG_REQUIRED: "Dữ liệu không được để trống.",
    MSG_EMAIL_INVALID: "Email không đúng định dạng.",
    MSG_PASSWORD_INVALID: "Mật khẩu chỉ được chứa ký tự chữ cái, chữ số, ký tự đặc biệt.",
    MSG_ONLY_BLANK_INVALID: "Dữ liệu không được chỉ chứa dấu cách",
    MSG_BLANK_POSITION_INVALID: "Dữ liệu không được chứa dấu cách ở đầu cuối và nhiều dấu cách giữa các từ",
    MSG_IMAGE_INVALID: "Ảnh không hợp lệ",

    FUNC_CAIDAT: "SYSTEM_CONFIG",
    COMMON_SETTINGS: 90009,
    PLAYER_URL: process.env.NODE_ENV === "production" ? "http://player.sightcareer.com" : "http://localhost:8000",

    CLAIM_TYPE: "http://techforus.net/permission",
    LOGIN_URL_CALLBACK: "loginUrlCallback",

    REGEX_PASSWORD: /^[A-Za-z0-9!@#?$%^&*()_[\]|:"{},.<>+=-]*$/,
    INVALID_PASSWORD: 'Chỉ được nhập chữ in hoa, chữ thường, số và các ký tự !@#?$%^&*()_[]|:"{},.<>+=-',
    NAME_REGEX: /^[A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐa-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\s']+$/,
    INVALID_NAME: "Chỉ được nhập chữ in hoa, chữ thường, dấu cách và ký tự '",
    PHONE_REGEX: /^\d+$/,
    BIRTHDAY_REGEX: /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/,
    EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    INVALID_EMAIL: "Email không hợp lệ",
    // PHONE_REGEX:'(84[3|5|7|8|9])+([0-9]{8})\b',
    INVALID_PHONE: "Số điện thoại không hợp lệ!",
    COOKIE_USER_REFERENCE: "ref",
    CELL_PHONE_REGEX: /^(\+84|0[3|5|7|8|9])+([0-9]{8})$/,
    HOME_PHONE_REGEX: /^(\+84|0[2])+([0-9]{9})$/,
    CURRENT_WAREHOUSE: "CURRENT_WAREHOUSE",

    USER_READ: "USER.READ",
    USER_CREATE: "USER.CREATE",
    USER_UPDATE: "USER.UPDATE",
    USER_DELETE: "USER.DELETE",
    USER_RESET_PASSWORD: "USER.RESET_PASSWORD",
    USER_ASSIGN_ROLE: "USER.ASSIGN_ROLE",

    ROLE_READ: "ROLE.READ",
    ROLE_CREATE: "ROLE.CREATE",
    ROLE_UPDATE: "ROLE.UPDATE",
    ROLE_DELETE: "ROLE.DELETE",
    ROLE_PERMISISON: "ROLE.PERMISISON",

    PRODUCT_READ: "PRODUCT.READ",
    PRODUCT_CREATE: "PRODUCT.CREATE",
    PRODUCT_UPDATE: "PRODUCT.UPDATE",
    PRODUCT_DELETE: "PRODUCT.DELETE",

    INSURRANCE_READ: "INSURRANCE.READ",
    INSURRANCE_CREATE: "INSURRANCE.CREATE",
    INSURRANCE_UPDATE: "INSURRANCE.UPDATE",
    INSURRANCE_DELETE: "INSURRANCE.DELETE",
    INSURRANCE_EXPORT: "INSURRANCE.EXPORT",

    CUSTOMER_READ: "CUSTOMER.READ",
    CUSTOMER_CREATE: "CUSTOMER.CREATE",
    CUSTOMER_UPDATE: "CUSTOMER.UPDATE",
    CUSTOMER_DELETE: "CUSTOMER.DELETE",

    COMMENT_READ: "COMMENT.READ",
    COMMENT_CREATE: "COMMENT.CREATE",
    COMMENT_UPDATE: "COMMENT.UPDATE",
    COMMENT_DELETE: "COMMENT.DELETE",

    WEBSETTING_READ: "WEBSETTING.READ",
    WEBSETTING_UPDATE: "WEBSETTING.UPDATE",

    LANDING_READ: "LANDING.READ",
    LANDING_UPDATE: "LANDING.UPDATE",

    CONTACT_READ: "CONTACT.READ",
    CONTACT_DELETE: "CONTACT.DELETE",

    NEWS_READ: "NEWS.READ",
    NEWS_CREATE: "NEWS.CREATE",
    NEWS_UPDATE: "NEWS.UPDATE",
    NEWS_DELETE: "NEWS.DELETE",

    SIDEBAR_MENU_TOPIC: "SIDEBAR",
};

var ENTITY_STATE = {
    INSERT: 1,
    UPDATE: 2,
    DELETE: 3,
};

var NOTIFY = {
    SUCCESS: "Success",
    ERROR: "Error",
    WARNING: "Notice",
    INFO: "Info",
};

var TICKET_TYPE = {
    GeneralSupport: 0,
    ReturnOrder: 1,
};

var MAINMENU = {
    ManageProduct: "ManageProduct",
    Category: "Category",
    BrandManage: "BrandManage",
    AttributeManagement: "AttributeManagement",
    Warehouse: "Warehouse",
    StockInHistory: "StockInHistory",
    StockOutHistory: "StockOutHistory",
    Inventory: "Inventory",
    TransactionManagement: "TransactionManagement",
    ConnectPlatform: "ConnectPlatform",
    LazadaProducts: "LazadaProducts",
    Settings: "Settings",
    LazadaBrands: "LazadaBrands",
    LazadaCategories: "LazadaCategories",
    Customer: "Customer",
    Supplier: "Supplier",
    WebSetting: "WebSetting",
    UserManagement: "UserManagement",
    RoleManagement: "RoleManagement",
    ShippingService: "ShippingService",
    ManageMenus: "ManageMenus",
    PointLevelConfig: "PointLevelConfig",
    RewardPointConfig: "RewardPointConfig",
    VirtualWarehouseConfig: "VirtualWarehouseConfig",
    ShippingFeeConfig: "ShippingFeeConfig",
    ManageWarranty: "ManageWarranty",
    SocialMarketPlace: "SocialMarketPlace",
    AllChannel: "AllChannel",
    Comments: "Comments",
    Messengers: "Messengers",
    SystemBankAccounts: "SystemBankAccounts",

    Dashboard: "Dashboard",
    FlashSale: "FlashSale",
    Campaign: "Campaign",
    BannerSetting: "BannerSetting",
    ManageReviewProduct: "ManageReviewProduct",
    UrlFriendlyManage: "UrlFriendlyManage",
    Facebook: "Facebook",
    WarehouseManageAdmin: "WarehouseManageAdmin",
    ThirdPartyManage: "ThirdPartyManagement",
    CreditManageAdmin: "CreditManageAdmin",
    CreditManage: "CreditManage",
    WarrantyReceive: "WarrantyReceive",
};

var SUBMENU = {
    ListCourse: "ListCourse",
    CourseEditor: "CourseEditor",
    ListQuestion: "ListQuestion",
    QuestionEditor: "QuestionEditor",
    Lession: "Lession",
    Users: "Users",
    Roles: "Roles",
    Attributes: "Attributes",
    AttributeValues: "AttributeValues",
    AttributeGroups: "AttributeGroups",
    LazadaBrands: "LazadaBrands",
    LazadaCategories: "LazadaCategories",
    LazadaProducts: "LazadaProducts",
    LazadaOrders: "LazadaOrders",
    FacebookConfig: "FacebookConfig",
    FacebookSettings: "FacebookSettings",
    FacebookAllChannel: "FacebookAllChannel",
    FacebookComments: "FacebookComments",
    FacebookMessengers: "FacebookMessengers",
    SocialMarketPlace: "SocialMarketPlace",
};

var PLATFORM = {
    Undefined: 0,
    Internal: 1,
    LOTUS: 1,
    Lazada: 2,
    Shopee: 4,
    Tiki: 8,
    Facebook: 16,
    Sendo: 32,
    Tiktok: 64,
};

var PRODUCT_TYPE = {
    PRODUCT: 1,
    PRODUCT_VARIANT: 2,
};

var VOUCHER_DISCOUNT_TYPE = {
    PERCENTAGE: 0,
    FIXEDPRICE: 1,
};

var VOUCHER_TYPE = {
    NORMAL: 0,
    GIFT: 1,
    PROMOTEDBRAND: 2,
    FREESHIP: 3,
    FLEXI: 4,
    BRAND: 5,
    DIRECT: 6,
    FLEXICOMBO: 7,
    FREEPRODUCT: 8,
};

var VOUCHER_CONDITION_TAB_TYPE = {
    SPECIAL: 0,
    ONLYYOU: 1,
    CREATOR: 2,
    COLLECTED: 3,
};

var VOUCHER_CONDITION_COLLECT_TYPE = {
    ENOUGH: 1,
    NEARLY: 2,
    COLLECTED: 3,
};

var STATUS_PRODUCT = {
    NEW: 1,
    SELLING: 2,
    STOP_SELLING: 3,
    OUT_OF_STOCK: 4,
};

var ATTRIBUTE_INPUT_TYPE = {
    CHECKBOX: 1,
    SINGLE_SELECT: 2,
    NUMBER: 3,
    TEXT: 4,
    RADIO: 5,
    MULTI_SELECT: 6,
};

var ATTRIBUTE_TYPE = {
    NORMAL: 0,
    SKU: 1,
};

var WARRANTY_TYPE = {
    WARRANTY_TYPE: 1,
    WARRANTY_TIME: 2,
};

var JOIN_CHAR = {
    MULTI_SELECT: "///",
};

var FACEBOOK_CHANNEL_FEED = {
    ALL: "ALL",
    COMMENT: "COMMENT",
    MESSENGER: "MESSENGER",
};

var REFUND_REASONS = [
    { value: "1", label: "Sản phẩm không giống hình ảnh/mô tả" },
    { value: "2", label: "Giao sai sản phẩm" },
    { value: "3", label: "Thiếu phụ kiện/quà tặng kèm" },
    { value: "4", label: "Sản phẩm bị hư hỏng/bể vỡ" },
    { value: "5", label: "Sản phẩm có dấu hiệu hàng giả" },
    { value: "6", label: "Thiếu/không nhận được sản phẩm" },
];

var REVIEWGOOD = [
    { id: 1, name: "Tuyệt vời" },
    { id: 2, name: "Hình in chất lượng" },
    { id: 3, name: "Giao hàng nhanh" },
    { id: 4, name: "Đóng gói hàng đẹp" },
    { id: 5, name: "Chất lượng sản phẩm tốt" },
];
var REVIEWBAD = [
    { id: 1, name: "Không hài lòng" },
    { id: 2, name: "Hình in kém chất lượng" },
    { id: 3, name: "Sản phẩm không tốt" },
    { id: 4, name: "Giao hàng lâu" },
    { id: 5, name: "Đóng gói sơ sài" },
];

var FACEBOOK_CHANNEL_FEED_ENUM = {
    COMMENT: 1,
    MESSENGER: 2,
};

var FACEBOOK_CHANNEL_DETAIL_TAB = {
    PRODUCT: "PRODUCT",
    CLIENT: "CLIENT",
    ORDER: "ORDER",
    FBSHOP: "FBSHOP",
    INSTAGRAMSHOP: "INSTASHOP",
    ZALOSHOP: "ZALOSHOP",
    LAZSHOP: "LAZSHOP",
    SHOPEESHOP: "SHOPEESHOP",
    TIKISHOP: "TIKISHOP",
    FIFOSHOP: "FIFOSHOP",
    SHOPPINGCART: "SHOPPINGCART",
};

var STOCK_IN_STATUS = {
    PendingApproval: 0,
    Approved: 1,
    InboundingProcess: 2,
    CompletelyReceived: 3,
    PartiallyReceived: 4,
    Completed: 5,
    Cancelled: 6,
    RejectApproval: 7,
};

var STOCK_IN_STATUS_NAME = {
    PendingApproval: "Đang chờ xác nhận",
    Approved: "Xác nhận",
    InboundingProcess: "Đang nhập hàng",
    CompletelyReceived: "Đã nhận toàn bộ hàng",
    PartiallyReceived: "Đã nhận một phần",
    Completed: "Hoàn thành",
    Cancelled: "Hủy yêu cầu",
    RejectApproval: "Từ chối",
};

var USER_PROFILE_TAB = [
    {
        icon: "fa fa-user",
        name: "CHANGE_PROFILE",
        text: "Thay đổi thông tin cá nhân",
        url: "/user/profile",
    },
    {
        icon: "fa fa-lock",
        name: "CHANGE_PASSWORD",
        text: "Thay đổi mật khẩu",
        url: "/user/change-password",
    },
    {
        icon: "fa fa-history",
        name: "ORDER_HISTORY",
        text: "Lịch sử mua hàng",
        url: "/user/orders",
    },
    {
        icon: "fa fa-history",
        name: "ORDER_RETURN",
        text: "Lịch sử trả hàng/hoàn tiền",
        url: "/return-order-list",
    },
    {
        icon: "fa fa-heart",
        name: "FAVORITE_PRODUCTS",
        text: "Sản phẩm ưu thích",
        url: "/user/favorite",
    },
    {
        icon: "fa fa-comment",
        name: "REVIEWED_PRODUCTS",
        text: "Sản phẩm đã đánh giá",
        url: "/user/reviewed-products",
    },
    {
        icon: "fa fa-picture-o",
        name: "ARTWORKS",
        text: "Tác phẩm",
        url: "/user/artworks",
    },
    {
        icon: "fa fa-folder-o",
        name: "FOLDERS",
        text: "Thư mục",
        url: "/user/folders",
    },
    {
        icon: "fa fa-exclamation-circle",
        name: "SUPPORT",
        text: "Gửi yêu cầu hỗ trợ",
        url: "/support",
    },
    {
        icon: "fa fa-share-alt",
        name: "CONTACT",
        text: "Liên hệ với chúng tôi",
        url: "/contact",
    },
    {
        icon: "fa fa-info-circle",
        name: "LOTUS_INFO",
        text: "Thông tin về Ranus",
        url: "/about",
    },
];

var FAVORITE_TABS = {
    SP: 1,
    GH: 2,
    BST: 3,
};

var PRODUCT_STATUS = {
    Good: "Good",
    Defective: "Defective",
    Broken: "Broken",
};

var PRODUCT_STATUS_TYPE = {
    Good: 1,
    Defective: 2,
    Broken: 3,
};

var SOCIAL_MARKET_PLACE = {
    Facebook: 1,
    Instagram: 2,
    Zalo: 3,
    Twitter: 4,
};

var PAYMENT_METHOD = {
    UNDEFINED: 0,
    COD: 1,
    MOMO_WALLET: 2,
    MIXEDCARD: 3,
    PAYMENT_ACCOUNT: 4,
    NAPAS_ONLINE: 5,
    ZALOPAY_WALLET: 6,
    INTERNET_BANKING: 7,
    SHOPEE_WALLET: 8,
    VIRTUAL_PAYMENT: 9,
    DIGITAL_WALLET: 10,
    SELF_ARRANGE: 11,
};

var PAYMENT_METHOD_NAME = {
    COD: "COD",
    MOMO_WALLET: "Ví MoMo",
};

var SELLER_PAYMENT_METHOD = {
    MOMO: "MOMO",
    BANK_ACCOUNT: "BANK_ACCOUNT",
};

var ORDER_SOURCE = {
    FIFO: "FIFO",
    OTHERS: "Others",
};

var DEFAULT_VALUE = {
    NO_BRAND: "NO BRAND",
};

var ORDER_TYPE = {
    StockIn: 1,
    StockOut: 2,
};

var ORDER_STATUS = {
    PendingApproval: 0,
    Approved: 1,
    InboundingProcess: 2,
    CompletelyReceived: 3,
    PartiallyReceived: 4,
    Completed: 5,
    Cancelled: 6,
};

var PRODUCT_EDITOR_TAB = {
    PRODUCT_INFO: "PRODUCT_INFO",
    PRODUCT_WARRANTY: "WARRANTY",
    BUFFERSTOCK: "BUFFERSTOCK",
    REWARDPOINTS: "REWARDPOINTS",
    MAPPING_PRODUCT_FIFO: "MAPPING_PRODUCT_FIFO",
};

var AGENCY_PRODUCT_EDITOR_TAB = {
    PRODUCT_INFO: "PRODUCT_INFO",
    FIFO_MAPPING: "FIFO_MAPPING",
};

var STATUS_TRANSACTION = {
    UNDEFINED: 0,
    PROCESSING: 1,
    SUCCEED: 2,
    FAIL_DELIVERY_TO_CUSTOMER: 3,
    CANCELED: 4,
    UNPAID_FROM_CUSTOMER: 5,
    READYTOSHIP: 6,
    SHIPPED_TO_SHIPPER: 7,
    RETURNED_TO_SENDER: 8,
    PENDINGPAYMENT: 9,
    PAID_FROM_CUSTOMER: 10,
    LOST: 11,
    PACKING: 12,
    SHIPPING: 13,
    WAITING_CONFIRM: 14,
    CLOSED: 15,
    DELAYING: 16,
    DELAY: 17,
    SPLITTING: 18,
    SPLITTED: 19,
    MERGING: 20,
    RETURNING: 21,
    WAITING_OPF: 22,
    REPLACE_PRODUCTS: 23,
    PRODUCTION: 24,
};

var STATUS_TRANSACTION_NAME = {
    UNDEFINED: "Không xác định",
    PROCESSING: "Đang xử lý",
    SUCCEED: "Thành công",
    FAIL_DELIVERY_TO_CUSTOMER: "Giao thất bại",
    CANCELED: "Đã hủy",
    UNPAID_FROM_CUSTOMER: "Chưa thanh toán",
    READYTOSHIP: "Sẵn sàng giao",
    SHIPPED_TO_SHIPPER: "Đang giao",
    RETURNED_TO_SENDER: "Hoàn trả hàng",
    PENDINGPAYMENT: "Chờ thanh toán",
    PAID_FROM_CUSTOMER: "Đã thanh toán",
    LOST: "Mất hàng",
    PACKING: "Đang đóng gói",
    SHIPPING: "Đang vận chuyển",
    WAITING_CONFIRM: "Chờ xác nhận đơn hàng",
    CLOSED: "Đóng đơn hàng",
    DELAYING: "Yêu cầu hoãn",
    DELAY: "Đang hoãn",
    SPLITTING: "Yêu cầu tách",
    SPLITTED: "Chờ tách",
    MERGING: "Chờ gộp",
    RETURNING: "Đang đổi trả",
    WAITING_OPF: "Chờ Platform xử lý",
    REPLACE_PRODUCTS: "Đổi sản phẩm khác",
    PRODUCTION: "Đang sản xuất",
};

var BANK_TRANSFER_STATUS = {
    PendingTransaction: 1,
    SuccessfulTransaction: 2,
    RejectedTransaction: 3,
};

var BANK_TRANSFER_STATUS_NAME = {
    PendingTransaction: "Giao dịch đang xử lý",
    SuccessfulTransaction: "Giao dịch thành công",
    RejectedTransaction: "Giao dịch bị từ chối",
};

var ATTRIBUTE_TYPES = [
    {
        value: ATTRIBUTE_INPUT_TYPE.CHECKBOX,
        label: "Checkbox",
    },
    {
        value: ATTRIBUTE_INPUT_TYPE.SINGLE_SELECT,
        label: "Single Select",
    },
    {
        value: ATTRIBUTE_INPUT_TYPE.MULTI_SELECT,
        label: "Multi Select",
    },
    {
        value: ATTRIBUTE_INPUT_TYPE.NUMBER,
        label: "Number",
    },
    {
        value: ATTRIBUTE_INPUT_TYPE.TEXT,
        label: "Text",
    },
    {
        value: ATTRIBUTE_INPUT_TYPE.RADIO,
        label: "Radio",
    },
];

var INPUT_TYPE = {
    SingleSelect: 2,
    MultiEnumInput: 6,
    Text: 4,
    RichText: 7,
    Numeric: 3,
    Date: 8,
    Image: 9,
    MultiSelect: 6,
};

var INPUT_TYPE = {
    SingleSelect: 2,
    MultiEnumInput: 6,
    Text: 4,
    RichText: 7,
    Numeric: 3,
    Date: 8,
    Image: 9,
    MultiSelect: 6,
};

var ATTRIBUTE_VALUE_DISPLAY_NAME = {
    Sex: "Giới tính",
    Color: "Màu sắc",
    Size: "Kích cỡ",
};

var PATTERNS = {
    EMAIL: '(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))',
    PHONE: "(086|096|097|098|032|033|034|035|036|037|038|039|088|091|094|083|084|085|081|082|089|090|093|070|079|077|076|078|092|056|058|099|059)(\\d{7})",
    CODE: "([a-zA-Z0-9_]*)",
    NUMBER: "[+-]?\\d+(\\.\\d+)?",
    USERNAME: "[\\w\\d]+",
    URL:
        "(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?",
    HEX_COLOR: "#[0-9a-z]{6}",
};

var REGEX = {
    // match single
    EMAIL: new RegExp(`^${PATTERNS.EMAIL}$`),
    PHONE: new RegExp(`^${PATTERNS.PHONE}$`),
    CODE: new RegExp(`^${PATTERNS.CODE}$`),
    NUMBER: new RegExp(`^${PATTERNS.NUMBER}$`),
    USERNAME: new RegExp(`^${PATTERNS.USERNAME}$`),
    URL: new RegExp(`^${PATTERNS.URL}$`),
    // match multiple
    EMAILS: new RegExp(`^${PATTERNS.EMAIL}\\s|\\s${PATTERNS.EMAIL}$|\\s${PATTERNS.EMAIL}\\s|^${PATTERNS.EMAIL}$`, "gi"),
    PHONES: new RegExp(`^${PATTERNS.PHONE}\\s|\\s${PATTERNS.PHONE}$|\\s${PATTERNS.PHONE}\\s|^${PATTERNS.PHONE}$`, "g"),
    CODES: new RegExp(`^${PATTERNS.CODE}\\s|\\s${PATTERNS.CODE}$|\\s${PATTERNS.CODE}\\s|^${PATTERNS.CODE}$`, "gi"),
    NUMBERS: new RegExp(`^${PATTERNS.NUMBER}\\s|\\s${PATTERNS.NUMBER}$|\\s${PATTERNS.NUMBER}\\s|^${PATTERNS.NUMBER}$`, "g"),
    USERNAMES: new RegExp(`^${PATTERNS.USERNAME}\\s|\\s${PATTERNS.USERNAME}$|\\s${PATTERNS.USERNAME}\\s|^${PATTERNS.USERNAME}$`, "gi"),
    URLS: new RegExp(`^${PATTERNS.URL}\\s|\\s${PATTERNS.URL}$|\\s${PATTERNS.URL}\\s|^${PATTERNS.URL}$`, "gi"),
    HEX_COLOR: new RegExp(`^${PATTERNS.HEX_COLOR}$`),
};

var PRODUCT_OVERVIEW = [
    {
        value: "all",
        label: "Tất cả",
    },
    {
        value: "active",
        label: "Đang hoạt động",
    },
    {
        value: "inactive",
        label: "Không hoạt động",
    },
];

var WARRANTY_STATUS = {
    AVAILABLE: 1,
    PROCESSING: 2,
    EXPIRED: 3,
};

var WARRANTY_RECEIVE_STATUS = {
    PROCESSING: 0,
    NEW_PRODUCT: 1,
    REPAIR: 2,
    NO_PROBLEM: 3,
};

var STATUS_ACTIVE = {
    InActive: 0,
    Active: 1,
    All: 2,
};

var PAYMENT_SCHEME = {
    SELLER: 1,
    BUYER: 2,
};

var SHIPPING_SERVICE_TYPE = {
    FAST: 1,
    STANDARD: 2,
    SAVING: 3,
};

var TEMPLATE_IMPORT = {
    IMPORT_CATEGORIES: "Danh_muc_san_pham.xlsx",
    IMPORT_BRANDS: "Danh_muc_thuong_hieu.xlsx",
    IMPORT_MAPPING_BRANDS: "Mapping_Brands.xlsx",
    IMPORT_MAPPING_CATEGORIES: "Mapping_Categories.xlsx",
};

var PRODUCT_PUSHING_STATUS = {
    Processing: 0,
    Pending: 1,
    Failed: 2,
    Successed: 3,
};

var RETURN_ORDER_STATUS_NAME_ARR = {
    0: "Đang được xử lý",
    1: "Cần cập nhật thêm thông tin",
    2: "Đồng ý đổi trả",
    4: "Từ chối đổi trả",
    8: "Đổi sang sản phẩm khác",
    16: "Gửi thêm sản phẩm mới",
    32: "Hoàn lại tiền",
    1024: "Đã đóng",
};

var ATTRIBUTE_DATA_TYPE = {
    Checkbox: 1,
    SingleSelect: 2,
    Number: 3,
    Text: 4,
    Radio: 5,
    MultiSelect: 6,
    RichText: 7,
    Date: 8,
    Image: 9,
    ColorPicker: 10,
    TextArea: 11,
};

var INITIAL_PAGING = {
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
    orderBy: "",
    orderDirection: "",
    totalRecord: 0,
    totalPages: 0,
    createdBy: "",
};

const PRINT_TYPE = {
    0: "Trơn",
    1: "DTG",
    2: "PET",
    4: "Lụa",
    8: "Decal",
    16: "Chuyển nhiệt",
    32: "Thêu",
};

const PRINT_TYPE_NAME = {
    DTG: 1,
    PET: 2,
    Embroider: 32,
};

const SIZE_NAME_CLASSIC = {
    S: 0,
    M: 1,
    L: 2,
    XL: 3,
    _2XL: 4,
};
const SIZE_NAME_UNISEX = {
    S: 0,
    M: 1,
    L: 2,
    XL: 3,
};
const SIZE_NAME_KID = {
    K5: 0,
    K6: 1,
    K7: 2,
    K8: 3,
    K9: 4,
};
const SIZES_CLASSIC = [
    { height: 146, minWeight: 43, maxWeight: 56, size: SIZE_NAME_CLASSIC.S, sizeName: "S", sizeValue: 1 },
    { height: 147, minWeight: 43, maxWeight: 57, size: SIZE_NAME_CLASSIC.S, sizeName: "S", sizeValue: 1 },
    { height: 148, minWeight: 43, maxWeight: 58, size: SIZE_NAME_CLASSIC.S, sizeName: "S", sizeValue: 1 },
    { height: 149, minWeight: 43, maxWeight: 58, size: SIZE_NAME_CLASSIC.S, sizeName: "S", sizeValue: 1 },
    { height: 150, minWeight: 43, maxWeight: 59, size: SIZE_NAME_CLASSIC.S, sizeName: "S", sizeValue: 1 },
    { height: 151, minWeight: 43, maxWeight: 60, size: SIZE_NAME_CLASSIC.S, sizeName: "S", sizeValue: 1 },
    { height: 152, minWeight: 43, maxWeight: 61, size: SIZE_NAME_CLASSIC.S, sizeName: "S", sizeValue: 1 },

    { height: 153, minWeight: 43, maxWeight: 61, size: SIZE_NAME_CLASSIC.M, sizeName: "M", sizeValue: 2 },
    { height: 154, minWeight: 43, maxWeight: 62, size: SIZE_NAME_CLASSIC.M, sizeName: "M", sizeValue: 2 },
    { height: 155, minWeight: 44, maxWeight: 63, size: SIZE_NAME_CLASSIC.M, sizeName: "M", sizeValue: 2 },
    { height: 156, minWeight: 44, maxWeight: 64, size: SIZE_NAME_CLASSIC.M, sizeName: "M", sizeValue: 2 },
    { height: 157, minWeight: 45, maxWeight: 65, size: SIZE_NAME_CLASSIC.M, sizeName: "M", sizeValue: 2 },
    { height: 158, minWeight: 46, maxWeight: 66, size: SIZE_NAME_CLASSIC.M, sizeName: "M", sizeValue: 2 },
    { height: 159, minWeight: 46, maxWeight: 66, size: SIZE_NAME_CLASSIC.M, sizeName: "M", sizeValue: 2 },

    { height: 160, minWeight: 47, maxWeight: 67, size: SIZE_NAME_CLASSIC.L, sizeName: "L", sizeValue: 3 },
    { height: 161, minWeight: 47, maxWeight: 68, size: SIZE_NAME_CLASSIC.L, sizeName: "L", sizeValue: 3 },
    { height: 162, minWeight: 48, maxWeight: 69, size: SIZE_NAME_CLASSIC.L, sizeName: "L", sizeValue: 3 },
    { height: 163, minWeight: 49, maxWeight: 70, size: SIZE_NAME_CLASSIC.L, sizeName: "L", sizeValue: 3 },
    { height: 164, minWeight: 49, maxWeight: 71, size: SIZE_NAME_CLASSIC.L, sizeName: "L", sizeValue: 3 },
    { height: 165, minWeight: 50, maxWeight: 72, size: SIZE_NAME_CLASSIC.L, sizeName: "L", sizeValue: 3 },
    { height: 166, minWeight: 50, maxWeight: 72, size: SIZE_NAME_CLASSIC.L, sizeName: "L", sizeValue: 3 },

    { height: 167, minWeight: 51, maxWeight: 73, size: SIZE_NAME_CLASSIC.XL, sizeName: "XL", sizeValue: 4 },
    { height: 168, minWeight: 51, maxWeight: 74, size: SIZE_NAME_CLASSIC.XL, sizeName: "XL", sizeValue: 4 },
    { height: 169, minWeight: 52, maxWeight: 75, size: SIZE_NAME_CLASSIC.XL, sizeName: "XL", sizeValue: 4 },
    { height: 170, minWeight: 53, maxWeight: 76, size: SIZE_NAME_CLASSIC.XL, sizeName: "XL", sizeValue: 4 },
    { height: 171, minWeight: 53, maxWeight: 77, size: SIZE_NAME_CLASSIC.XL, sizeName: "XL", sizeValue: 4 },
    { height: 172, minWeight: 54, maxWeight: 78, size: SIZE_NAME_CLASSIC.XL, sizeName: "XL", sizeValue: 4 },
    { height: 173, minWeight: 55, maxWeight: 78, size: SIZE_NAME_CLASSIC.XL, sizeName: "XL", sizeValue: 4 },

    { height: 174, minWeight: 55, maxWeight: 79, size: SIZE_NAME_CLASSIC._2XL, sizeName: "2XL", sizeValue: 5 },
    { height: 175, minWeight: 56, maxWeight: 80, size: SIZE_NAME_CLASSIC._2XL, sizeName: "2XL", sizeValue: 5 },
    { height: 176, minWeight: 57, maxWeight: 81, size: SIZE_NAME_CLASSIC._2XL, sizeName: "2XL", sizeValue: 5 },
    { height: 177, minWeight: 57, maxWeight: 82, size: SIZE_NAME_CLASSIC._2XL, sizeName: "2XL", sizeValue: 5 },
    { height: 178, minWeight: 58, maxWeight: 83, size: SIZE_NAME_CLASSIC._2XL, sizeName: "2XL", sizeValue: 5 },
    { height: 179, minWeight: 58, maxWeight: 84, size: SIZE_NAME_CLASSIC._2XL, sizeName: "2XL", sizeValue: 5 },
    { height: 180, minWeight: 59, maxWeight: 85, size: SIZE_NAME_CLASSIC._2XL, sizeName: "2XL", sizeValue: 5 },
];
const SIZES_UNISEX = [
    { height: 153, minWeight: 43, maxWeight: 61, size: SIZE_NAME_UNISEX.S, sizeName: "S", sizeValue: 34 },
    { height: 154, minWeight: 43, maxWeight: 62, size: SIZE_NAME_UNISEX.S, sizeName: "S", sizeValue: 34 },
    { height: 155, minWeight: 44, maxWeight: 63, size: SIZE_NAME_UNISEX.S, sizeName: "S", sizeValue: 34 },
    { height: 156, minWeight: 44, maxWeight: 64, size: SIZE_NAME_UNISEX.S, sizeName: "S", sizeValue: 34 },
    { height: 157, minWeight: 45, maxWeight: 65, size: SIZE_NAME_UNISEX.S, sizeName: "S", sizeValue: 34 },
    { height: 158, minWeight: 46, maxWeight: 66, size: SIZE_NAME_UNISEX.S, sizeName: "S", sizeValue: 34 },
    { height: 159, minWeight: 46, maxWeight: 66, size: SIZE_NAME_UNISEX.S, sizeName: "S", sizeValue: 34 },

    { height: 160, minWeight: 47, maxWeight: 67, size: SIZE_NAME_UNISEX.M, sizeName: "M", sizeValue: 35 },
    { height: 161, minWeight: 47, maxWeight: 68, size: SIZE_NAME_UNISEX.M, sizeName: "M", sizeValue: 35 },
    { height: 162, minWeight: 48, maxWeight: 69, size: SIZE_NAME_UNISEX.M, sizeName: "M", sizeValue: 35 },
    { height: 163, minWeight: 49, maxWeight: 70, size: SIZE_NAME_UNISEX.M, sizeName: "M", sizeValue: 35 },
    { height: 164, minWeight: 49, maxWeight: 71, size: SIZE_NAME_UNISEX.M, sizeName: "M", sizeValue: 35 },
    { height: 165, minWeight: 50, maxWeight: 72, size: SIZE_NAME_UNISEX.M, sizeName: "M", sizeValue: 35 },
    { height: 166, minWeight: 50, maxWeight: 72, size: SIZE_NAME_UNISEX.M, sizeName: "M", sizeValue: 35 },

    { height: 167, minWeight: 51, maxWeight: 73, size: SIZE_NAME_UNISEX.L, sizeName: "L", sizeValue: 36 },
    { height: 168, minWeight: 51, maxWeight: 74, size: SIZE_NAME_UNISEX.L, sizeName: "L", sizeValue: 36 },
    { height: 169, minWeight: 52, maxWeight: 75, size: SIZE_NAME_UNISEX.L, sizeName: "L", sizeValue: 36 },
    { height: 170, minWeight: 53, maxWeight: 76, size: SIZE_NAME_UNISEX.L, sizeName: "L", sizeValue: 36 },
    { height: 171, minWeight: 53, maxWeight: 77, size: SIZE_NAME_UNISEX.L, sizeName: "L", sizeValue: 36 },
    { height: 172, minWeight: 54, maxWeight: 78, size: SIZE_NAME_UNISEX.L, sizeName: "L", sizeValue: 36 },
    { height: 173, minWeight: 55, maxWeight: 78, size: SIZE_NAME_UNISEX.L, sizeName: "L", sizeValue: 36 },

    { height: 174, minWeight: 55, maxWeight: 79, size: SIZE_NAME_UNISEX.XL, sizeName: "XL", sizeValue: 37 },
    { height: 175, minWeight: 56, maxWeight: 80, size: SIZE_NAME_UNISEX.XL, sizeName: "XL", sizeValue: 37 },
    { height: 176, minWeight: 57, maxWeight: 81, size: SIZE_NAME_UNISEX.XL, sizeName: "XL", sizeValue: 37 },
    { height: 177, minWeight: 57, maxWeight: 82, size: SIZE_NAME_UNISEX.XL, sizeName: "XL", sizeValue: 37 },
    { height: 178, minWeight: 58, maxWeight: 83, size: SIZE_NAME_UNISEX.XL, sizeName: "XL", sizeValue: 37 },
    { height: 179, minWeight: 58, maxWeight: 84, size: SIZE_NAME_UNISEX.XL, sizeName: "XL", sizeValue: 37 },
    { height: 180, minWeight: 59, maxWeight: 85, size: SIZE_NAME_UNISEX.XL, sizeName: "XL", sizeValue: 37 },
];
const SIZES_KID = [
    { height: 110, minWeight: 19, maxWeight: 28, size: SIZE_NAME_KID.K5, sizeName: "5", sizeValue: 10 },
    { height: 111, minWeight: 20, maxWeight: 29, size: SIZE_NAME_KID.K5, sizeName: "5", sizeValue: 10 },
    { height: 112, minWeight: 20, maxWeight: 29, size: SIZE_NAME_KID.K5, sizeName: "5", sizeValue: 10 },
    { height: 113, minWeight: 21, maxWeight: 30, size: SIZE_NAME_KID.K5, sizeName: "5", sizeValue: 10 },
    { height: 114, minWeight: 21, maxWeight: 30, size: SIZE_NAME_KID.K5, sizeName: "5", sizeValue: 10 },
    { height: 115, minWeight: 22, maxWeight: 31, size: SIZE_NAME_KID.K5, sizeName: "5", sizeValue: 10 },
    { height: 116, minWeight: 22, maxWeight: 31, size: SIZE_NAME_KID.K5, sizeName: "5", sizeValue: 10 },

    { height: 117, minWeight: 22, maxWeight: 32, size: SIZE_NAME_KID.K6, sizeName: "6", sizeValue: 11 },
    { height: 118, minWeight: 23, maxWeight: 32, size: SIZE_NAME_KID.K6, sizeName: "6", sizeValue: 11 },
    { height: 119, minWeight: 23, maxWeight: 33, size: SIZE_NAME_KID.K6, sizeName: "6", sizeValue: 11 },
    { height: 120, minWeight: 23, maxWeight: 34, size: SIZE_NAME_KID.K6, sizeName: "6", sizeValue: 11 },
    { height: 121, minWeight: 23, maxWeight: 34, size: SIZE_NAME_KID.K6, sizeName: "6", sizeValue: 11 },
    { height: 122, minWeight: 24, maxWeight: 35, size: SIZE_NAME_KID.K6, sizeName: "6", sizeValue: 11 },
    { height: 123, minWeight: 25, maxWeight: 35, size: SIZE_NAME_KID.K6, sizeName: "6", sizeValue: 11 },

    { height: 124, minWeight: 25, maxWeight: 36, size: SIZE_NAME_KID.K7, sizeName: "7", sizeValue: 12 },
    { height: 125, minWeight: 25, maxWeight: 36, size: SIZE_NAME_KID.K7, sizeName: "7", sizeValue: 12 },
    { height: 126, minWeight: 26, maxWeight: 37, size: SIZE_NAME_KID.K7, sizeName: "7", sizeValue: 12 },
    { height: 127, minWeight: 26, maxWeight: 38, size: SIZE_NAME_KID.K7, sizeName: "7", sizeValue: 12 },
    { height: 128, minWeight: 27, maxWeight: 38, size: SIZE_NAME_KID.K7, sizeName: "7", sizeValue: 12 },
    { height: 129, minWeight: 27, maxWeight: 39, size: SIZE_NAME_KID.K7, sizeName: "7", sizeValue: 12 },
    { height: 130, minWeight: 27, maxWeight: 39, size: SIZE_NAME_KID.K7, sizeName: "7", sizeValue: 12 },

    { height: 131, minWeight: 28, maxWeight: 40, size: SIZE_NAME_KID.K8, sizeName: "8", sizeValue: 13 },
    { height: 132, minWeight: 28, maxWeight: 41, size: SIZE_NAME_KID.K8, sizeName: "8", sizeValue: 13 },
    { height: 133, minWeight: 29, maxWeight: 41, size: SIZE_NAME_KID.K8, sizeName: "8", sizeValue: 13 },
    { height: 134, minWeight: 29, maxWeight: 42, size: SIZE_NAME_KID.K8, sizeName: "8", sizeValue: 13 },
    { height: 135, minWeight: 30, maxWeight: 42, size: SIZE_NAME_KID.K8, sizeName: "8", sizeValue: 13 },
    { height: 136, minWeight: 30, maxWeight: 43, size: SIZE_NAME_KID.K8, sizeName: "8", sizeValue: 13 },
    { height: 137, minWeight: 31, maxWeight: 44, size: SIZE_NAME_KID.K8, sizeName: "8", sizeValue: 13 },

    { height: 138, minWeight: 31, maxWeight: 44, size: SIZE_NAME_KID.K9, sizeName: "9", sizeValue: 14 },
    { height: 139, minWeight: 31, maxWeight: 44, size: SIZE_NAME_KID.K9, sizeName: "9", sizeValue: 14 },
    { height: 140, minWeight: 32, maxWeight: 45, size: SIZE_NAME_KID.K9, sizeName: "9", sizeValue: 14 },
    { height: 141, minWeight: 32, maxWeight: 45, size: SIZE_NAME_KID.K9, sizeName: "9", sizeValue: 14 },
    { height: 142, minWeight: 33, maxWeight: 46, size: SIZE_NAME_KID.K9, sizeName: "9", sizeValue: 14 },
    { height: 143, minWeight: 33, maxWeight: 47, size: SIZE_NAME_KID.K9, sizeName: "9", sizeValue: 14 },
    { height: 144, minWeight: 34, maxWeight: 48, size: SIZE_NAME_KID.K9, sizeName: "9", sizeValue: 14 },
];
const HEIGHTS = [
    { id: 0, size: "S", description: "1m46-1m52", min: 146, max: 152 },
    { id: 1, size: "M", description: "1m53-1m59", min: 153, max: 159 },
    { id: 2, size: "L", description: "1m60-1m66", min: 160, max: 166 },
    { id: 3, size: "XL", description: "1m67-1m73", min: 167, max: 173 },
    { id: 4, size: "2XL", description: "1m74-1m80", min: 174, max: 180 },
];

const WEIGHTS = [
    { id: 0, size: "S", description: "40-43kg", min: 40, max: 43 },
    { id: 1, size: "M", description: "43-46kg", min: 43, max: 46 },
    { id: 2, size: "L", description: "62-68kg", min: 62, max: 68 },
    { id: 3, size: "XL", description: "69-75kg", min: 69, max: 75 },
    { id: 4, size: "2XL", description: "75-81kg", min: 75, max: 81 },
];
const USER_INFORMATION_STATUS = {
    NotUpdate: "Chưa cập nhật",
    Verification: "Đang xác thực",
    NoData: "Chưa có dữ liệu",
};

const FLEXI_COMBO_TYPE = {
    MinMoney: 1,
    MinQuantity: 2,
};

const FLEXI_COMBO_TYPE_NAME = {
    MinMoney: "Đơn hàng tối thiểu",
    MinQuantity: "Số lượng tối thiểu",
};

var LAYOUT_BANNER = {
    HOMEPAGE: 1,
    VOUCHER: 2,
    CREATOR_PROFILE: 3,
};

var IMAGE_SITE = {
    Desktop: 1,
    Mobile: 2,
};

var IMAGE_TYPE = {
    Slider: 1,
    ArtWork: 31,
    Brand: 12,
    VariantFront: 19,
    VariantBack: 20,
    AgeVariantFront: 27,
    AgeVariantBack: 28,

    SectionProductNew: 40, //ảnh header section sản phẩm mới
    SectionProductMilestone: 41, //ảnh header section mua ngay giá tốt
    SectionProductHot: 42, //ảnh header section sản phẩm hot
    SectionProductCardForYou: 43, //ảnh header section sản phẩm cho bạn
    BannerCreatorProfile: 44, //ảnh banner trang creator profile
};

//vị trí hiển thị
var BANNER_POSITION = {
    NONE: 0,
    TOP: 1,
    CENTER: 2,
    BOTTOM: 3,
    LEFT: 4,
    RIGHT: 5,
    BACKGROUND: 6,
};
var BLOG_FLAG = {
    HIGHLIGHT: 1,
    DEFAULT: 0,
};
var SOCIAL = {
    Undefined: 0,
    Facebook: 1,
    Google: 2,
    Zalo: 3,
    Tiktok: 4,
};
var SectionId = {
    NEW_PRODUCT: 1,
    BUY_NOW: 2,
    SEARCH: 0,
};
const HOME_TAB = {
    PRINTER: 1,
    STORE: 2,
    MTO: 3,
};
const HOME_TAB_BG = {
    PRINTER: "#ffffff",
    STORE: "#ffffff",
    MTO: "#ffffff",
};

const ARTWORK_TYPE = {
    None: 0,
    Front: 1,
    Back: 2,
};

const GIFT_OBJECT_TYPE = {
    None: 0,
    FlexiCombo: 1,
};

const SIZE = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560,
    desktopL: 3840,
};

const DEVICE_INFO_INIT = {
    Desktop: 1,
    FaceBookInApp: 2,
    TiktokInApp: 3,
    ZaloInApp: 4,
};

const THIRD_PARTY_TYPE = {
    Customer: 1,
    Supplier: 2,
    Producer: 4,
    Lotus: 8,
    Creator: 16,
    Affiliator: 32,
};

const SHARE_OBJECT_TYPE = {
    Default: 0,
    Collection: 1,
    Creator: 2,
};

const MKT_VIEW_PAGE_ID = {
    HOME_PAGE: 1,
    VOUCHER: 2,
    PDP: 3,
    PROMOTION: 4,
};

const MKT_VIEW_SECTION_ID = {
    BANNER: 1,
    PROMOTION: 2,
    OTHERPRODUCT: 3,
};
const MKT_USER_ACTION_TYPE = {
    View: 1,
    Click: 2,
    AddToCart: 3,
    Checkout: 4,
    ClickAfLink: 5,
    Search: 6,
    Share: 7,
};

var TicketHistoryStatus = {
    Default: 0,
    BuyerSendRequest: 1,
    AdminReceivedRequest: 2,
    BuyerSendProduct: 3,
    AdminReceivedProduct: 4,
    AdminSendProduct: 5,
    AdminRefund: 6,
    AdminCloseTicket: 7,
    BuyerCloseTicket: 8,
    AdminAllowReceivingOff: 9,
    AdminDeclineReceivingOff: 10,
    AdminNeedMoreInfo: 11,
    AdminRefundSucceed: 12,
    AdminSendProductSucceed: 13,
};

const IMAGEABLE_TYPE = {
    StockPhoto: 1,
    UploadImage: 2,
};

const SEX = {
    Man: 0,
    Woman: 1,
    Other: 2,
};

const SEX_VI = {
    Man: "Nam",
    Woman: "Nữ",
    Other: "Khác",
};

const CUS_IMAGE_TYPE = {
    StockPhoto: 1,
    StockImage: 2,
    UploadImage: 3,
    Template: 4,
    Font: 5,
};

const StockFolderType = {
    StockPhoto: 1,
    Font: 2,
    StockImage: 3,
    PhotoTemplate: 4,
    TextTemplate: 5,
    StockAI: 7,
};

const StockFolderTypeName = {
    StockPhoto: "Họa tiết",
    Font: "Font chữ",
    StockImage: "Hình ảnh",
    PhotoTemplate: "Mẫu họa tiết",
};


const BANNER_TYPE = {
    DESKTOP: 1,
    MOBILE: 2
}
const BANNER_PAGE = {
    TRADEMARK: 1,
    SHOP: 2,
    CUSTOMIZE: 3,
    CREATOR: 4
}
const BANNER_POSITION_TYPE = {
    HOMEPAGE: 1,
    //TRADEMARK
    TOP_PROMOTION: 2,
    MIDDLE_PROMOTION: 3,
    VOUCHER: 4,
    BEST_DEAL: 5,
    NEW_DESIGN: 6,
    BEST_SELLER: 7,
    HOT_COLLECTION: 8,
    DESIGN_STYLE: 9,
    HOT_CREATOR: 10,
    //SHOP
    NEW_PRODUCT: 11,
    COMBO: 12,
    COLLECTION: 13,
    MALE: 14,
    FEMALE: 15,
    CHILD: 16,
    //CUSTOMIZE
    PRINT_DIGITAL: 17,
    COM_EMBROIDERY: 18,

    //CREATOR
    CREATOR_1: 19,
    CREATOR_2: 20, 
    CREATOR_3: 21,
}


export {
    SOCIAL,
    CONSTANTS,
    BLOG_FLAG,
    NOTIFY,
    MAINMENU,
    SUBMENU,
    PLATFORM,
    STATUS_PRODUCT,
    VOUCHER_DISCOUNT_TYPE,
    ATTRIBUTE_INPUT_TYPE,
    ATTRIBUTE_TYPE,
    JOIN_CHAR,
    WARRANTY_TYPE,
    STATUS_TRANSACTION,
    REFUND_REASONS,
    FACEBOOK_CHANNEL_FEED,
    FACEBOOK_CHANNEL_FEED_ENUM,
    USER_PROFILE_TAB,
    FACEBOOK_CHANNEL_DETAIL_TAB,
    STOCK_IN_STATUS,
    TICKET_TYPE,
    PRODUCT_STATUS,
    PAYMENT_METHOD,
    PAYMENT_METHOD_NAME,
    SELLER_PAYMENT_METHOD,
    ORDER_SOURCE,
    SOCIAL_MARKET_PLACE,
    DEFAULT_VALUE,
    ORDER_TYPE,
    ORDER_STATUS,
    PRODUCT_EDITOR_TAB,
    AGENCY_PRODUCT_EDITOR_TAB,
    STATUS_TRANSACTION_NAME,
    BANK_TRANSFER_STATUS,
    BANK_TRANSFER_STATUS_NAME,
    PRODUCT_STATUS_TYPE,
    ENTITY_STATE,
    ATTRIBUTE_TYPES,
    INPUT_TYPE,
    PRODUCT_OVERVIEW,
    STOCK_IN_STATUS_NAME,
    PRODUCT_TYPE,
    WARRANTY_STATUS,
    ATTRIBUTE_VALUE_DISPLAY_NAME,
    PATTERNS,
    REGEX,
    WARRANTY_RECEIVE_STATUS,
    STATUS_ACTIVE,
    PAYMENT_SCHEME,
    SHIPPING_SERVICE_TYPE,
    TEMPLATE_IMPORT,
    PRODUCT_PUSHING_STATUS,
    RETURN_ORDER_STATUS_NAME_ARR,
    ATTRIBUTE_DATA_TYPE,
    INITIAL_PAGING,
    PRINT_TYPE,
    HEIGHTS,
    WEIGHTS,
    SIZES_CLASSIC,
    SIZES_KID,
    SIZES_UNISEX,
    VOUCHER_TYPE,
    USER_INFORMATION_STATUS,
    FLEXI_COMBO_TYPE,
    FLEXI_COMBO_TYPE_NAME,
    VOUCHER_CONDITION_TAB_TYPE,
    VOUCHER_CONDITION_COLLECT_TYPE,
    LAYOUT_BANNER,
    IMAGE_SITE,
    IMAGE_TYPE,
    BANNER_POSITION,
    SectionId,
    HOME_TAB,
    HOME_TAB_BG,
    FAVORITE_TABS,
    ARTWORK_TYPE,
    GIFT_OBJECT_TYPE,
    SIZE,
    REVIEWGOOD,
    REVIEWBAD,
    DEVICE_INFO_INIT,
    THIRD_PARTY_TYPE,
    SHARE_OBJECT_TYPE,
    MKT_VIEW_PAGE_ID,
    MKT_USER_ACTION_TYPE,
    MKT_VIEW_SECTION_ID,
    TicketHistoryStatus,
    IMAGEABLE_TYPE,
    PRINT_TYPE_NAME,
    SEX,
    SEX_VI,
    CUS_IMAGE_TYPE,
    StockFolderType,
    StockFolderTypeName,
    BANNER_PAGE,BANNER_POSITION_TYPE,BANNER_TYPE
};
