import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";
import BaseBottomBarModal from "../../based/BaseBottomBarModal";
import { NotifyDialog } from "../../based/NotifyDialog";
import SpinnerServices from "../../based/services/SpinnerServices";
import BackgroundLogo from "./assets/BackgroundLogo.png";
import { camSpinnerLinkDTO } from "./MainPage";
import Common from "../../based/Common";

function QRModal({ show, onClose, item = { ...camSpinnerLinkDTO } }) {
    const ref = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        //get height of ref after loading and set it to maxHeight
        if (ref.current) {
            setMaxHeight((ref.current.clientHeight * 100) / window.innerHeight);
        }
    }, []);

    const downloadQr = async (model) => {
        window.location.href = `https://api.ranus.vn/api/spinner/create-qr-share/${model.id}`;
    };

    return (
        <BaseBottomBarModal isShow={show} onClose={onClose} maxHeight={maxHeight}>
            <Wrapper ref={ref}>
                <div className="body-section">
                    <div
                        className="close-icon"
                        onClick={(e) => {
                            onClose();
                        }}
                    >
                        <div className="icon">
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="background">
                        <img src={BackgroundLogo} alt="background" />
                    </div>
                    {item.linkUrl && (
                        <div className="qr-section">
                            <div className="qr-text">
                                <p>Quét mã QR quay số may mắn</p>
                            </div>
                            <div className="qr-code">
                                <QRCode id="qr-code" value={item.linkUrl} size={230} />
                            </div>
                        </div>
                    )}
                    <div className="link-section">
                        <div className="link-url">{item.linkUrl}</div>
                        <div
                            className="link-copy"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigator.clipboard.writeText(item.linkUrl);
                                NotifyDialog("Copy link thành công");
                            }}
                        >
                            <div className="icon">
                                <CopyIcon />
                            </div>
                        </div>
                    </div>
                    <div className="body-bottom">
                        <div className="link-code">
                            <p>Code: {item.partnerCode}</p>
                        </div>
                        <div className="note">{item.note}</div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="bottom-left">
                        <div
                            className="bottom-close"
                            onClick={(e) => {
                                onClose();
                            }}
                        >
                            Đóng
                        </div>
                    </div>
                    <div className="bottom-right">
                        {/* <div className="btn-share">
                            <div className="icon">
                                <ShareIcon />
                            </div>
                        </div> */}
                        <div
                            className="btn-download"
                            onClick={(e) => {
                                downloadQr(item);
                            }}
                        >
                            <div className="icon">
                                <DownloadIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </BaseBottomBarModal>
    );
}

export default QRModal;

const Wrapper = styled.div`
    padding: 16px;
    svg#qr-code {
        padding: 8px;
        background: white;
        border-radius: 10px;
    }
    .background {
        width: 100%;
        border-radius: 12px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: auto;
        object-fit: contain;
        right: 0;
        bottom: 0;
    }

    .background img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
    }

    .close-icon {
        display: flex;
        position: absolute;
        top: 8px;
        right: 8px;
    }

    .body-section {
        position: relative;
    }

    .qr-text p {
        margin-bottom: 0;
        color: #f8c828;
        text-align: center;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .qr-section {
        padding-top: 100px;
        text-align: center;
    }

    .link-section {
    }

    .body-bottom {
    }

    .qr-code {
        display: flex;
        justify-content: center;
        margin-top: 13px;
    }

    .link-section {
        display: flex;
        align-items: center;
        margin: 12px 18px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.07);
        padding: 12px 16px;
    }

    .link-url {
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .link-copy {
        margin-left: 16px;
    }

    .body-bottom {
        display: flex;
        align-items: center;
        padding: 16px;
    }

    .note {
        margin-left: auto;
    }

    .note {
        color: #fff;
        text-align: right;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .link-code {
        color: #fff;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .link-code p {
        margin-bottom: 0 !important;
    }

    .bottom {
        display: flex;
        margin-top: 12px;
    }

    .bottom-right {
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    .bottom-close {
        color: #303030;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .bottom-left {
        border-radius: 10px;
        background: var(--mu-vin-nn, #f3f3f3);
        display: flex;
        justify-content: center;
        padding: 14px;
        flex: 1;
        margin-right: 20px;
    }

    .btn-download {
        margin: 10px;
    }

    .btn-share {
        margin: 10px;
    }
`;

const CloseIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 13L1 0.999999" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const CopyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const ShareIcon = () => {
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.0615 14.5851L20.2219 8.80678C20.6432 8.41155 20.643 7.74254 20.2214 7.3476L14.061 1.57708C13.4222 0.978738 12.3774 1.43167 12.3774 2.30691V5.01099C12.2878 5.02198 12.2094 5.03297 12.131 5.03297C10.7088 5.13187 9.35386 5.47253 8.05487 6.05494C3.70997 8.02198 1 12.1648 1 16.8571V17.0558C1 17.9367 2.23342 18.1958 2.64614 17.4176C4.30347 14.2857 6.92385 12.3187 10.4065 11.4725C11.0448 11.3077 11.6943 11.2527 12.3774 11.1429V13.8558C12.3774 14.7313 13.4229 15.1841 14.0615 14.5851Z"
                stroke="#303030"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const DownloadIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13"
                stroke="#303030"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M5 8L10 13L15 8" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 13V1" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
