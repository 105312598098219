import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
const elementId = "confirm-dialog";

var NotifyDialog = function (message, delay = 1) {
    let modelElement = document.getElementById(elementId);
    if (!modelElement) {
        modelElement = document.createElement("div");
        modelElement.setAttribute("id", elementId);
        document.body.appendChild(modelElement);
    }
    ReactDOM.render(<Notify text={message} delay={delay * 1000} />, modelElement);
};

export { NotifyDialog };

function Notify({ text, delay }) {
    // base on delay to show notify
    useEffect(() => {
        const timer = setTimeout(() => {
            //remove element
            const element = document.getElementById(elementId);
            if (element) {
                element.remove();
            }
        }, delay);
        return () => clearTimeout(timer);
    }, [delay]);

    return (
        <NotifyWrapper>
            <div className="notify-wrapper">
                <span>{text}</span>
            </div>
        </NotifyWrapper>
    );
}

const NotifyWrapper = styled.div`
    .notify-wrapper {
        position: fixed;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        top: 5vh;
        z-index: 99999;
    }

    .notify-wrapper span {
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(2px);
        color: #fff;
        text-align: center;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px 12px;
    }
`;
