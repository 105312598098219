import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BasedModalStyle } from "../PartnerHome/PartnerHome";
import Commom from "../../based/Common";
import PartnerServices from "../../based/services/PartnerServices";

const ModalChangeSpecialDay = ({ isShow, onClose, colorCode, giftId, dataApply, date, setShowToast }) => {
    const isDesktop = Commom.isDesktop();
    const [specialDay, setSpecialDay] = useState([]);
    const [data, setData] = useState("");
    const [dataChoose, setDataChoose] = useState("");

    useEffect(() => {
        if (dataApply != null) {
            setData(dataApply.specialDayId);
            setDataChoose(dataApply.specialDayId);
        } else {
            setDataChoose("");
            setData("");
        }
    }, [dataApply]);

    useEffect(() => {
        if (giftId > 0) getSpecialDayByGiftId(giftId);
    }, [giftId, isShow]);

    const getSpecialDayByGiftId = async (giftId) => {
        let [err, data] = await PartnerServices.GetSpecialDayByGiftId(giftId);
        if (!err && data) {
            setSpecialDay(data);
        } else {
            setSpecialDay([]);
        }
    };

    const handleDeleteSpecialApply = async (date, id, isDelete) => {
        let [err, data] = await PartnerServices.CreateOrDeleteSpecialDayApply({
            id: 0,
            specialDayId: id,
            applyDate: date,
        });
        if (!err && data) {
            if (isDelete) {
                setData("");
                setDataChoose("");
                onClose();
            } else {
                onClose();
            }
        } else {
            setShowToast({
                isShow: true,
                message: "Lỗi khi xử lý , thử lại sau",
                type: "ERROR",
            });
        }
    };

    return (
        <BasedModalStyle
            onClick={(e) => {
                e.stopPropagation();
                onClose();
            }}
            className={`${isDesktop ? "modal_center" : "relative_modal bottom_modal"} ${isShow ? "show_modal" : ""}`}
        >
            <ModalChangeSpecialDayStyle
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className={`partner_modal `}
            >
                <div className="partner_modal_header">
                    <div className="partner_modal_header-title">Chọn ngày đặc biệt</div>
                    <span
                        className="partner_modal_close"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L14 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M14 1L0.999999 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </span>
                </div>

                <div className="partner_modal_body">
                    {specialDay.length > 0 &&
                        specialDay.map((item, idx) => {
                            return (
                                <div className="item-day-special">
                                    <input type="radio" id={`radio-${idx}`} checked={item.id == dataChoose} />
                                    <label
                                        onClick={() => {
                                            if (!data) {
                                                setDataChoose(item.id);
                                            }
                                        }}
                                        htmlFor={`radio-${idx}`}
                                    >
                                        {specialDay.length - idx} - {item.name}
                                    </label>
                                </div>
                            );
                        })}
                </div>

                <div className="box-footer-modal">
                    <div className="partner_modal_body-comfirm-btn">
                        <button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Đóng
                        </button>

                        <button
                            className="accept"
                            onClick={() => {
                                if (data) {
                                    handleDeleteSpecialApply(date, data, true);
                                } else {
                                    if (dataChoose) {
                                        handleDeleteSpecialApply(date, dataChoose, false);
                                    } else {
                                        setShowToast({
                                            isShow: true,
                                            message: "Chọn 1 ngày đặc biệt",
                                            type: "WARNING",
                                        });
                                    }
                                }
                            }}
                        >
                            {data ? "Bỏ chọn" : "Xác nhận"}
                        </button>
                    </div>
                </div>
            </ModalChangeSpecialDayStyle>
        </BasedModalStyle>
    );
};

const ModalChangeSpecialDayStyle = styled.div`
    width: 100%;
    bottom: 0;
    height: 270px;
    border-radius: 16px 16px 0 0 !important;
    position: relative;
    background-color: #fff !important;
    font-family: "Lexend";

    @media (min-width: 768px) {
        max-width: 350px;
        border-radius: 16px !important;
        overflow: hidden;
    }

    .partner_modal_body {
        padding-bottom: 80px;
        margin-top: 10px;

        .item-day-special {
            display: flex;
            align-items: center;
            margin-top: 10px;
            input {
                width: 24px;
                height: 24px;
                accent-color: #b3282d;
            }
            label {
                color: #505050;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 1;
                margin-bottom: 0;
                margin-left: -25px;
                padding: 0 0 0 45px;
                z-index: 1;
            }
        }
    }

    .box-footer-modal {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #fff;
        height: 60px;

        .partner_modal_body-comfirm-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            padding: 0 16px;
            z-index: 1;
            background-color: #fff;

            button {
                border: none;
                outline: none;
                border-radius: 10px;
                width: 125px;
                height: 38px;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                line-height: 38px;
                text-align: center;
                border: 2px solid #f4f4f4;
                color: #b3282d;
                background-color: #fff;
                line-height: 1;
                margin: 0 5px;

                &.accept {
                    background: #b3282d;
                    color: #fff;
                }
            }
        }
    }
`;

export default ModalChangeSpecialDay;
