import BaseEcomServices from "./BaseEcomServices";

const AccountServices = {
    // Login
    Login: async (model, redirect) => {
        return await BaseEcomServices.Post(`/api/accounts/login?${redirect}`, model);
    },
    // Info
    GetUserById: async () => {
        return await BaseEcomServices.Get(`/api/accounts/get-user-info`);
    },
};

export default AccountServices;
