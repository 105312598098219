import React, {useEffect , useState} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PartnerServices from "../../based/services/PartnerServices";
import Common from "../../based/Common";
import PartnerTooltip from "../components/PartnerTooltip";

const PartnerWinningList = ({campaignId}) => {
    const history = useHistory()
    const [data, setData] = useState()
    const [date , setDate] = useState(new Date())
    const [orderDirection , setOrderDirection] = useState("DESC")

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    },[])
    
    useEffect(()=>{
        getUserWin(new Date(), "DESC")
    },[campaignId])

    const getUserWin = async (date , orderDirection) => {
        let [err, data] = await PartnerServices.GetPartnerUserWin({
            date, orderDirection, campaignId
        });
        if (!err && data && data.length > 0) {
            setData(data);
        } else {    
            setData([])
        }
        
    }

    return (
        <PartnerWinningListStyle className="partner-home-body">
            <div className="body-container">
                <div className="winning-title">
                    <h4>
                        <span>Danh sách trúng thưởng</span>

                    <span className="button-detail">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_8397_2569)">
                            <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6 8V6" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6 4H6.005" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_8397_2569">
                            <rect width="12" height="12" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <PartnerTooltip title={ <>
                                                Màu xanh: đã nhận quà<br />
                                                Màu đỏ: hết thời gian nhận<br />
                                                Màu trắng: chưa nhận quà
                                            </>} position={"bottom"}/>
                    </span>
                    </h4>
                </div>
                <div className="input-date-box">
                <input type="date" className="input-date"  onChange={(e)=>{
                    setDate(e.target.value)
                    getUserWin(e.target.value , orderDirection)
                }} />
                <span>{Common.formatDate(new Date(date))}</span>
                </div>
                <table className="winning-table">
                    <tr className="winning-table-header">
                        <th style={{width: 40}}>STT</th>
                        <th style={{width: 100}}>Tên tài khoản</th>
                        <th>Quà tặng</th>
                        <th style={{width: 100}}
                            onClick={()=>{
                                setOrderDirection(orderDirection == "DESC" ? "ASC" : "DESC");
                                getUserWin(date , orderDirection == "DESC" ? "ASC" : "DESC")
                            }}
                        
                        >Thời gian
                            <svg className="ml-1" width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6L5.5 1L10 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10 11L5.5 16L1 11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </th>
                        <th>Trạng thái</th>
                    </tr>
                    {data && data.length > 0 && data.map((item , idx)=>{
                        return <tr key={idx} className={`winning-table-body ${item.isPinVerified  ? "row-success" : item.isOutDate ? "row-error" : ""}`}>
                            <td>{idx + 1}</td>
                            <td style={{textAlign: 'left'}}>{item.fullName}</td>
                            <td style={{textAlign: 'left'}}>
                                {item.prizeType == 0 ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_8398_3235)">
                                            <path d="M11.5402 4.12147V4.40147C11.5042 4.54947 11.4762 4.70147 11.4282 4.84147C11.3461 5.10778 11.1959 5.34804 10.9924 5.53846C10.7889 5.72887 10.5392 5.86284 10.2681 5.92709C9.9969 5.99135 9.71365 5.98367 9.44636 5.9048C9.17908 5.82594 8.93703 5.67863 8.74417 5.47747C8.69217 5.42547 8.64817 5.36547 8.59617 5.30547C8.45188 5.51666 8.25824 5.68946 8.03205 5.80887C7.80586 5.92828 7.55395 5.99069 7.29817 5.99069C7.04239 5.99069 6.79048 5.92828 6.56429 5.80887C6.3381 5.68946 6.14446 5.51666 6.00017 5.30547C5.85575 5.51584 5.66228 5.6879 5.43649 5.80677C5.21069 5.92565 4.95935 5.98777 4.70417 5.98777C4.44899 5.98777 4.19765 5.92565 3.97185 5.80677C3.74606 5.6879 3.55259 5.51584 3.40817 5.30547L3.38017 5.32947L3.30817 5.41347C3.09079 5.68203 2.78922 5.86946 2.45217 5.94547C2.09457 6.02285 1.72124 5.97533 1.39443 5.81083C1.06762 5.64633 0.807051 5.37479 0.65617 5.04147C0.48737 4.73573 0.428096 4.3815 0.48817 4.03747C0.68417 3.20947 0.88817 2.38147 1.08817 1.55347C1.11718 1.3858 1.20628 1.23443 1.33879 1.12769C1.47131 1.02094 1.63817 0.966115 1.80817 0.973468H10.2082C10.3784 0.965242 10.5457 1.01974 10.6784 1.12664C10.8111 1.23354 10.9 1.38541 10.9282 1.55347C11.0682 2.12147 11.2042 2.68947 11.3282 3.25747L11.5402 4.12147Z" fill="#B3282D"/>
                                            <path d="M7.38424 11.0189V8.15489H4.62024V11.0189H1.91624C1.81058 11.0275 1.70434 11.0124 1.60527 10.9747C1.50619 10.937 1.41678 10.8776 1.34357 10.801C1.27036 10.7243 1.21519 10.6323 1.18209 10.5316C1.14899 10.4309 1.13879 10.324 1.15224 10.2189V6.49889C1.25624 6.53489 1.35224 6.57489 1.44824 6.60289C1.76009 6.70676 2.09176 6.73697 2.41724 6.69117C2.74272 6.64536 3.05317 6.52479 3.32424 6.33889C3.34661 6.3217 3.37403 6.31238 3.40224 6.31238C3.43045 6.31238 3.45787 6.3217 3.48024 6.33889C3.84269 6.57669 4.26674 6.70338 4.70024 6.70338C5.13374 6.70338 5.55779 6.57669 5.92024 6.33889C5.94385 6.32104 5.97264 6.31139 6.00224 6.31139C6.03184 6.31139 6.06063 6.32104 6.08424 6.33889C6.4462 6.57405 6.86859 6.69921 7.30024 6.69921C7.73189 6.69921 8.15427 6.57405 8.51624 6.33889C8.57224 6.30689 8.60824 6.29089 8.66824 6.33889C8.97071 6.54699 9.32156 6.67394 9.68717 6.70755C10.0528 6.74117 10.4209 6.68034 10.7562 6.53089L10.8482 6.49889V10.2149C10.8613 10.3213 10.8503 10.4294 10.8161 10.531C10.7818 10.6327 10.7251 10.7253 10.6502 10.8021C10.5754 10.8789 10.4842 10.9379 10.3835 10.9747C10.2827 11.0115 10.175 11.0253 10.0682 11.0149H7.38424V11.0189Z" fill="#B3282D"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_8398_3235">
                                            <rect width="12" height="12" fill="white"/>
                                            </clipPath>
                                            </defs>
                                            </svg> :
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="12" height="12" rx="3" fill="black"/>
                                            <path d="M9.74987 4.92923V4.38849C9.74987 3.82133 9.51282 3.2774 9.09087 2.87635C8.66892 2.4753 8.09664 2.25 7.49991 2.25H3V9.75H5.24996V3.33452H6.37815C6.67294 3.33608 6.95538 3.44724 7.16503 3.64422C7.37468 3.8412 7.49488 4.10834 7.49991 4.38849V4.92923C7.49991 5.21362 7.38105 5.48636 7.16947 5.68746C6.9579 5.88855 6.67094 6.00153 6.37172 6.00153H5.81887V7.07077H6.38136C6.67891 7.07319 6.96339 7.18723 7.17289 7.38807C7.38239 7.5889 7.49992 7.86026 7.49991 8.14308V9.75H9.74987V8.14308C9.75331 7.83855 9.68827 7.53683 9.55912 7.25813C9.42996 6.97942 9.23966 6.73014 9.00096 6.52699C9.23722 6.32588 9.42608 6.07957 9.55514 5.80421C9.68421 5.52885 9.75057 5.23067 9.74987 4.92923Z" fill="white"/>
                                            </svg>
                                            
                                }
                                <span>{item.nameGift}</span>    
                            </td>   
                            <td>{Common.formatDate(new Date(item.spinTime), 'fulldate')}</td>
                            <td>{item.isPinVerified  ? "Đã nhận" : item.isOutDate ? "Hết hạn" : "Chưa nhận"}</td>
                        </tr>
                    })}
                </table>
            </div>
        </PartnerWinningListStyle>
    );
};
const PartnerWinningListStyle = styled.div`

.input-date-box{
    position: relative;
    .input-date{
        margin-top: 10px;
        border-radius: 10px;
        border: 1px solid #E1E1E1;
        background: #FFF;
        width: 200px;
        height: 38px;
        padding: 0 17px;
    }

    span{
        position: absolute;
        background: rgb(255, 255, 255);
        height: 32px;
        left: 17px;
        display: block;
        width: 100px;
        line-height: 38px;
        top: 11px;
    }
}


    .winning-title{
        display: flex;
        align-items: center;
        justify-content: space-between;

        

        h4{
            display: flex;
            align-items: center;
            
            span{
                color: #505050;
                font-family: Lexend;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }

            .button-detail{
                position: relative;
                margin-left: 20px;

                svg{
                    transform: scale(1.5);
                }
                .customise-tooltip{
                    text-align: left;
                    .customise-tooltip-content{
                        line-height: 1.2;
                    }
                }
    
                :hover{
                    .customise-tooltip{
                        display: block;
                    }
                }
            }
        }

        

        
    }

    .winning-table{
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 12px;


        .winning-table-header{
            th, td{
                border: 1px solid #E1E1E1;
                background: #7A7A7A;
                color: #FFF;
                text-align: center;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 8px 0;

            }
        }
        .winning-table-body{
            th, td{
                border: 1px solid #E1E1E1;
                background: #FFF;
                color: #505050;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                padding: 6px;

                svg{
                    
                    margin-right: 5px;
                }

            }

            &.row-success{
                th, td{
                    background-color: #C2F2D3;
                }
            }

            &.row-error{
                th, td{
                    background-color: #FFD3D5;
                }
            }
        }

    }
   
`
export default PartnerWinningList;






