import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PartnerTooltip from "../components/PartnerTooltip";
import PartnerServices from "../../based/services/PartnerServices";
import Common from "../../based/Common";

const TAB_PARTNER = {
    PARTNER: 1,
    RANUS: 0,
    COLLAB: 3,
};

const PartnerGift = ({ setIsShowModalChangePin, landingPage, 
    campaign 
    ,setIsShowModalGiftShow, setIsShowModalGift, isShowModalGift }) => {
    const history = useHistory();
    const [tab, setTab] = useState(TAB_PARTNER.PARTNER);
    const [partnerCollab, setPartnerCollab] = useState({});
    const [gifts, setGifts] = useState([]);


    useEffect(()=>{
        if (campaign && campaign.id) {
            getPartnerCollab(campaign.id);
            getGiftInCamPaign(campaign.id, TAB_PARTNER.PARTNER);
        }else{
            setGifts([])
            setPartnerCollab({})
        }
    },[campaign])

 
    useEffect(() => {
        if (!isShowModalGift.isShow) getGiftInCamPaign(campaign.id, TAB_PARTNER.PARTNER);
    }, [isShowModalGift]);

    
    const getPartnerCollab = async (campaignId) => {
        let [err, data] = await PartnerServices.GetPartnerCollabByCampaign(campaignId);
        if (!err && data && data.length > 0) {
            setPartnerCollab(data[0]);
        } else {
            setPartnerCollab({});
        }
    };

    const getGiftInCamPaign = async (id, type) => {
        let [err, data] = await PartnerServices.GetGiftByCampaignId(id, type);
        if (!err && data && data.length > 0) {
            setGifts(data);
        } else {
            setGifts([]);
        }
    };

    const getGiftCollabInCamPaign = async (campaignId, collabId) => {
        let [err, data] = await PartnerServices.GetGiftCollabByCampaignId(campaignId, collabId);
        if (!err && data && data.length > 0) {
            setGifts(data);
        } else {
            setGifts([]);
        }
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <PartnerGiftStyle className="partner-home-body">
            <div className="body-container">
                <div className="gift-pin">
                    <div className="gift-pin-left">
                        <div className="gift-pin-box">
                            <strong>Mã PIN</strong>
                            <div>
                                <span>{campaign.pin}</span>
                                <span
                                    onClick={() => {
                                        setIsShowModalChangePin({
                                            isShow: true,
                                            pin: campaign.pin,
                                            campaignId: campaign.id,
                                        });
                                    }}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.0738 6.3997L7.77616 16.7104L1 19L3.28667 12.2152L13.6072 1.9274C13.9003 1.63341 14.2484 1.40018 14.6316 1.24104C15.0148 1.08191 15.4256 1 15.8405 1C16.2553 1 16.6661 1.08191 17.0493 1.24104C17.4326 1.40018 17.7807 1.63341 18.0738 1.9274C18.3674 2.22087 18.6003 2.56943 18.7593 2.95313C18.9182 3.33684 19 3.74816 19 4.16355C19 4.57895 18.9182 4.99026 18.7593 5.37397C18.6003 5.75767 18.3674 6.10623 18.0738 6.3997V6.3997Z"
                                            stroke="#505050"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M11.5898 3.91797L15.7678 8.33656" stroke="#505050" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <span className="button-detail">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_8398_3275)">
                                    <path
                                        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                        stroke="#A0A0A0"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path d="M9 12V9" stroke="#A0A0A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 6H9.00667" stroke="#A0A0A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_8398_3275">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <PartnerTooltip title={"Mã này được nhập trên máy khách trúng thưởng để xác nhận quà tặng"} position={"bottom"} />
                        </span>
                    </div>
                    {campaign && campaign.id && tab === TAB_PARTNER.PARTNER && (
                        <button
                            className="button-add-gift"
                            onClick={() => {
                                setIsShowModalGift({
                                    isShow: true,
                                    giftItem: {
                                        name: "",
                                        id: 0,
                                        marketingCampaignId: campaign.id,
                                        giftType: 1,
                                    },
                                });
                            }}
                        >
                            Thêm giải thưởng
                        </button>
                    )}
                </div>
                <div className="gift-container">
                    <div className="gift-tab">
                        <div
                            style={{ width: partnerCollab && partnerCollab.id ? "33.33333%" : "50%" }}
                            className={`gift-tab-item ${tab === TAB_PARTNER.PARTNER ? "active" : ""}`}
                            onClick={() => {
                                setTab(TAB_PARTNER.PARTNER);
                                getGiftInCamPaign(campaign.id, TAB_PARTNER.PARTNER);
                            }}
                        >
                            {landingPage.brandName}
                        </div>
                        {partnerCollab && partnerCollab.id && (
                            <div
                                style={{ width: "33.33333%" }}
                                className={`gift-tab-item ${tab === TAB_PARTNER.COLLAB ? "active" : ""}`}
                                onClick={() => {
                                    setTab(TAB_PARTNER.COLLAB);
                                    getGiftCollabInCamPaign(campaign.id, partnerCollab.id)
                                }}
                            >
                                {partnerCollab.brandName}
                            </div>
                        )}

                        <div
                            style={{ width: partnerCollab && partnerCollab.id ? "33.33333%" : "50%" }}
                            className={`gift-tab-item ${tab === TAB_PARTNER.RANUS ? "active" : ""}`}
                            onClick={() => {
                                setTab(TAB_PARTNER.RANUS);
                                getGiftInCamPaign(campaign.id, TAB_PARTNER.RANUS);
                            }}
                        >
                            Ranus
                        </div>
                    </div>
                    <div className="gift-date" style={{ borderRadius: tab === TAB_PARTNER.PARTNER ? "0 10px 0 0" : "10px 0 0 0" }}>
                        {campaign && campaign.startDate && campaign.endDate
                            ? `Chương trình bắt đầu từ ${Common.formatDate(new Date(campaign.startDate), "month")} đến ${Common.formatDate(new Date(campaign.endDate), "month")}`
                            : "Chưa có chiến dịch nào"}
                    </div>
                    <div className="box-gift-table">
                        <table className="gift-table">
                            <tr className="gift-table-header">
                                <th>Giải thưởng</th>
                                <th style={{ width: 100 }}>Tổng phần quà</th>
                            </tr>
                            {gifts.length > 0 &&
                                gifts.map((item, idx) => {
                                    return (
                                        <tr
                                            key={idx}
                                            className={`gift-table-body`}
                                            onClick={() => {
                                                if (tab === TAB_PARTNER.RANUS || tab === TAB_PARTNER.COLLAB)
                                                    setIsShowModalGiftShow({
                                                        isShow: true,
                                                        giftItem: item,
                                                    });
                                                else
                                                    setIsShowModalGift({
                                                        isShow: true,
                                                        giftItem: item,
                                                    });
                                            }}
                                        >
                                            <td style={{ textAlign: "left" }}>{item.name}</td>
                                            <td>{item.countTotalAllCampaign}</td>
                                        </tr>
                                    );
                                })}
                        </table>
                    </div>
                </div>
                <div className="gift-remind">Chiến dịch được Ranus tạo</div>
                {campaign && campaign.id > 0 && tab === TAB_PARTNER.PARTNER && (
                    <div
                        className="gift-add"
                        onClick={() => {
                            setIsShowModalGift({
                                isShow: true,
                                giftItem: {
                                    name: "",
                                    id: 0,
                                    marketingCampaignId: campaign.id,
                                    giftType: 1,
                                },
                            });
                        }}
                    >
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill="#B3282D" />
                            <path d="M24 15V33" stroke="white" strokeWidth="3" strokeLinecap="round" />
                            <path d="M33 24L15 24" stroke="white" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                    </div>
                )}
            </div>
        </PartnerGiftStyle>
    );
};
const PartnerGiftStyle = styled.div`
    @media (min-width: 768px) {
        .gift-add {
            display: none;
        }
        .gift-pin {
            .button-add-gift {
                display: block !important;
            }
        }
    }

    .gift-remind {
        color: #000;
        text-align: center;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        margin-top: 100px;
    }

    .gift-pin {
        display: flex;
        justify-content: space-between;

        .gift-pin-left {
            display: flex;
            align-items: center;

            .button-detail {
                position: relative;

                :hover {
                    .customise-tooltip {
                        display: block;
                    }
                }
            }

            span {
                margin-left: 10px;
                display: block;
            }
        }
        .gift-pin-box {
            border-radius: 10px;
            border: 1px solid #e1e1e1;
            background: #fff;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 210px;
            padding: 0 12px;

            strong {
                color: #505050;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            div {
                display: flex;
            }
            span {
                color: #b9b9b9;
                font-family: Lexend;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: block;
                margin-left: 10px;
            }
        }
        .button-add-gift {
            display: none;
            border-radius: 12px;
            background: #b3282d;
            border: none;
            outline: none;
            height: 36px;
            padding: 0 10px;
            color: #fff;
            text-align: center;
            font-family: Lexend;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
        }
    }

    .gift-container {
        margin-top: 20px;
        .gift-tab {
            display: flex;

            .gift-tab-item {
                color: #444;
                font-family: Lexend;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px;
                text-align: center;
                border-radius: 10px 10px 0px 0px;
                height: 44px;

                &.active {
                    color: #b3282d;
                    background: #fff;
                }
            }
        }

        .gift-date {
            text-align: center;
            height: 34px;
            background: #ffd3d5;
            color: #b3282d;
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px;
        }

        .box-gift-table {
            background-color: #ffffff;
            padding: 12px 12px 60px 12px;
            border-radius: 0 0 10px 10px;

            .gift-table {
                border-radius: 10px;
                overflow: hidden;
                width: 100%;

                .gift-table-header {
                    th,
                    td {
                        border: 1px solid #e1e1e1;
                        background: #7a7a7a;
                        color: #fff;
                        text-align: center;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding: 8px 0;
                    }
                }
                .gift-table-body {
                    th,
                    td {
                        border: 1px solid #e1e1e1;
                        background: #fff;
                        color: #505050;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-align: center;
                        padding: 10px;
                    }

                    &.row-even {
                        th,
                        td {
                            background-color: #ededed;
                        }
                    }

                    &.row-error {
                        th,
                        td {
                            background-color: #ffbdbd;
                            color: #ce0000;
                        }
                    }
                }
            }
        }
    }

    .gift-add {
        position: fixed;
        left: 12px;
        bottom: 72px;
    }
`;
export default PartnerGift;
