import BaseEcomServices from "./BaseEcomServices";

const AffiliateServices = {

    GetThirdPartyInfo: async () => {
        return await BaseEcomServices.Get(`/api/affiliate/get-third-party-info`);
    },
    GetOrderCommission: async (paging) => {
        return await BaseEcomServices.Post(`/api/affiliate/get-order-commissions`, paging);
    },
    GetAffiliateTraffic: async () => {
        return await BaseEcomServices.Get(`/api/affiliate/get-affiliate-traffic`);
    },

    SaveAffiliateVoucher: async (code) => {
        return await BaseEcomServices.Get(`/api/affiliate/save-voucher-affiliate/${code}`);
    },
    GetLatestVoucherAffiliate: async () => {
        return await BaseEcomServices.Get(`/api/affiliate/get-latest-voucher-affiliate`);
    },

};

export default AffiliateServices;
