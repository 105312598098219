import React from "react";
import { Helmet } from "react-helmet";
var PropTypes = require("prop-types");

export default function TFUHelmet(props) {
    const { structuredJSON, keywords, description, robots, title, ogimage, ogtitle, ogurl, ogdescription, canonical } = props;
    return (
        <Helmet>
            {structuredJSON && (
                <script className="structured-data-list" type="application/ld+json">
                    {structuredJSON}
                </script>
            )}
            {keywords && <meta name="keywords" content={keywords} />}
            {description && <meta name="description" content={description} />}
            {robots && <meta name="robots" content={robots} />}
            {title && <meta name="title" content={title} />}
            {ogimage && <meta property="og:image" content={ogimage} />}
            {ogtitle && <meta property="og:title" content={ogtitle} />}
            {ogurl && <meta property="og:url" content={ogurl} />}
            {ogdescription && <meta property="og:description" content={ogdescription} />}
            {canonical && <link rel="canonical" href={canonical} />}
        </Helmet>
    );
}

TFUHelmet.propTypes = {
    keywords: PropTypes.string,
    description: PropTypes.string,
    robots: PropTypes.string,
    title: PropTypes.string,
    ogimage: PropTypes.string,
    ogtitle: PropTypes.string,
    ogurl: PropTypes.string,
    ogdescription: PropTypes.string,
    canonical: PropTypes.string,
};



export class SeoProductModel {
    constructor() {
        this.productID = "";
        this.sku = "";
        this.name = "";
        this.description = "";
        this.image = "";
        this.brand = "";
        this.url = "";
        this.googleProductCategory = "";
        this.offers = [new SeoVariantModel()];
        this.ratingValue = 0;
        this.reviewCount = 0;
        this.price = 0;
        this.priceCurrency = "";
        this.priceValidUntil = "";
        this.salePrice = "";
        this.salePriceEffectiveDay = "";
        this.availability = "";
        this.condition = "";
    }
}

export class SeoVariantModel {
    constructor() {
        this.price = "";
        this.priceCurrency = "";
        this.salePrice = "";
        this.salePriceEffectiveDay = "";
        this.availability = "";
        this.url = "";
        this.size = "";
        this.color = "";
        this.condition = "";
        this.priceValidUntil = "";
    }
}
