/***** AUTHENTICATION *****/
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_UPDATE_PERSONAL = "AUTH_UPDATE_PERSONAL";

/***** NOTIFICATION *****/
export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_REMOVE = "NOTIFICATION_REMOVE";

/***** CART *****/
export const CART_GET = "CART_GET";
export const CART_ADD = "CART_ADD";
export const CART_REMOVE = "CART_REMOVE";
export const CART_CLEAR = "CART_CLEAR";
export const CART_UPDATE = "CART_UPDATE";
export const CART_UPDATE_CHECK_STATUS = "CART_UPDATE_CHECK_STATUS";
export const CART_UPDATE_ALL_CHECK_STATUS = "CART_UPDATE_ALL_CHECK_STATUS";

/***** VOUCHER *****/
export const VOUCHER_GET = "VOUCHER_GET";
export const VOUCHER_UPDATE = "VOUCHER_UPDATE";
export const VOUCHER_COLLECT = "VOUCHER_COLLECT";
export const VOUCHER_UN_APPLY = "VOUCHER_UN_APPLY";

/***** FLEXICOMBO *****/
export const FLEXICOMBO_GET = "FLEXICOMBO_GET";
export const FLEXICOMBO_COLLECT = "FLEXICOMBO_COLLECT";

/***** FAVORITE LIST *****/
export const FAVORITE_LIST_GET = "FAVORITE_LIST_GET";
export const FAVORITE_LIST_ADD = "FAVORITE_LIST_ADD";
export const FAVORITE_LIST_REMOVE = "FAVORITE_LIST_REMOVE";

/***** DESIGN *****/
export const DESIGN_ADD = "DESIGN_ADD";
export const DESIGN_UPDATE = "DESIGN_UPDATE";
export const DESIGN_REMOVE = "DESIGN_REMOVE";
export const DESIGN_ACTIVE = "DESIGN_ACTIVE";
export const DESIGN_INACTIVE_ALL = "DESIGN_INACTIVE_ALL";
export const DESIGN_SET_PRODUCT = "DESIGN_SET_PRODUCT";

/***** DESIGN *****/
export const CHANGE_HOME_TAB = "CHANGE_HOME_TAB";

/***** SIZE *****/
export const SIZE_UPDATE = "SIZE_UPDATE";
