import React from "react";
import styled from "styled-components";
import GlobalStyle from "../based/GlobalStyle";

const Backdrop = ({ isShow, zIndex, onClear, classBackDrop, opacity = 0 }) => {
    return isShow ? (
        <Wrapper opacity={opacity}>
            <div className={`backdrop ${classBackDrop ? classBackDrop : ""}`} style={{ zIndex: zIndex }} onClick={onClear}></div>
            <GlobalStyle />
        </Wrapper>
    ) : null;
};

export default Backdrop;

const Wrapper = styled.div`
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => (props.opacity >= 0 ? `rgba(0, 0, 0, ${props.opacity})` : `rgba(0, 0, 0, 0.8)`)};
        height: 100vh;
        overflow: hidden !important;
    }
`;
