import React, {  useEffect, useState } from "react";
import styled from "styled-components"
const PartnerTooltip = ({title, position}) => {

    return (
        <PartnerTooltipStyle className="customise-tooltip" style={
            {   ...(position === "bottom" && {top: 'calc(100% + 10px)', left: '50%', transform: 'translateX(-50%)' } ),
                ...(position === "left" && {top: '50%', transform: 'translateY(-50%)', right: 'calc(100% + 10px)'} ),
                ...(position === "top" && {bottom: 'calc(100% + 10px)', left: '50%', transform: 'translateX(-50%)'} ),
                ...(position === "right" && {top: '50%', transform: 'translateY(-50%)', left: 'calc(100% + 10px)'} ),
            }
        }>
            <div className="customise-tooltip-content">{title}</div>
            <div className="customise-tooltip-polygon"
                style={
                    {   ...(position === "bottom" && {bottom: 'calc(100% - 1px)', left: '50%', transform: 'translateX(-50%)'} ),
                        ...(position === "left" && {top: '50%', transform: 'translateY(-50%) rotate(90deg)', left: 'calc(100% - 2px)'} ),
                        ...(position === "top" && {top: 'calc(100% - 1px)', left: '50%', transform: 'translateX(-50%) rotate(180deg)'} ),
                        ...(position === "right" && {top: '50%', transform: 'translateY(-50%) rotate(-90deg)', right: 'calc(100% - 2px)'} ),
                    }
                }
            >
                <Polygon />
            </div>
        </PartnerTooltipStyle>
    );
};
const Polygon = () => {
    return (
        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.23207 1C5.46227 -0.333333 3.53777 -0.333334 2.76797 1L0 6H9.00003L6.23207 1Z" fill="rgba(68, 68, 68, 0.80)"/>
        </svg>

        )
}

const PartnerTooltipStyle = styled.div`
    position: absolute;
    display: none;
    z-index: 999;
    width: 200px;
    text-align: center;
    
    .customise-tooltip-content{
        color: #FFF;
        font-size: 14px;
        font-weight: 400;
        padding: 9px;
        background: rgba(68, 68, 68, 0.80);
        backdrop-filter: blur(2px);
        border-radius: 8px;

    }

    .customise-tooltip-polygon{
        position: absolute;
        line-height: 1;
        display: grid;
        place-item: center;

        
        svg{
            
        }
    }
    
    
`

export default PartnerTooltip;
