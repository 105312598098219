import React from "react";
import BaseBottomBarModal from "../../based/BaseBottomBarModal";
import styled from "styled-components";

function ArchiveModal({ show, onClose }) {
    return (
        <BaseBottomBarModal isShow={show} onClose={onClose} title={"Mã đã ẩn"}>
            <Wrapper>
                <div className="table-section">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Link</th>
                                <th>Trạng thái</th>
                                <th colSpan={2}>Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p className="link">https://ranus.vn/abc</p>
                                    <p className="link-code">Mã: chava123456</p>
                                    <p className="link-date">Ngày tạo: 12.10.2024</p>
                                </td>
                                <td>
                                    <p className="use">Đã dùng</p>
                                    <p className="user-info">User: G*****</p>
                                    <div className="gift-icon">
                                        <div className="icon">
                                            <GiftIcon />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="note">Anh Hào</p>
                                    <div className="edit-note">
                                        <div className="icon">
                                            <EditNoteIcon />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <button className="btn back-archive-btn">
                                        <div className="icon">
                                            <BackArchiveIcon />
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    className="close-button"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClose();
                    }}
                >
                    <div className="close-btn">Đóng</div>
                </div>
            </Wrapper>
        </BaseBottomBarModal>
    );
}

export default ArchiveModal;

const Wrapper = styled.div`
    margin: 16px;
    overflow: auto;

    tr th {
        vertical-align: middle !important;
        text-align: center;
        color: var(--Tiu, #444);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    tr {
    }

    tr td {
        vertical-align: middle !important;
    }

    td p {
        margin-bottom: 0 !important;
    }

    p.link {
        overflow: hidden;
        color: var(--Ca-hng, #1ba6f9);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.link-code {
        color: #a0a0a0;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.link-date {
        color: #a0a0a0;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.not-get {
        color: #b3282d;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.get {
        color: #35aa49;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.use {
        color: #35aa49;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.user-info {
        color: #35aa49;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.note {
        color: #303030;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .edit-note {
        display: flex;
    }

    tr.active {
        background: #ebf7ed;
    }

    .sc-eqUAAy {
        margin: 16px;
        overflow: auto;
    }

    tr th {
        color: var(--Tiu, #444);
        text-align: center;
        font-family: Lexend;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: 1px solid #e5e5e5;
        background: #e5e5e5;
        vertical-align: middle !important;
    }

    tr td {
        color: #303030;
        text-align: left !important;
        font-family: Lexend;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        vertical-align: middle !important;
    }

    .gift-name {
        text-align: left;
        display: flex;
    }

    .icon {
        display: flex;
        margin-top: 4px;
    }

    .gift-name span {
        color: #303030;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 6px;
    }

    .location {
        color: var(--Ca-hng, #1ba6f9);
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 12px;
    }

    .close-button {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .close-btn {
        width: 100%;
        border-radius: 10px;
        background: var(--mu-vin-nn, #f3f3f3);
        color: #303030;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        padding: 12.5px;
        margin: 8px 16px;
    }
`;

const StoreSVG = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4746_1128)">
                <path
                    d="M11.54 4.11952V4.39952C11.504 4.54752 11.476 4.69952 11.428 4.83952C11.346 5.10583 11.1958 5.34609 10.9923 5.5365C10.7888 5.72692 10.5391 5.86089 10.2679 5.92514C9.99678 5.9894 9.71353 5.98171 9.44624 5.90285C9.17896 5.82399 8.9369 5.67668 8.74405 5.47552C8.69205 5.42352 8.64805 5.36352 8.59605 5.30352C8.45176 5.51471 8.25812 5.68751 8.03193 5.80692C7.80574 5.92632 7.55382 5.98873 7.29805 5.98873C7.04227 5.98873 6.79036 5.92632 6.56417 5.80692C6.33797 5.68751 6.14433 5.51471 6.00005 5.30352C5.85563 5.51389 5.66216 5.68595 5.43636 5.80482C5.21057 5.92369 4.95923 5.98581 4.70405 5.98581C4.44887 5.98581 4.19753 5.92369 3.97173 5.80482C3.74593 5.68595 3.55247 5.51389 3.40805 5.30352L3.38005 5.32752L3.30805 5.41152C3.09067 5.68008 2.78909 5.8675 2.45205 5.94352C2.09444 6.02089 1.72112 5.97337 1.39431 5.80888C1.06749 5.64438 0.806929 5.37284 0.656048 5.03952C0.487248 4.73378 0.427974 4.37955 0.488048 4.03552C0.684048 3.20752 0.888048 2.37952 1.08805 1.55152C1.11706 1.38384 1.20615 1.23248 1.33867 1.12573C1.47118 1.01899 1.63804 0.964162 1.80805 0.971515H10.208C10.3783 0.963289 10.5456 1.01779 10.6783 1.12469C10.811 1.23159 10.8998 1.38346 10.928 1.55152C11.068 2.11952 11.204 2.68752 11.328 3.25552L11.54 4.11952Z"
                    fill="#B3282D"
                />
                <path
                    d="M7.38399 11.0208V8.15684H4.62V11.0208H1.916C1.81034 11.0294 1.7041 11.0143 1.60502 10.9766C1.50595 10.9389 1.41654 10.8796 1.34333 10.8029C1.27012 10.7263 1.21495 10.6342 1.18184 10.5335C1.14874 10.4328 1.13854 10.326 1.152 10.2208V6.50084C1.256 6.53684 1.352 6.57684 1.448 6.60484C1.75984 6.70871 2.09151 6.73893 2.41699 6.69312C2.74248 6.64732 3.05293 6.52674 3.324 6.34084C3.34636 6.32365 3.37378 6.31433 3.402 6.31433C3.43021 6.31433 3.45763 6.32365 3.48 6.34084C3.84245 6.57864 4.2665 6.70533 4.7 6.70533C5.13349 6.70533 5.55754 6.57864 5.92 6.34084C5.94361 6.323 5.9724 6.31334 6.002 6.31334C6.03159 6.31334 6.06038 6.323 6.084 6.34084C6.44596 6.576 6.86835 6.70117 7.29999 6.70117C7.73164 6.70117 8.15403 6.576 8.516 6.34084C8.572 6.30884 8.608 6.29284 8.66799 6.34084C8.97047 6.54895 9.32132 6.67589 9.68692 6.70951C10.0525 6.74313 10.4206 6.6823 10.756 6.53284L10.848 6.50084V10.2168C10.8611 10.3233 10.8501 10.4313 10.8158 10.533C10.7815 10.6346 10.7249 10.7273 10.65 10.804C10.5751 10.8808 10.4839 10.9398 10.3832 10.9767C10.2825 11.0135 10.1747 11.0272 10.068 11.0168H7.38399V11.0208Z"
                    fill="#B3282D"
                />
            </g>
            <defs>
                <clipPath id="clip0_4746_1128">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const RanusSVG = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="12" rx="3" fill="#303030" />
            <path
                d="M9.74987 4.92923V4.38849C9.74987 3.82133 9.51282 3.2774 9.09087 2.87635C8.66892 2.4753 8.09664 2.25 7.49991 2.25H3V9.75H5.24996V3.33452H6.37815C6.67294 3.33608 6.95538 3.44724 7.16503 3.64422C7.37468 3.8412 7.49488 4.10834 7.49991 4.38849V4.92923C7.49991 5.21362 7.38105 5.48636 7.16947 5.68746C6.9579 5.88855 6.67094 6.00153 6.37172 6.00153H5.81887V7.07077H6.38136C6.67891 7.07319 6.96339 7.18723 7.17289 7.38807C7.38239 7.5889 7.49992 7.86026 7.49991 8.14308V9.75H9.74987V8.14308C9.75331 7.83855 9.68827 7.53683 9.55912 7.25813C9.42996 6.97942 9.23966 6.73014 9.00096 6.52699C9.23722 6.32588 9.42608 6.07957 9.55514 5.80421C9.68421 5.52885 9.75057 5.23067 9.74987 4.92923Z"
                fill="white"
            />
        </svg>
    );
};

const BackArchiveIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 4.89453H1V15.021H15V4.89453Z" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.366 0.978516H7.99518H3.63406L1.00002 4.89419H7.99518H15L12.366 0.978516Z" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.99518 12.7539L7.99518 8.54783" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.21639 9.96289L7.99516 7.37742L10.7739 9.96289" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const EditNoteIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 15H15.75" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M12.375 2.62419C12.6734 2.32582 13.078 2.1582 13.5 2.1582C13.7089 2.1582 13.9158 2.19936 14.1088 2.27931C14.3019 2.35926 14.4773 2.47646 14.625 2.62419C14.7727 2.77193 14.8899 2.94732 14.9699 3.14035C15.0498 3.33338 15.091 3.54026 15.091 3.74919C15.091 3.95813 15.0498 4.16501 14.9699 4.35804C14.8899 4.55107 14.7727 4.72646 14.625 4.87419L5.25 14.2492L2.25 14.9992L3 11.9992L12.375 2.62419Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const DownloadIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 11.25V2.25" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const ShareIcon = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6556 9.92737L14.2447 6.49214C14.6563 6.0982 14.6561 5.4405 14.2442 5.04683L10.6551 1.61627C10.0189 1.00819 8.96417 1.4591 8.96417 2.33916V3.57928C8.90146 3.58713 8.84658 3.59498 8.79171 3.59498C7.79619 3.66562 6.8477 3.90895 5.93841 4.32496C2.89698 5.72998 1 8.68917 1 12.0408V12.1925C1 12.8103 1.86741 12.9893 2.1523 12.4411C3.31243 10.2041 5.1467 8.79906 7.58455 8.19466C8.03135 8.07692 8.486 8.03768 8.96417 7.95918V9.20495C8.96417 10.0853 10.0196 10.5361 10.6556 9.92737Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const GiftIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4751_1196)">
                <path d="M15 9V16.5H3V9" stroke="#35AA49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.5 5.25H1.5V9H16.5V5.25Z" stroke="#35AA49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 16.5V5.25" stroke="#35AA49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M9 5.25H5.625C5.12772 5.25 4.65081 5.05246 4.29917 4.70083C3.94754 4.34919 3.75 3.87228 3.75 3.375C3.75 2.87772 3.94754 2.40081 4.29917 2.04917C4.65081 1.69754 5.12772 1.5 5.625 1.5C8.25 1.5 9 5.25 9 5.25Z"
                    stroke="#35AA49"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9 5.25H12.375C12.8723 5.25 13.3492 5.05246 13.7008 4.70083C14.0525 4.34919 14.25 3.87228 14.25 3.375C14.25 2.87772 14.0525 2.40081 13.7008 2.04917C13.3492 1.69754 12.8723 1.5 12.375 1.5C9.75 1.5 9 5.25 9 5.25Z"
                    stroke="#35AA49"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4751_1196">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
