import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LogoRanus from "../../_assets/image/LogoRanus.png";
import ModalLogout from "../components/ModalLogout";

const PartnerAccessDenied = (props) => {
    const history = useHistory();
    const [isShowLogout, setIsShowLogout] = useState(false);

    return (
        <PartnerAccessDeniedStyle>
            <ModalLogout
                isShow={isShowLogout}
                onClose={() => {
                    setIsShowLogout(false);
                }}
            />
            <div className="partner-home-header">
                <div className="partner-home-logo">
                    <img src={LogoRanus} />
                    <span>Partner</span>
                </div>
                <div
                    className="partner-home-logout"
                    onClick={() => {
                        setIsShowLogout(true);
                    }}
                >
                    <span>Đăng xuất</span>
                </div>
            </div>
            <div className="box-waiting">
                <h4>Không đủ quyền truy cập</h4>
                <span>Vui lòng Liên hệ với Ranus để kích hoạt tài khoản</span>
            </div>
        </PartnerAccessDeniedStyle>
    );
};

const PartnerAccessDeniedStyle = styled.div`
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    height: 100svh;
    font-family: "Lexend";
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    .partner-home-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 16px;
        z-index: 1;

        .partner-home-logo {
            img {
                width: auto;
                height: auto;
            }

            span {
                color: #b3282d;
                font-family: Lexend;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                display: block;
            }
        }

        .partner-home-logout {
            span {
                color: #b3282d;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
        }
    }

    .box-waiting {
        text-align: center;
        margin: 100px 0 0 0;

        h4 {
            color: #000;
            font-family: Lexend;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;
            margin-bottom: 12px;
        }
        span {
            color: #b3282d;
            text-align: center;
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.14px;
            display: block;
            width: 180px;
            margin: 0 auto;
        }
    }
`;
export default PartnerAccessDenied;
