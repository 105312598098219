import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import MainPage from "./app/HomePage/MainPage";
import Login from "./app/Login/Login";
import PartnerAccessDenied from "./app/PartnerHome/PartnerAccessDenied";
import PartnerWaiting from "./app/PartnerHome/PartnerWaiting";
import "./custom.css";
import AuthProvider from "./providers/AuthProvider";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AuthProvider>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/" component={MainPage} />
                    <Route exact path="/wait" component={PartnerWaiting} />
                    <Route exact path="/access-denied" component={PartnerAccessDenied} />
                </Switch>
            </AuthProvider>
        );
    }
}
