import * as actionTypes from "../actions/actionTypes";

const login = ({ facebookUserId, googleUserId, email }, state) => {
    const updatedState = {
        ...state,
        facebookUserId: facebookUserId,
        googleUserId: googleUserId,
        email: email,
    };
    return updatedState;
};

const logout = (state) => {
    const updatedState = {
        ...state,
        facebookUserId: null,
        googleUserId: null,
        email: null,
        data: null,
    };
    return updatedState;
};

const update = (state, action) => {
    const updatedState = {
        ...state,
        data: action.data,
    };
    return updatedState;
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.AUTH_LOGIN:
            return login(
                {
                    facebookUserId: action.facebookUserId,
                    googleUserId: action.googleUserId,
                    email: action.email,
                },
                state
            );
        case actionTypes.AUTH_LOGOUT:
            return logout(state);
        case actionTypes.AUTH_UPDATE_PERSONAL:
            return update(state, action);
        default:
            return state;
    }
};
