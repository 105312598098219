import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LogoRanus from "../../_assets/image/LogoRanus.png";
import PartnerServices from "../../based/services/PartnerServices";
import ModalChangeLandingPage from "../components/ModalChangeLandingPage";
import ModalChangePin from "../components/ModalChangePin";
import ModalComfirm from "../components/ModalComfirm";
import ModalCreateUpdateGift from "../components/ModalCreateUpdateGift";
import ModalLogout from "../components/ModalLogout";
import ModalShowGift from "../components/ModalShowGift";
import ToastMessage from "../components/ToastMessage";
import PartnerAffiliate from "./PartnerAffiliate";
import PartnerGift from "./PartnerGift";
import PartnerGiftCollab from "./PartnerGiftCollab";
import PartnerWinningList from "./PartnerWinningList";

const TAB_PARTNER = {
    AFFILIATE: 0,
    GIFT: 1,
    WINNIN_LIST: 2,
    COLLAB: 3,
};
const modelLP = {
    id: 0,
    brandName: "",
    redirectUrl: "",
    thumbnail: "",
    name: "",
};
const PartnerHome = () => {
    const history = useHistory();
    const [tab, setTab] = useState(TAB_PARTNER.AFFILIATE);
    const [isShowLogout, setIsShowLogout] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState({
        isShow: false,
        onAccept: () => {},
    });
    const [isShowModalChangePin, setIsShowModalChangePin] = useState({
        isShow: false,
        pin: 0,
        campaignId: 0,
    });
    const [isShowModalChangeLandingPage, setIsShowModalChangeLandingPage] = useState(false);
    const [isShowModalGift, setIsShowModalGift] = useState({
        isShow: false,
        giftItem: {
            name: "",
            id: 0,
            marketingCampaignId: 0,
            giftType: 1,
        },
    });
    const [isShowModalGiftShow, setIsShowModalGiftShow] = useState({
        isShow: false,
        giftItem: {
            name: "",
            id: 0,
            marketingCampaignId: 0,
            giftType: 1,
        },
    });
    const [showToast, setShowToast] = useState({
        isShow: false,
        message: "",
        type: "",
    });
    const [landingPage, setLandingPage] = useState([]);
    const [landingActive, setLandingActive] = useState({ ...modelLP });
    const [imageHidden, setImageHidden] = useState("");
    const [campaign, setCampaign] = useState({});
    const [campaignCollab, setCampaignCollab] = useState([]);
    const [campaignCollabActive, setCampaignCollabActive] = useState([]);

    useEffect(() => {
        getPartnerLandingPage();
        getCampaignCollab();
    }, []);

    useEffect(() => {
        if (landingActive.id > 0) {
            let dt = landingPage.filter((x) => x.id != landingActive.id);
            if (dt.length > 0) {
                setImageHidden(dt[0].thumbnail);
            }
        }
        getCampaign();
    }, [landingActive]);

    const getCampaign = async () => {
        let [err, data] = await PartnerServices.GetPartnerCampaign(landingActive.id);
        if (!err && data) {
            setCampaign(data);
        } else {
            setCampaign({});
        }
    };

    const getCampaignCollab = async () => {
        let [err, data] = await PartnerServices.GetCampaignCollab();
        if (!err && data) {
            setCampaignCollab(data);
        } else {
            setCampaignCollab({});
        }
    };

    const getLPById = async () => {
        let [err, data] = await PartnerServices.GetPartnerLandingPageById(landingActive.id);
        if (!err && data) {
            setLandingActive(data);
        } else {
        }
    };
    const getPartnerLandingPage = async () => {
        let [err, data] = await PartnerServices.GetPartnerLandingPage();
        if (!err && data) {
            setLandingPage(data);
            if (data.length > 0) {
                setLandingActive(data[0]);
            } else {
                setLandingActive({ ...modelLP });
            }
        } else {
            setLandingPage([]);
        }
    };

    return (
        <PartnerHomeStyle>
            <ModalLogout
                isShow={isShowLogout}
                onClose={() => {
                    setIsShowLogout(false);
                }}
            />
            <ToastMessage
                show={showToast.isShow}
                message={showToast.message}
                type={showToast.type}
                onClose={() => {
                    setShowToast({
                        isShow: false,
                        message: "",
                        type: "",
                    });
                }}
            />
            <ModalComfirm
                isShow={isShowConfirm.isShow}
                onAccept={isShowConfirm.onAccept}
                onClose={() => {
                    setIsShowConfirm({
                        isShow: false,
                        onAccept: () => {},
                    });
                }}
            />
            <ModalChangePin
                isShow={isShowModalChangePin.isShow}
                onSave={() => {
                    getLPById();
                }}
                PIN={isShowModalChangePin.pin}
                setShowToast={setShowToast}
                campaignId={isShowModalChangePin.campaignId}
                onClose={() => {
                    setIsShowModalChangePin({
                        isShow: false,
                        pin: 0,
                        campaignId: 0,
                    });
                }}
            />

            <ModalChangeLandingPage
                isShow={isShowModalChangeLandingPage}
                setShowToast={setShowToast}
                landingPage={landingPage}
                setLandingActive={setLandingActive}
                landingActive={landingActive}
                setCampaignCollabActive={setCampaignCollabActive}
                campaignCollabActive={campaignCollabActive}
                campaignCollab={campaignCollab}
                setTab={setTab}
                tab={tab}
                onClose={() => {
                    setIsShowModalChangeLandingPage(false);
                }}
            />
            <ModalCreateUpdateGift
                isShow={isShowModalGift.isShow}
                setIsShowConfirm={setIsShowConfirm}
                giftItem={isShowModalGift.giftItem}
                setShowToast={setShowToast}
                onClose={() => {
                    setIsShowModalGift({
                        isShow: false,
                        giftItem: {
                            name: "",
                            id: 0,
                            marketingCampaignId: 0,
                            giftType: 1,
                        },
                    });
                }}
            />
            <ModalShowGift
                isShow={isShowModalGiftShow.isShow}
                setIsShowConfirm={setIsShowConfirm}
                giftItem={isShowModalGiftShow.giftItem}
                setShowToast={setShowToast}
                onClose={() => {
                    setIsShowModalGiftShow({
                        isShow: false,
                        giftItem: {
                            name: "",
                            id: 0,
                            marketingCampaignId: 0,
                            giftType: 1,
                        },
                    });
                }}
            />
            <div className="partner-home-header">
                <div className="partner-home-logo">
                    <img src={LogoRanus} />
                    <span>Partner</span>
                </div>
                <div
                    className="partner-home-logout"
                    onClick={() => {
                        setIsShowLogout(true);
                    }}
                >
                    <span>Đăng xuất</span>
                </div>
            </div>
            {tab === TAB_PARTNER.AFFILIATE && <PartnerAffiliate landingPage={landingActive} setShowToast={setShowToast} />}
            {tab === TAB_PARTNER.GIFT && (
                <PartnerGift
                    setIsShowModalChangePin={setIsShowModalChangePin}
                    setIsShowModalGift={setIsShowModalGift}
                    setIsShowModalGiftShow={setIsShowModalGiftShow}
                    isShowModalGift={isShowModalGift}
                    landingPage={landingActive}
                    campaign={campaign}
                />
            )}
            {tab === TAB_PARTNER.WINNIN_LIST && <PartnerWinningList campaignId={campaign.id} />}
            {tab === TAB_PARTNER.COLLAB && <PartnerGiftCollab setIsShowModalGift={setIsShowModalGift} setIsShowModalGiftShow={setIsShowModalGiftShow} isShowModalGift={isShowModalGift} campaign={campaignCollabActive} />}
            <div className="partner-home-footer">
                <div className="menu-bar">
                    <div
                        className={`item ${tab == TAB_PARTNER.AFFILIATE ? "active" : ""}`}
                        onClick={() => {
                            setTab(TAB_PARTNER.AFFILIATE);
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.4554 16.9164C19.8074 15.9835 20 14.9724 20 13.9164C20 10.278 17.714 7.17356 14.5 5.96094M15.5 21.4183C14.3079 22.0553 12.9461 22.4164 11.5 22.4164C10.0539 22.4164 8.69215 22.0553 7.5 21.4183M3.54455 16.9164C3.19259 15.9835 3 14.9724 3 13.9164C3 10.278 5.28597 7.17356 8.5 5.96094"
                                stroke="#A0A0A0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path d="M15.3978 17.918C14.6195 16.4237 13.1651 15.418 11.5002 15.418C9.83518 15.418 8.38083 16.4237 7.60254 17.918" stroke="#A0A0A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path
                                d="M11.5 15.418C12.6046 15.418 13.5 14.5225 13.5 13.418C13.5 12.3134 12.6046 11.418 11.5 11.418C10.3954 11.418 9.5 12.3134 9.5 13.418C9.5 14.5225 10.3954 15.418 11.5 15.418Z"
                                stroke="#A0A0A0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M20.688 20.8516C21.4578 19.3897 20.8967 17.5805 19.4348 16.8107C17.9729 16.0409 16.1637 16.6019 15.3939 18.0639C14.6241 19.5258 15.1851 21.335 16.6471 22.1048C18.109 22.8746 19.9182 22.3135 20.688 20.8516Z"
                                stroke="#A0A0A0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.68799 20.8516C8.4578 19.3897 7.89673 17.5805 6.4348 16.8107C4.97286 16.0409 3.16368 16.6019 2.39387 18.0639C1.62406 19.5258 2.18513 21.335 3.64706 22.1048C5.109 22.8746 6.91818 22.3135 7.68799 20.8516Z"
                                stroke="#A0A0A0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M14.4921 5.4615C14.4921 3.80928 13.1527 2.46988 11.5005 2.46988C9.84826 2.46988 8.50887 3.80928 8.50887 5.4615C8.50887 7.11373 9.84826 8.45312 11.5005 8.45312C13.1527 8.45312 14.4921 7.11373 14.4921 5.4615Z"
                                stroke="#A0A0A0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span>Tiếp thị liên kết</span>
                    </div>
                    <div
                        className={`item ${tab == TAB_PARTNER.GIFT ? "active" : ""}`}
                        onClick={() => {
                            setTab(TAB_PARTNER.GIFT);
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 12V22H4V12" stroke="#A0A0A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M22 7H2V12H22V7Z" stroke="#A0A0A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 22V7" stroke="#A0A0A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path
                                d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z"
                                stroke="#A0A0A0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z"
                                stroke="#A0A0A0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span>Cơ cấu giải thưởng</span>
                    </div>
                    <div
                        className={`item ${tab == TAB_PARTNER.WINNIN_LIST ? "active" : ""}`}
                        onClick={() => {
                            setTab(TAB_PARTNER.WINNIN_LIST);
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8"
                                stroke="#A0A0A0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z" stroke="#A0A0A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <span>Danh sách trúng</span>
                    </div>
                    <div
                        className={`item`}
                        onClick={() => {
                            setIsShowModalChangeLandingPage(true);
                        }}
                    >
                        {landingActive && landingActive.id ? (
                            <div className="box-avatar">
                                <div className="box-image">
                                    <img src={landingActive.thumbnail} />
                                    {imageHidden && <img className="image-hidden" src={imageHidden} />}
                                </div>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 7L7 1L13 7" stroke="#444444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        ) : (
                            <span>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 7L7 1L13 7" stroke="#444444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        )}
                    </div>
                </div>
                <div
                    className="partner-home-logout"
                    onClick={() => {
                        setIsShowLogout(true);
                    }}
                >
                    <span>Đăng xuất</span>
                </div>
            </div>
        </PartnerHomeStyle>
    );
};
const PartnerHomeStyle = styled.div`
    width: 100%;
    box-sizing: border-box;
    background: #f3f3f3;

    @media (min-width: 768px) {
        background: #fff !important;

        .partner-home-header {
            background: #fff !important;
            max-width: 800px;

            .partner-home-logout {
                display: none;
            }
        }

        .partner-home-footer {
            width: 300px !important;
            top: 78px;
            height: auto !important;
            display: block !important;

            .menu-bar {
                flex-wrap: wrap;
                .item {
                    width: 100% !important;
                    min-width: 100% !important;
                    max-width: 100% !important;
                    height: 70px !important;

                    span {
                        font-weight: 600 !important;
                        font-size: 14px !important;
                        margin-top: 5px !important;
                    }

                    :hover {
                        background-color: rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                    }
                }
            }

            .partner-home-logout {
                width: 100%;
                display: block !important;
                color: #b3282d;
                font-family: Lexend;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 125% */
                margin-top: 40px;
                text-align: center;
                cursor: pointer;
            }
        }
        .partner-home-body {
            position: fixed;
            top: 78px;
            padding-top: 12px !important;
            left: 300px;
            width: calc(100% - 300px);
            height: 100%;
            overflow-y: auto;

            ::-webkit-scrollbar {
                display: none;
            }

            .body-container {
                max-width: 500px;
                padding-bottom: 60px;
            }
        }
    }

    .partner-home-body {
        padding: 78px 12px;
        background: #f3f3f3;
        min-height: 100svh;

        .body-container {
            width: 100%;
        }
    }

    .partner-home-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 16px;
        z-index: 1;
        background: #f3f3f3;

        .partner-home-logo {
            img {
                width: auto;
                height: auto;
            }

            span {
                color: #b3282d;
                font-family: Lexend;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                display: block;
            }
        }

        .partner-home-logout {
            span {
                color: #b3282d;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
        }
    }

    .partner-home-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-radius: 16px 16px 0px 0px;
        background: #fff;
        padding: 0 12px;
        display: flex;
        align-items: center;

        .partner-home-logout {
            display: none;
        }

        .menu-bar {
            display: flex;
            align-items: center;
            width: 100%;

            .item {
                width: 25%;
                min-width: 25%;
                max-width: 25%;
                height: 55px;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span {
                    color: #a0a0a0;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    display: block;
                    margin-top: 3px;
                }

                &.active {
                    background: #b3282d;

                    span {
                        color: #fff;
                    }

                    svg {
                        filter: brightness(0) invert(100%);
                    }
                }

                .box-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .box-image {
                        position: relative;
                        margin-right: 20px;
                        img {
                            width: 36px;
                            height: 36px;
                            object-fit: cover;
                            border-radius: 50%;
                        }

                        .image-hidden {
                            position: absolute;
                            bottom: 0;
                            right: -8px;
                            width: 16px;
                            height: 16px;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
`;

export const BasedModalStyle = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    place-items: center;
    transition: all 0.3s ease-out;

    .search {
        margin-top: 0px;
    }

    &.modal_center {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;

        &.show_modal {
            visibility: visible;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.7);
            pointer-events: auto;

            .partner_modal {
                transform: scale(1);
                opacity: 1;
                pointer-events: auto;
            }
        }

        .partner_modal {
            transform: scale(0);
            opacity: 0;
            pointer-events: none;
            box-shadow: 0px 0px 31px 7px rgba(0, 0, 0, 0.4);
        }
    }

    &.relative_modal {
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;

        &.show_modal {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;

            .partner_modal {
                transform: scale(1);
                opacity: 1;
                pointer-events: auto;
                box-shadow: 0px 0px 31px 7px rgba(0, 0, 0, 0.4);
            }
        }

        .partner_modal {
            position: absolute;
            transform: scale(0);
            opacity: 0;
            pointer-events: none;
        }
    }

    &.bottom_modal {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.7) !important;

        &.show_modal {
            visibility: visible;
            opacity: 1;
            background-color: transparent;
            pointer-events: auto;

            .partner_modal {
                transform: translateY(0);
                opacity: 1;
                pointer-events: auto;
            }
        }

        .partner_modal {
            transform: translateY(100%);
            opacity: 0;
            pointer-events: none;
        }
    }

    .partner_modal {
        padding: 16px;
        border-radius: 10px;
        background-color: #fff;
        transition: all 0.3s ease-out;

        .partner_modal_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .partner_modal_header-title {
                color: #505050;
                font-family: Lexend;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                span {
                    line-height: 1;
                }
            }
            .partner_modal_close {
                cursor: pointer;
                line-height: 1;
            }
        }

        .partner_modal_body {
            overflow-y: auto;
            height: -webkit-fill-available;
            scroll-behavior: smooth;
            will-change: transform;
            transition: all 0.3s ease-out;

            ::-webkit-scrollbar {
                display: none;
            }

            .partner_modal_body-desc {
                margin-top: 4px;
                color: #a0a0a0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
            }
        }
    }
`;
export default PartnerHome;
