import React, { useContext } from "react";
import cookie from "react-cookies";
import styled from "styled-components";
import { AuthContext } from "../../contexts/AuthContext";
import { BasedModalStyle } from "../PartnerHome/PartnerHome";

const ModalLogout = ({ isShow, onClose, colorCode }) => {
    const authContext = useContext(AuthContext);

    const handleSignOut = () => {
        cookie.remove("token", { path: "/" });
        authContext.logout();
        window.location.replace("/login");
    };

    return (
        <BasedModalStyle
            onClick={() => {
                onClose();
            }}
            className={`modal_center ${isShow ? "show_modal" : ""}`}
        >
            <ModalLogoutStyle
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className={`partner_modal `}
            >
                <div className="partner_modal_header">
                    <div className="partner_modal_header-title">Xác nhận</div>
                    <span
                        className="partner_modal_close"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L14 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M14 1L0.999999 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </span>
                </div>
                <div className="partner_modal_body">
                    <div className="partner_modal_body-comfirm-desc">Bạn có chắc chắn muốn thoát tài khoản này không? </div>
                    <div className="partner_modal_body-comfirm-btn">
                        <button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Đóng
                        </button>
                        <button
                            className="accept"
                            onClick={() => {
                                handleSignOut();
                            }}
                        >
                            Đăng xuất
                        </button>
                    </div>
                </div>
            </ModalLogoutStyle>
        </BasedModalStyle>
    );
};

const ModalLogoutStyle = styled.div`
    width: 300px;
    height: 150px;
    font-family: "Lexend";

    .partner_modal_body-comfirm-desc {
        color: #a0a0a0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.14px;
        margin-top: 5px;
    }
    .partner_modal_body-comfirm-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            margin: 20px auto 0;
            border: none;
            outline: none;
            border-radius: 10px;
            width: calc(50% - 10px);
            height: 38px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            line-height: 38px;
            text-align: center;
            border: 2px solid #f4f4f4;
            color: #b3282d;
            background-color: #fff;
            line-height: 1;

            &.accept {
                background: #b3282d;
                color: #fff;
            }
        }
    }
`;

export default ModalLogout;
