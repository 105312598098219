import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BasedModalStyle } from "../PartnerHome/PartnerHome";
import Commom from "../../based/Common";
import PartnerTooltip from "./PartnerTooltip";
import ModalChangeSpecialDay from "./ModalChangeSpecialDay";
import PartnerServices from "../../based/services/PartnerServices";
import { Notify } from "../../based/Notify";
import { NOTIFY } from "../../based/Constants";

const TAB_CONFIG = {
    OPTION: 0,
    DATE: 1,
};
const initGift = {
    name: "",
    id: 0,
    marketingCampaignId: 0,
    giftType: 1,
};

const initConfigGift = {
    startTime: "00:00",
    endTime: "00:00",
    rateWin: 0,
    quantity: 0,
    minuteExpiry: 30,
    timeStartUsing: null,
    timeEndUsing: null,
    id: 0,
    giftId: 0,
};
const initConfigSpecialDayGift = {
    startTime: "00:00",
    endTime: "00:00",
    rateWin: 0,
    quantity: 0,
    minuteExpiry: 30,
    timeStartUsing: null,
    timeEndUsing: null,
    id: 0,
    specialDayId: 0,
};
const initSpecialDay = {
    id: 0,
    giftId: 0,
    name: "",
    configTimes: [],
    isUpdate: true,
};

const EFFECT_TIME = [
    { value: 1, label: "1 ngày" },
    { value: 3, label: "3 ngày" },
    { value: 7, label: "7 ngày" },
    { value: -1, label: "Cả chiến dịch" },
];

const ModalCreateUpdateGift = ({ isShow, onClose, colorCode, giftItem, setIsShowConfirm, setShowToast }) => {
    const isDesktop = Commom.isDesktop();
    const [tab, setTab] = useState(TAB_CONFIG.OPTION);
    const [isModal, setIsModal] = useState({
        isShow: false,
        date: null,
        specialApply: {},
    });
    const [gift, setGift] = useState({ ...initGift });
    const [configDefault, setConfigDefault] = useState([]);
    const [specialDay, setSpecialDay] = useState([]);
    const [giftId, setGiftId] = useState([]);

    useEffect(() => {
        setTab(TAB_CONFIG.OPTION);
    }, [isShow]);

    useEffect(() => {
        if (giftItem && giftItem.id > 0) {
            getGiftConfigByGiftId(giftItem.id);
            getSpecialDayByGiftId(giftItem.id);
        } else {
            setConfigDefault([]);
            setSpecialDay([]);
        }
        setGift({ ...giftItem });
    }, [giftItem]);

    const getGiftConfigByGiftId = async (giftId) => {
        let [err, data] = await PartnerServices.GetGiftConfigByGiftId(giftId);
        if (!err && data) {
            setConfigDefault(data);
        } else {
            setConfigDefault([]);
        }
    };

    const getSpecialDayByGiftId = async (giftId) => {
        let [err, data] = await PartnerServices.GetSpecialDayByGiftId(giftId);
        if (!err && data) {
            setSpecialDay(data);
        } else {
            setSpecialDay([]);
        }
    };

    const createUpdateGift = async () => {
        if (gift.name.length > 0) {
            let [err, data] = await PartnerServices.CreateUpdateGift(gift);
            if (!err && data) {
                setGift(data);
            } else {
                setShowToast({
                    isShow: true,
                    message: "Lưu thất bại",
                    type: "ERROR",
                });
            }
        }
    };

    //default config
    const handleAddConfig = async () => {
        if (gift.id > 0) {
            let [err, data] = await PartnerServices.CreateUpdateMarketingGiftConfig({ ...initConfigGift, giftId: gift.id });
            if (!err && data) {
                getGiftConfigByGiftId(gift.id);
            } else {
                setShowToast({
                    isShow: true,
                    message: "Thêm thất bại",
                    type: "ERROR",
                });
            }
        } else {
            setShowToast({
                isShow: true,
                message: "Cập nhật tên quà",
                type: "WARNING",
            });
        }
    };
    const handleAddConfigLast = async (startTime, endTime) => {
        let [err, data] = await PartnerServices.CreateUpdateMarketingGiftConfig({ ...initConfigGift, startTime, endTime, giftId: gift.id });
        if (!err && data) {
            getGiftConfigByGiftId(gift.id);
        } else {
            setShowToast({
                isShow: true,
                message: "Thêm thất bại",
                type: "ERROR",
            });
        }
    };
    const updateItemConfig = async (item) => {
        if (
            item.startTime.split(":")[0] <= 23 &&
            item.startTime.split(":")[0] >= 0 &&
            item.startTime.split(":")[1] <= 59 &&
            item.startTime.split(":")[1] >= 0 &&
            item.endTime.split(":")[0] <= 23 &&
            item.endTime.split(":")[0] >= 0 &&
            item.endTime.split(":")[1] <= 59 &&
            item.endTime.split(":")[1] >= 0
        ) {
            let [err, data] = await PartnerServices.CreateUpdateMarketingGiftConfig(item);
            if (!err && data) {
            } else {
                setShowToast({
                    isShow: true,
                    message: "Thêm thất bại",
                    type: "ERROR",
                });
            }
        } else {
            setShowToast({
                isShow: true,
                message: "Giờ từ 0 đến 23 ,phút từ 0 đến 59",
                type: "WARNING",
            });
        }
    };

    const handleDeleteConfig = async (id) => {
        setIsShowConfirm({
            isShow: true,
            onAccept: async () => {
                let [err, dt] = await PartnerServices.DeleteGiftConfigById(id);
                if (!err && dt != 0) {
                    if (dt == 1) getGiftConfigByGiftId(gift.id);
                    if (dt == -7)
                        setShowToast({
                            isShow: true,
                            message: "Đang diễn ra chiến dịch , không thể xóa",
                            type: "WARNING",
                        });
                } else {
                    setShowToast({
                        isShow: true,
                        message: "Xóa thất bại",
                        type: "ERROR",
                    });
                }
            },
        });
    };

    const deleteGift = async () => {
        setIsShowConfirm({
            isShow: true,
            onAccept: async () => {
                let [err, dt] = await PartnerServices.DeleteGift(gift.id);
                if (!err && dt != 0) {
                    if (dt == 1) onClose();
                    if (dt == -7)
                        setShowToast({
                            isShow: true,
                            message: "Đang diễn ra chiến dịch , không thể xóa",
                            type: "WARNING",
                        });
                } else {
                    setShowToast({
                        isShow: true,
                        message: "Xóa thất bại",
                        type: "ERROR",
                    });
                }
            },
        });
    };

    //special day
    const handleAddSpecialDay = async () => {
        if (gift.id > 0) {
            setSpecialDay([{ ...initSpecialDay, giftId: gift.id }, ...specialDay]);
        } else {
            setShowToast({
                isShow: true,
                message: "Cập nhật tên quà",
                type: "WARNING",
            });
        }
    };

    const handleCreateUpdateSpecialDay = async (item, i) => {
        let data = [...specialDay];
        if (data[i].isUpdate) {
            let [err, dt] = await PartnerServices.CreateUpdateSpecialDay(item);
            if (!err && dt) {
                getSpecialDayByGiftId(gift.id);
            } else {
                setShowToast({
                    isShow: true,
                    message: "Lưu thất bại",
                    type: "ERROR",
                });
            }
        }
        data[i].isUpdate = !data[i].isUpdate;
        setSpecialDay(data);
    };

    const handleAddConfigSpecialDay = async (start, end, specialDayId) => {
        let [err, data] = await PartnerServices.CreateUpdateMarketingGiftSpecialDayConfig({ ...initConfigSpecialDayGift, startTime: start, endTime: end, specialDayId: specialDayId });
        if (!err && data) {
            getSpecialDayByGiftId(gift.id);
        } else {
            setShowToast({
                isShow: true,
                message: "Thêm thất bại",
                type: "ERROR",
            });
        }
    };

    const handleAddConfigSpecialDayFirst = async (specialDayId) => {
        let [err, data] = await PartnerServices.CreateUpdateMarketingGiftSpecialDayConfig({ ...initConfigSpecialDayGift, specialDayId: specialDayId });
        if (!err && data) {
            getSpecialDayByGiftId(gift.id);
        } else {
            setShowToast({
                isShow: true,
                message: "Thêm thất bại",
                type: "ERROR",
            });
        }
    };

    const handleDeleteSpecialDay = async (id, idx) => {
        if (id > 0) {
            setIsShowConfirm({
                isShow: true,
                onAccept: async () => {
                    let [err, dt] = await PartnerServices.DeleteSpecialDayById(id);
                    if (!err && dt != 0) {
                        if (dt == 1) getSpecialDayByGiftId(gift.id);
                        if (dt == -7)
                            setShowToast({
                                isShow: true,
                                message: "Đang diễn ra chiến dịch , không thể xóa",
                                type: "WARNING",
                            });
                    } else {
                        setShowToast({
                            isShow: true,
                            message: "Xóa thất bại",
                            type: "ERROR",
                        });
                    }
                },
            });
        } else {
            let data = specialDay.filter((item, i) => i !== idx);
            setSpecialDay(data);
        }
    };

    const handleDeleteSpecialDayConfig = async (id) => {
        setIsShowConfirm({
            isShow: true,
            onAccept: async () => {
                let [err, dt] = await PartnerServices.DeleteSpecialDayGifyConfigById(id);
                if (!err && dt != 0) {
                    if (dt == 1) getSpecialDayByGiftId(gift.id);
                    if (dt == -7)
                        setShowToast({
                            isShow: true,
                            message: "Đang diễn ra chiến dịch , không thể xóa",
                            type: "WARNING",
                        });
                } else {
                    setShowToast({
                        isShow: true,
                        message: "Xóa thất bại",
                        type: "ERROR",
                    });
                }
            },
        });
    };

    const updateItemSpecialDayConfig = async (item) => {
        if (
            item.startTime.split(":")[0] <= 23 &&
            item.startTime.split(":")[0] >= 0 &&
            item.startTime.split(":")[1] <= 59 &&
            item.startTime.split(":")[1] >= 0 &&
            item.endTime.split(":")[0] <= 23 &&
            item.endTime.split(":")[0] >= 0 &&
            item.endTime.split(":")[1] <= 59 &&
            item.endTime.split(":")[1] >= 0
        ) {
            let [err, data] = await PartnerServices.CreateUpdateMarketingGiftSpecialDayConfig(item);
            if (!err && data) {
            } else {
                setShowToast({
                    isShow: true,
                    message: "Thêm thất bại",
                    type: "ERROR",
                });
            }
        } else {
            setShowToast({
                isShow: true,
                message: "Giờ từ 0 đến 23 ,phút từ 0 đến 59",
                type: "WARNING",
            });
        }
    };

    return (
        <BasedModalStyle
            onClick={() => {
                onClose();
            }}
            className={`${isDesktop ? "modal_center" : "relative_modal bottom_modal"} ${isShow ? "show_modal" : ""}`}
        >
            <ModalChangeSpecialDay
                giftId={giftId}
                dataApply={isModal.specialApply}
                date={isModal.date}
                setShowToast={setShowToast}
                onSave={isModal.onSave}
                isShow={isModal.isShow}
                onClose={() => {
                    setIsModal({
                        isShow: false,
                        date: null,
                        specialApply: {},
                    });
                }}
            />
            <ModalCreateUpdateGiftStyle
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className={`partner_modal `}
            >
                <div className="partner_modal_header">
                    <div className="partner_modal_header-title">
                        {tab === TAB_CONFIG.DATE && "Ngày áp dụng"}
                        {tab === TAB_CONFIG.OPTION && "Chi tiết giải thưởng"}
                    </div>
                    <span
                        className="partner_modal_close"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L14 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M14 1L0.999999 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </span>
                </div>
                {tab === TAB_CONFIG.OPTION && (
                    <div className="partner_modal_body">
                        <div className="partner_modal_body-box">
                            <div className="partner_modal_body-box-title">
                                <h5>Tên giải thưởng</h5>
                                <span>{gift.name.length}/40</span>
                            </div>
                            <input
                                type="text"
                                className="input-name-gift"
                                value={gift.name}
                                onChange={(e) => {
                                    setGift({ ...gift, name: e.target.value });
                                }}
                                onBlur={() => {
                                    createUpdateGift();
                                }}
                                maxLength={40}
                            />
                        </div>
                        {gift && gift.id > 0 && (
                            <div className="partner_modal_body-box-delete">
                                <span
                                    onClick={() => {
                                        deleteGift();
                                    }}
                                >
                                    Xóa quà
                                </span>
                            </div>
                        )}

                        {gift && gift.id > 0 && (
                            <div className="partner_modal_body-box-user-max">
                                <span>
                                    <label>Số lượng quà tối đa 1 user nhận</label>
                                    <input
                                        type="number"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        value={gift.maxReceive}
                                        placeholder="0"
                                        onChange={(e) => {
                                            setGift({ ...gift, maxReceive: e.target.value });
                                        }}
                                        onBlur={() => {
                                            createUpdateGift();
                                        }}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                    />
                                </span>
                                <span>
                                    <label>Trong khoảng thời gian</label>
                                    <select
                                        value={gift.effectiveTime}
                                        onChange={(e) => {
                                            setGift({ ...gift, effectiveTime: e.target.value });
                                        }}
                                        onBlur={() => {
                                            createUpdateGift();
                                        }}
                                    >
                                        {gift.effectiveTime == null && (
                                            <option value="" selected={true}>
                                                Chọn
                                            </option>
                                        )}
                                        {EFFECT_TIME.map((option, index) => {
                                            return (
                                                <option value={option.value} key={index}>
                                                    {option.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </span>
                            </div>
                        )}
                        <div className="partner_modal_body-config">
                            <h5>Ngày mặc định ({configDefault.length} khung giờ)</h5>

                            <div className="partner_modal_body-config-list">
                                {configDefault && configDefault.length > 0 ? (
                                    configDefault.map((item, idx) => {
                                        return (
                                            <GiftConfigItem
                                                idx={idx}
                                                item={item}
                                                configDefault={configDefault}
                                                setConfigDefault={setConfigDefault}
                                                handleDeleteConfig={() => {
                                                    handleDeleteConfig(item.id);
                                                }}
                                                updateItem={() => {
                                                    updateItemConfig(item);
                                                }}
                                                handleAddConfigLast={handleAddConfigLast}
                                                isLastItem={idx === configDefault.length - 1}
                                            />
                                        );
                                    })
                                ) : (
                                    <div
                                        className="add-config"
                                        onClick={() => {
                                            handleAddConfig();
                                        }}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="12" cy="12" r="12" fill="#FFD3D5" />
                                            <path d="M12 7.5V16.5" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                                            <path d="M16.5 12L7.5 12" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="partner_modal_body-config">
                            <h5>Ngày đặc biệt ({specialDay.length})</h5>
                            {specialDay.length > 0 &&
                                specialDay.map((itm, i) => {
                                    return (
                                        <div key={i} className="partner_modal_body-config-special">
                                            <div className="partner_modal_body-config-special-title">
                                                <div className="partner_modal_body-config-special-count">{specialDay.length - i}</div>
                                                <div className="partner_modal_body-config-special-name">
                                                    {itm.isUpdate ? (
                                                        <input
                                                            type="text"
                                                            value={itm.name}
                                                            placeholder="Nhập tên"
                                                            maxLength={40}
                                                            onChange={(e) => {
                                                                let data = [...specialDay];
                                                                data[i].name = e.target.value;
                                                                setSpecialDay(data);
                                                            }}
                                                        />
                                                    ) : (
                                                        itm.name
                                                    )}{" "}
                                                    ({itm.configTimes.length})
                                                </div>
                                                <div
                                                    className="partner_modal_body-config-special-icon"
                                                    onClick={() => {
                                                        handleCreateUpdateSpecialDay(itm, i);
                                                    }}
                                                >
                                                    {" "}
                                                    {itm.isUpdate ? (
                                                        "Lưu"
                                                    ) : (
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M16.1767 5.79974L7.02325 14.9648L1 17L3.03259 10.9691L12.2064 1.82435C12.4669 1.56303 12.7763 1.35571 13.117 1.21426C13.4576 1.07281 13.8228 1 14.1915 1C14.5603 1 14.9254 1.07281 15.2661 1.21426C15.6067 1.35571 15.9162 1.56303 16.1767 1.82435C16.4377 2.08522 16.6447 2.39505 16.786 2.73612C16.9273 3.07719 17 3.44281 17 3.81205C17 4.18129 16.9273 4.5469 16.786 4.88797C16.6447 5.22904 16.4377 5.53887 16.1767 5.79974V5.79974Z"
                                                                stroke="#B3282D"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path d="M10.4131 3.59375L14.1269 7.52139" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    )}
                                                </div>
                                                <div
                                                    className="partner_modal_body-config-special-icon"
                                                    onClick={() => {
                                                        handleDeleteSpecialDay(itm.id, i);
                                                    }}
                                                >
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2 4H3.33333H14" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path
                                                            d="M12.6668 3.9987V13.332C12.6668 13.6857 12.5264 14.0248 12.2763 14.2748C12.0263 14.5249 11.6871 14.6654 11.3335 14.6654H4.66683C4.31321 14.6654 3.97407 14.5249 3.72402 14.2748C3.47397 14.0248 3.3335 13.6857 3.3335 13.332V3.9987M5.3335 3.9987V2.66536C5.3335 2.31174 5.47397 1.9726 5.72402 1.72256C5.97407 1.47251 6.31321 1.33203 6.66683 1.33203H9.3335C9.68712 1.33203 10.0263 1.47251 10.2763 1.72256C10.5264 1.9726 10.6668 2.31174 10.6668 2.66536V3.9987"
                                                            stroke="#B3282D"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="partner_modal_body-config-list">
                                                {itm.configTimes.length > 0 ? (
                                                    itm.configTimes.map((item, idx) => {
                                                        return (
                                                            <ConfigTimeSpecialDayItem
                                                                idx={idx}
                                                                item={item}
                                                                indexSpec={i}
                                                                specialDay={specialDay}
                                                                setSpecialDay={setSpecialDay}
                                                                handleDeleteConfig={() => {
                                                                    handleDeleteSpecialDayConfig(item.id);
                                                                }}
                                                                updateItem={() => {
                                                                    updateItemSpecialDayConfig(item);
                                                                }}
                                                                handleAddConfigLast={handleAddConfigSpecialDay}
                                                                isLastItem={idx === itm.configTimes.length - 1}
                                                            />
                                                        );
                                                    })
                                                ) : (
                                                    <div
                                                        className="add-config"
                                                        onClick={() => {
                                                            handleAddConfigSpecialDayFirst(itm.id);
                                                        }}
                                                    >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="12" fill="#FFD3D5" />
                                                            <path d="M12 7.5V16.5" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                                                            <path d="M16.5 12L7.5 12" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
                {tab === TAB_CONFIG.OPTION && (
                    <div
                        className="modal-gift-config-add"
                        onClick={() => {
                            handleAddSpecialDay();
                        }}
                    >
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill="#B3282D" />
                            <path d="M24 15V33" stroke="white" strokeWidth="3" strokeLinecap="round" />
                            <path d="M33 24L15 24" stroke="white" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                    </div>
                )}
                {tab === TAB_CONFIG.DATE && <DateConfig setIsModal={setIsModal} isModal={isModal} specialDay={specialDay} giftId={giftId} />}
                <div className="box-footer-modal">
                    <div className="partner_modal_tab">
                        <div
                            className={`partner_modal_tab-item ${tab === TAB_CONFIG.OPTION ? "active" : ""}`}
                            onClick={() => {
                                setTab(TAB_CONFIG.OPTION);
                            }}
                        >
                            Giải thưởng
                            <div className="partner_modal_tab-item-line"></div>
                        </div>
                        <div
                            className={`partner_modal_tab-item ${tab === TAB_CONFIG.DATE ? "active" : ""}`}
                            onClick={() => {
                                if (gift.id > 0) {
                                    setGiftId(gift.id);
                                    setTab(TAB_CONFIG.DATE);
                                } else {
                                    setShowToast({
                                        isShow: true,
                                        message: "Cần nhật đầy đủ thông tin",
                                        type: "WARNING",
                                    });
                                }
                            }}
                        >
                            Ngày áp dụng
                            <div className="partner_modal_tab-item-line"></div>
                        </div>
                    </div>
                    <div className="partner_modal_body-comfirm-btn">
                        <button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Đóng
                        </button>
                        <button
                            className="accept"
                            onClick={() => {
                                if (tab === TAB_CONFIG.OPTION) {
                                    if (gift.id > 0) {
                                        setGiftId(gift.id);
                                        setTab(TAB_CONFIG.DATE);
                                    } else {
                                        setShowToast({
                                            isShow: true,
                                            message: "Cần nhật đầy đủ thông tin",
                                            type: "WARNING",
                                        });
                                    }
                                }
                                if (tab === TAB_CONFIG.DATE) {
                                    onClose();
                                }
                            }}
                        >
                            {tab === TAB_CONFIG.DATE ? "Xong" : "Tiếp theo"}
                        </button>
                    </div>
                </div>
            </ModalCreateUpdateGiftStyle>
        </BasedModalStyle>
    );
};

const DateConfig = ({ giftId, setIsModal, isModal, specialDay }) => {
    const [day, setDay] = useState([]);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [campaign, setCampaign] = useState({});

    useEffect(() => {
        if (giftId > 0) getMonthAndSpecialDayByGiftId(month, year);
    }, []);

    useEffect(() => {
        if (!isModal.isShow) {
            getMonthAndSpecialDayByGiftId(month, year);
        }
    }, [isModal]);

    useEffect(() => {
        getCampaign();
    }, []);

    const getCampaign = async () => {
        let [err, data] = await PartnerServices.GetPartnerCampaign();
        if (!err && data && data.length > 0) {
            setCampaign(data[0]);
        } else {
            setCampaign({});
        }
    };

    const getMonthAndSpecialDayByGiftId = async (month, year) => {
        let [err, dt] = await PartnerServices.GetMonthAndSpecialDayByGiftId(month, year, giftId);
        if (!err && dt) {
            setDay(dt);
        } else {
            setDay([]);
        }
    };

    const compareDate = async (day, month, year) => {
        let date = new Date(year + "-" + month + "-" + day);
        let startDate = new Date(campaign.startDate);
        let endDate = new Date(campaign.endDate);
        return startDate <= date && date <= endDate;
    };

    return (
        <div className="partner_modal_body">
            <div className="box-month-year">
                <div className="month-show">
                    <span
                        onClick={() => {
                            let m = month - 1 > 0 ? month - 1 : 12;
                            let y = month - 1 > 0 ? year : year - 1;
                            setMonth(m);
                            setYear(y);
                            getMonthAndSpecialDayByGiftId(m, y);
                        }}
                    >
                        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 2L2 10L9 18" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    <span>
                        {month}/{year}
                    </span>
                    <span
                        onClick={() => {
                            let m = month + 1 <= 12 ? month + 1 : 1;
                            let y = month + 1 <= 12 ? year : year + 1;
                            setMonth(m);
                            setYear(y);
                            getMonthAndSpecialDayByGiftId(m, y);
                        }}
                    >
                        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 18L9 10L2 2" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
            </div>
            <div className="table-date">
                <div className="row-date-header">
                    <div className="item-date-header">T2</div>
                    <div className="item-date-header">T3</div>
                    <div className="item-date-header">T4</div>
                    <div className="item-date-header">T5</div>
                    <div className="item-date-header">T6</div>
                    <div className="item-date-header">T7</div>
                    <div className="item-date-header">CN</div>
                </div>
                <div className="row-date-body">
                    {day &&
                        day.length > 0 &&
                        day.map((item, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`item-date ${item.month != month ? "faded" : item.isCampaign ? "active" : ""}`}
                                    onClick={async () => {
                                        if (item.isCampaign) {
                                            setIsModal({
                                                isShow: true,
                                                date: new Date(item.year, item.month - 1, item.day),
                                                specialApply: item.specialApply,
                                            });
                                        }
                                    }}
                                >
                                    <span>{item.day}</span>
                                    {item.specialApply && <strong>{specialDay.length - specialDay.findIndex((x) => x.id == item.specialApply.specialDayId)}</strong>}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

const ConfigTimeSpecialDayItem = ({ idx, item, indexSpec, handleAddConfigLast, handleDeleteConfig, specialDay, setSpecialDay, updateItem, isLastItem }) => {
    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);
    const input4 = useRef(null);

    return (
        <div key={idx} className="partner_modal_body-config-col">
            <div className="partner_modal_body-config-item">
                <div className="partner_modal_body-config-item-title">
                    <h6>Khung giờ {idx + 1}</h6>
                    <span
                        onClick={() => {
                            handleDeleteConfig();
                        }}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 4H3.33333H14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path
                                d="M12.6668 3.9987V13.332C12.6668 13.6857 12.5264 14.0248 12.2763 14.2748C12.0263 14.5249 11.6871 14.6654 11.3335 14.6654H4.66683C4.31321 14.6654 3.97407 14.5249 3.72402 14.2748C3.47397 14.0248 3.3335 13.6857 3.3335 13.332V3.9987M5.3335 3.9987V2.66536C5.3335 2.31174 5.47397 1.9726 5.72402 1.72256C5.97407 1.47251 6.31321 1.33203 6.66683 1.33203H9.3335C9.68712 1.33203 10.0263 1.47251 10.2763 1.72256C10.5264 1.9726 10.6668 2.31174 10.6668 2.66536V3.9987"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </div>
                <div className="partner_modal_body-config-item-container">
                    <div className="partner_modal_body-config-item-box time">
                        <div className="time-item">
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                ref={input1}
                                min={0}
                                max={23}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                placeholder="00"
                                value={item.startTime.split(":")[0]}
                                onChange={(e) => {
                                    let data = [...specialDay];
                                    data[indexSpec].configTimes[idx].startTime = e.target.value + ":" + item.startTime.split(":")[1];
                                    setSpecialDay(data);
                                    if (e.target.value > 9 && e.target.value < 24) {
                                        input2.current.focus();
                                    }
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                            <span>:</span>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                min={0}
                                max={59}
                                ref={input2}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                placeholder="00"
                                value={item.startTime.split(":")[1]}
                                onChange={(e) => {
                                    let data = [...specialDay];
                                    data[indexSpec].configTimes[idx].startTime = item.startTime.split(":")[0] + ":" + e.target.value;
                                    setSpecialDay(data);
                                    if (e.target.value > 9 && e.target.value < 60) {
                                        input3.current.focus();
                                    }
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                        </div>
                        <span>-</span>
                        <div className="time-item">
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                min={0}
                                max={23}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                ref={input3}
                                placeholder="00"
                                value={item.endTime.split(":")[0]}
                                onChange={(e) => {
                                    let data = [...specialDay];
                                    data[indexSpec].configTimes[idx].endTime = e.target.value + ":" + item.endTime.split(":")[1];
                                    setSpecialDay(data);
                                    if (e.target.value > 9 && e.target.value < 24) {
                                        input4.current.focus();
                                    }
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                            <span>:</span>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                min={0}
                                max={59}
                                ref={input4}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                placeholder="00"
                                value={item.endTime.split(":")[1]}
                                onChange={(e) => {
                                    let data = [...specialDay];
                                    data[indexSpec].configTimes[idx].endTime = item.endTime.split(":")[0] + ":" + e.target.value;
                                    setSpecialDay(data);
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box">
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Tỷ lệ trúng</span>
                            <div className="config-item-box-item-input">
                                <input
                                    type="number"
                                    step="0.1"
                                    inputmode="decimal"
                                    min={0}
                                    max={100}
                                    placeholder="00"
                                    onFocus={(e) => {
                                        e.target.select();
                                    }}
                                    value={item.rateWin}
                                    onChange={(e) => {
                                        if (/^-?\d+(\.\d)?$/.test(e.target.value)) {
                                            let data = [...specialDay];
                                            data[indexSpec].configTimes[idx].rateWin = e.target.value;
                                            setSpecialDay(data);
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.target.select();
                                    }}
                                    onBlur={updateItem}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Lượng quà</span>
                            <div className="config-item-box-item-input">
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    min={0}
                                    placeholder="00"
                                    value={item.quantity}
                                    onChange={(e) => {
                                        let data = [...specialDay];
                                        data[indexSpec].configTimes[idx].quantity = e.target.value;
                                        setSpecialDay(data);
                                    }}
                                    onClick={(e) => {
                                        e.target.select();
                                    }}
                                    onBlur={updateItem}
                                    onFocus={(e) => {
                                        e.target.select();
                                    }}
                                />
                                <span>cái</span>
                            </div>
                        </div>
                    </div>

                    <div className="partner_modal_body-config-item-box">
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Hạn sử dụng</span>
                        </div>
                        <div className="partner_modal_body-config-item-box-item">
                            <span>
                                <i></i>
                                <svg
                                    onClick={() => {
                                        if (item.minuteExpiry != null) {
                                            let data = [...specialDay];
                                            data[indexSpec].configTimes[idx].timeStartUsing = 0;
                                            data[indexSpec].configTimes[idx].timeEndUsing = 0;
                                            data[indexSpec].configTimes[idx].minuteExpiry = null;
                                            setSpecialDay(data);
                                        } else {
                                            let data = [...specialDay];
                                            data[indexSpec].configTimes[idx].timeStartUsing = null;
                                            data[indexSpec].configTimes[idx].timeEndUsing = null;
                                            data[indexSpec].configTimes[idx].minuteExpiry = 0;
                                            setSpecialDay(data);
                                        }
                                    }}
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_8430_225)">
                                        <path d="M11.1821 1L13.728 3.54592L11.1821 6.09183" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M2.27148 7.36184V6.08889C2.27148 5.41367 2.53971 4.7661 3.01717 4.28865C3.49462 3.8112 4.14218 3.54297 4.8174 3.54297H13.7281"
                                            stroke="#B3282D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M4.8174 15.002L2.27148 12.4561L4.8174 9.91016" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M13.7281 8.63672V9.90968C13.7281 10.5849 13.4599 11.2325 12.9824 11.7099C12.505 12.1874 11.8574 12.4556 11.1822 12.4556H2.27148"
                                            stroke="#B3282D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_8430_225">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box">
                        {item.minuteExpiry != null && (
                            <div className="partner_modal_body-config-item-box-item">
                                <span>Hạn dùng</span>
                                <div className="config-item-box-item-input">
                                    <input
                                        type="number"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        min={0}
                                        placeholder="0"
                                        value={item.minuteExpiry}
                                        onChange={(e) => {
                                            let data = [...specialDay];
                                            data[indexSpec].configTimes[idx].minuteExpiry = e.target.value;
                                            setSpecialDay(data);
                                        }}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                        onBlur={updateItem}
                                    />
                                    <span>Phút</span>
                                </div>
                            </div>
                        )}
                        {item.minuteExpiry == null && (
                            <div className="partner_modal_body-config-item-box-item">
                                <span>Dùng sau</span>
                                <div className="config-item-box-item-input">
                                    <input
                                        type="number"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        min={0}
                                        placeholder="0"
                                        value={item.timeStartUsing}
                                        onChange={(e) => {
                                            let data = [...specialDay];
                                            data[indexSpec].configTimes[idx].timeStartUsing = e.target.value;
                                            setSpecialDay(data);
                                        }}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onBlur={updateItem}
                                    />
                                    <span>Ngày</span>
                                </div>
                            </div>
                        )}
                        {item.minuteExpiry == null && (
                            <div className="partner_modal_body-config-item-box-item">
                                <span>
                                    <div>Hạn dùng</div>
                                    <div className="modal-button-detail">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_8397_2569)">
                                                <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6 8V6" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6 4H6.005" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_8397_2569">
                                                    <rect width="12" height="12" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <PartnerTooltip title={"Ví dụ: Quà tặng được dùng sau 1 ngày (ngày mai) và hạn dùng là 3 ngày kể từ ngày mai."} position={"top"} />
                                    </div>
                                </span>
                                <div className="config-item-box-item-input">
                                    <input
                                        type="number"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        min={0}
                                        placeholder="0"
                                        value={item.timeEndUsing}
                                        onChange={(e) => {
                                            let data = [...specialDay];
                                            data[indexSpec].configTimes[idx].timeEndUsing = e.target.value;
                                            setSpecialDay(data);
                                        }}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                        onBlur={updateItem}
                                    />
                                    <span>Ngày</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="add-first"
                    onClick={() => {
                        if (idx > 0) handleAddConfigLast(specialDay[indexSpec].configTimes[idx - 1].endTime, item.startTime, specialDay[indexSpec].id);
                        else handleAddConfigLast("00:00", item.startTime, specialDay[indexSpec].id);
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#FFD3D5" />
                        <path d="M12 7.5V16.5" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                        <path d="M16.5 12L7.5 12" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                    </svg>
                </div>
                {isLastItem && (
                    <div
                        className="add-last"
                        onClick={() => {
                            handleAddConfigLast(item.endTime, "23:59", specialDay[indexSpec].id);
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#FFD3D5" />
                            <path d="M12 7.5V16.5" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                            <path d="M16.5 12L7.5 12" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};

const GiftConfigItem = ({ idx, item, handleAddConfigLast, handleDeleteConfig, configDefault, setConfigDefault, updateItem, isLastItem }) => {
    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);
    const input4 = useRef(null);
    return (
        <div key={idx} className="partner_modal_body-config-col">
            <div className="partner_modal_body-config-item">
                <div className="partner_modal_body-config-item-title">
                    <h6>Khung giờ {idx + 1}</h6>
                    <span
                        onClick={() => {
                            handleDeleteConfig();
                        }}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 4H3.33333H14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path
                                d="M12.6668 3.9987V13.332C12.6668 13.6857 12.5264 14.0248 12.2763 14.2748C12.0263 14.5249 11.6871 14.6654 11.3335 14.6654H4.66683C4.31321 14.6654 3.97407 14.5249 3.72402 14.2748C3.47397 14.0248 3.3335 13.6857 3.3335 13.332V3.9987M5.3335 3.9987V2.66536C5.3335 2.31174 5.47397 1.9726 5.72402 1.72256C5.97407 1.47251 6.31321 1.33203 6.66683 1.33203H9.3335C9.68712 1.33203 10.0263 1.47251 10.2763 1.72256C10.5264 1.9726 10.6668 2.31174 10.6668 2.66536V3.9987"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </div>
                <div className="partner_modal_body-config-item-container">
                    <div className="partner_modal_body-config-item-box time">
                        <div className="time-item">
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                min={0}
                                max={23}
                                ref={input1}
                                placeholder="00"
                                value={item.startTime.split(":")[0]}
                                onChange={(e) => {
                                    let data = [...configDefault];
                                    data[idx].startTime = e.target.value + ":" + item.startTime.split(":")[1];
                                    setConfigDefault(data);
                                    if (e.target.value > 9 && e.target.value < 24) {
                                        input2.current.focus();
                                    }
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                            <span>:</span>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                min={0}
                                max={59}
                                ref={input2}
                                placeholder="00"
                                value={item.startTime.split(":")[1]}
                                onChange={(e) => {
                                    let data = [...configDefault];
                                    data[idx].startTime = item.startTime.split(":")[0] + ":" + e.target.value;
                                    setConfigDefault(data);
                                    if (e.target.value > 9 && e.target.value < 60) {
                                        input3.current.focus();
                                    }
                                }}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                        </div>
                        <span>-</span>
                        <div className="time-item">
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                min={0}
                                max={23}
                                ref={input3}
                                placeholder="00"
                                value={item.endTime.split(":")[0]}
                                onChange={(e) => {
                                    let data = [...configDefault];
                                    data[idx].endTime = e.target.value + ":" + item.endTime.split(":")[1];
                                    setConfigDefault(data);

                                    if (e.target.value > 9 && e.target.value < 24) {
                                        input4.current.focus();
                                    }
                                }}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                            <span>:</span>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                min={0}
                                max={59}
                                ref={input4}
                                placeholder="00"
                                value={item.endTime.split(":")[1]}
                                onChange={(e) => {
                                    let data = [...configDefault];
                                    data[idx].endTime = item.endTime.split(":")[0] + ":" + e.target.value;
                                    setConfigDefault(data);
                                }}
                                onClick={(e) => {
                                    e.target.select();
                                }}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                                onBlur={updateItem}
                            />
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box">
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Tỷ lệ trúng</span>
                            <div className="config-item-box-item-input">
                                <input
                                    type="number"
                                    inputmode="decimal"
                                    step="0.1"
                                    min={0}
                                    max={100}
                                    placeholder="00"
                                    value={item.rateWin}
                                    onChange={(e) => {
                                        if (/^-?\d+(\.\d)?$/.test(e.target.value)) {
                                            let data = [...configDefault];
                                            data[idx].rateWin = e.target.value;
                                            setConfigDefault(data);
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.target.select();
                                    }}
                                    onFocus={(e) => {
                                        e.target.select();
                                    }}
                                    onBlur={updateItem}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Lượng quà</span>
                            <div className="config-item-box-item-input">
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    min={0}
                                    placeholder="00"
                                    value={item.quantity}
                                    onChange={(e) => {
                                        let data = [...configDefault];
                                        data[idx].quantity = e.target.value;
                                        setConfigDefault(data);
                                    }}
                                    onClick={(e) => {
                                        e.target.select();
                                    }}
                                    onFocus={(e) => {
                                        e.target.select();
                                    }}
                                    onBlur={updateItem}
                                />
                                <span>cái</span>
                            </div>
                        </div>
                    </div>

                    <div className="partner_modal_body-config-item-box">
                        <div className="partner_modal_body-config-item-box-item">
                            <span>Hạn sử dụng</span>
                        </div>
                        <div className="partner_modal_body-config-item-box-item">
                            <span>
                                <i></i>
                                <svg
                                    onClick={() => {
                                        if (item.minuteExpiry != null) {
                                            let data = [...configDefault];
                                            data[idx].timeStartUsing = 0;
                                            data[idx].timeEndUsing = 0;
                                            data[idx].minuteExpiry = null;
                                            setConfigDefault(data);
                                        } else {
                                            let data = [...configDefault];
                                            data[idx].timeStartUsing = null;
                                            data[idx].timeEndUsing = null;
                                            data[idx].minuteExpiry = 0;
                                            setConfigDefault(data);
                                        }
                                    }}
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_8430_225)">
                                        <path d="M11.1821 1L13.728 3.54592L11.1821 6.09183" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M2.27148 7.36184V6.08889C2.27148 5.41367 2.53971 4.7661 3.01717 4.28865C3.49462 3.8112 4.14218 3.54297 4.8174 3.54297H13.7281"
                                            stroke="#B3282D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M4.8174 15.002L2.27148 12.4561L4.8174 9.91016" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M13.7281 8.63672V9.90968C13.7281 10.5849 13.4599 11.2325 12.9824 11.7099C12.505 12.1874 11.8574 12.4556 11.1822 12.4556H2.27148"
                                            stroke="#B3282D"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_8430_225">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="partner_modal_body-config-item-box">
                        {item.minuteExpiry != null && (
                            <div className="partner_modal_body-config-item-box-item">
                                <span>Hạn dùng</span>
                                <div className="config-item-box-item-input">
                                    <input
                                        type="number"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        min={0}
                                        placeholder="0"
                                        value={item.minuteExpiry}
                                        onChange={(e) => {
                                            let data = [...configDefault];
                                            data[idx].minuteExpiry = e.target.value;
                                            setConfigDefault(data);
                                        }}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                        onBlur={updateItem}
                                    />
                                    <span>Phút</span>
                                </div>
                            </div>
                        )}
                        {item.minuteExpiry == null && (
                            <div className="partner_modal_body-config-item-box-item">
                                <span>Dùng sau</span>
                                <div className="config-item-box-item-input">
                                    <input
                                        type="number"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        min={0}
                                        placeholder="0"
                                        value={item.timeStartUsing}
                                        onChange={(e) => {
                                            let data = [...configDefault];
                                            data[idx].timeStartUsing = e.target.value;
                                            setConfigDefault(data);
                                        }}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onBlur={updateItem}
                                    />
                                    <span>Ngày</span>
                                </div>
                            </div>
                        )}
                        {item.minuteExpiry == null && (
                            <div className="partner_modal_body-config-item-box-item">
                                <span>
                                    <div>Hạn dùng</div>
                                    <div className="modal-button-detail">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_8397_2569)">
                                                <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6 8V6" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6 4H6.005" stroke="#A0A0A0" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_8397_2569">
                                                    <rect width="12" height="12" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <PartnerTooltip title={"Ví dụ: Quà tặng được dùng sau 1 ngày (ngày mai) và hạn dùng là 3 ngày kể từ ngày mai."} position={"top"} />
                                    </div>
                                </span>
                                <div className="config-item-box-item-input">
                                    <input
                                        type="number"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        min={0}
                                        placeholder="0"
                                        value={item.timeEndUsing}
                                        onChange={(e) => {
                                            let data = [...configDefault];
                                            data[idx].timeEndUsing = e.target.value;
                                            setConfigDefault(data);
                                        }}
                                        onClick={(e) => {
                                            e.target.select();
                                        }}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                        onBlur={updateItem}
                                    />
                                    <span>Ngày</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="add-first"
                    onClick={() => {
                        if (idx > 0) handleAddConfigLast(configDefault[idx - 1].endTime, item.startTime);
                        else handleAddConfigLast("00:00", item.startTime);
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#FFD3D5" />
                        <path d="M12 7.5V16.5" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                        <path d="M16.5 12L7.5 12" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                    </svg>
                </div>
                {isLastItem && (
                    <div
                        className="add-last"
                        onClick={() => {
                            handleAddConfigLast(item.endTime, "23:59");
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#FFD3D5" />
                            <path d="M12 7.5V16.5" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                            <path d="M16.5 12L7.5 12" stroke="#B3282D" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};

const ModalCreateUpdateGiftStyle = styled.div`
    width: 100%;
    height: 90svh;
    bottom: 0;
    border-radius: 16px 16px 0 0 !important;
    position: relative;
    background-color: #f3f3f3 !important;
    font-family: "Lexend";

    @media (min-width: 768px) {
        max-width: 500px;
        border-radius: 16px !important;
        overflow: hidden;

        .partner_modal_body-config-list {
            ::-webkit-scrollbar {
                display: block !important;
                width: 5px;
                cursor: pointer;
            }
            ::-webkit-scrollbar-thumb {
                background: #b3282d;
                border-radius: 10px;
            }
            ::-webkit-scrollbar-track {
                background: rgb(179 40 45 / 10%);
                border-radius: 10px;
            }
        }
    }

    .modal-gift-config-add {
        position: absolute;
        left: 14px;
        bottom: 114px;
    }

    .partner_modal_body-box-user-max {
        display: flex;

        span {
            width: 50%;
            max-width: 50%;

            label {
                display: block;
                color: rgb(80, 80, 80);
                font-size: 12px;
                font-style: normal;
                line-height: normal;
                font-weight: 500;
            }

            input {
                width: 80%;
                height: 32px;
                border-radius: 10px;
                color: rgb(80, 80, 80);
                font-size: 14px;
                border: none;
                outline: none;
                padding: 0 10px;

                ::placeholder {
                    opacity: 0.2;
                }
            }

            select {
                width: 80%;
                height: 32px;
                border: none;
                border-radius: 10px;
                padding: 0 10px;
                font-size: 14px;
            }
        }
    }

    .partner_modal_body {
        padding-bottom: 180px;

        .box-month-year {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            .month-show {
                display: flex;
                width: 160px;
                margin-left: 10px;
                padding: 20px;
                align-items: center;
                justify-content: space-between;
                height: 38px;
                border-radius: 10px;
                background: #fff;

                span {
                    color: #444;
                    font-family: Lexend;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    cursor: pointer;
                    line-height: normal;
                }
            }
        }

        .table-date {
            margin-top: 15px;
            border-radius: 10px;
            overflow: hidden;

            .row-date-header {
                display: flex;

                .item-date-header {
                    width: 14.2857%;
                    max-width: 14.2857%;
                    min-width: 14.2857%;
                    height: 45px;
                    color: #fff;
                    text-align: center;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 45px;
                    background: #7a7a7a;
                }
            }

            .row-date-body {
                display: flex;
                flex-wrap: wrap;

                .item-date {
                    width: 14.2857%;
                    max-width: 14.2857%;
                    min-width: 14.2857%;
                    cursor: pointer;
                    height: 80px;
                    padding: 12px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #ededed;
                    background: #fff;

                    &.active {
                        border: 1px solid #ededed;
                        background: #c2f2d3;
                    }

                    &.faded {
                        span {
                            color: #d8d8d8;
                        }
                        strong {
                            display: none;
                        }
                    }
                    span {
                        color: #666;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    strong {
                        display: block;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: #b3282d;
                        color: #fff;
                        text-align: center;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }
        }

        .partner_modal_body-config {
            margin-top: 12px;

            .partner_modal_body-config-special {
                margin-top: 20px;

                .partner_modal_body-config-special-title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .partner_modal_body-config-special-count {
                        min-width: 15px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #b3282d;
                        color: #fff;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 15px;
                    }
                    .partner_modal_body-config-special-name {
                        margin: 0 10px;
                        color: #b3282d;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px;
                        height: 32px;

                        input {
                            border-radius: 5px;
                            background: #fff;
                            height: 32px;
                            border: none;
                            outline: none;
                            width: 250px;
                            padding: 0 5px;

                            ::placeholder {
                                color: #505050;
                                font-family: Lexend;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }
                    .partner_modal_body-config-special-icon {
                        color: #b3282d;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 32px;
                        margin-right: 10px;
                    }
                }
            }

            h5 {
                color: #505050;
                font-family: Lexend;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 12px;
            }

            .partner_modal_body-config-list {
                display: flex;
                overflow-y: auto;

                ::-webkit-scrollbar {
                    display: none;
                }

                .add-config {
                    margin: 50px 0;
                }

                .partner_modal_body-config-col {
                    width: 215px;
                    min-width: 215px;
                    max-width: 215px;
                    text-align: center;
                    display: flex;
                    position: relative;
                    justify-content: end;

                    .partner_modal_body-config-item {
                        width: 190px;
                        position: relative;

                        .add-first {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translate(-100%, -50%);
                            cursor: pointer;
                        }
                        .add-last {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(100%, -50%);
                            cursor: pointer;
                        }

                        .partner_modal_body-config-item-title {
                            height: 38px;
                            border-radius: 10px 10px 0px 0px;
                            background: #7a7a7a;
                            padding: 0 8px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            h6 {
                                color: #fff;
                                font-family: Lexend;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1;
                                margin: 0;
                            }
                        }

                        .partner_modal_body-config-item-container {
                            background: #fff;
                            border-radius: 0 0 10px 10px;
                            padding: 8px;

                            .partner_modal_body-config-item-box {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 10px;

                                input {
                                    ::placeholder {
                                        opacity: 0.2;
                                    }
                                }

                                &.time {
                                    border-radius: 10px;
                                    border: 1px solid #fff;
                                    background: #ededed;
                                    height: 38px;
                                    padding: 8px;

                                    span {
                                        color: #bdbdbd;
                                        font-size: 20px;
                                    }

                                    .time-item {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 80px;

                                        input {
                                            width: 35px;
                                            text-align: center;
                                            border: none;
                                            outline: none;
                                            background-color: #ededed;

                                            ::-webkit-inner-spin-button,
                                            ::-webkit-outer-spin-button {
                                                -webkit-appearance: none;
                                                margin: 0;
                                            }
                                        }
                                    }
                                }

                                .partner_modal_body-config-item-box-item {
                                    width: calc(50% - 5px);

                                    span {
                                        display: flex;
                                        justify-content: space-between;
                                        color: #505050;
                                        font-family: Lexend;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 1;
                                        margin-bottom: 5px;
                                    }
                                    .config-item-box-item-input {
                                        width: 100%;
                                        position: relative;

                                        input {
                                            width: 100%;
                                            height: 32px;
                                            border-radius: 10px;
                                            border: 1px solid #fff;
                                            background: #ededed;
                                            color: #505050;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 600;
                                            line-height: 1;
                                            padding: 0 37px 0 8px;
                                            border: none;
                                            outline: none;
                                        }
                                        span {
                                            position: absolute;
                                            top: 50%;
                                            right: 6px;
                                            transform: translateY(-50%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal-button-detail {
            position: relative;

            .customise-tooltip {
                width: 232px;
                text-align: left;
                .customise-tooltip-content {
                    line-height: 1.2;
                }
            }

            :hover {
                .customise-tooltip {
                    display: block;
                }
            }
        }
        .partner_modal_body-box-delete {
            text-align: right;
            span {
                margin: 0px 10px;
                color: rgb(179, 40, 45);
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                height: 32px;
            }
        }
        .partner_modal_body-box {
            margin-top: 12px;
            border-radius: 10px;
            background: #fff;
            padding: 12px;

            .partner_modal_body-box-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    color: #505050;
                    font-family: Lexend;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1;
                }

                h5 {
                    color: #505050;
                    font-size: 12px;
                    font-style: normal;
                    line-height: normal;
                }
            }

            .input-name-gift {
                width: 100%;
                border-radius: 10px;
                border: 1px solid #e1e1e1;
                background: #fff;
                color: #505050;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                padding: 0 12px;
                height: 42px;
            }
        }
    }

    .box-footer-modal {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #fff;
        height: 100px;

        .partner_modal_tab {
            height: 40px;
            display: flex;

            .partner_modal_tab-item {
                height: 40px;
                width: 50%;
                background: #f8f8f8;
                color: #7a7a7a;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                text-align: center;
                position: relative;

                :first-child {
                    border-radius: 0 0 10px 0;
                }
                :last-child {
                    border-radius: 0 0 0 10px;
                }

                .partner_modal_tab-item-line {
                    display: none;
                }

                &.active {
                    background-color: #fff;
                    color: #b3282d;

                    .partner_modal_tab-item-line {
                        display: block;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 4px;
                        background: #b3282d;
                        left: 0;
                        z-index: 5;
                    }
                }
            }
        }

        .partner_modal_body-comfirm-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            padding: 0 16px;
            z-index: 1;
            background-color: #fff;

            button {
                border: none;
                outline: none;
                border-radius: 10px;
                width: 125px;
                height: 38px;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                line-height: 38px;
                text-align: center;
                border: 2px solid #f4f4f4;
                color: #b3282d;
                background-color: #fff;
                line-height: 1;
                margin: 0 5px;

                &.accept {
                    background: #b3282d;
                    color: #fff;
                }
            }
        }
    }
`;

export default ModalCreateUpdateGift;
