import BaseEcomServices from "./BaseEcomServices";

const PartnerServices = {

    GetCampaignCollab: async () => {
        return await BaseEcomServices.Get(`/api/Partner/get-campaign-collab`);
    },
    GetPartnerLandingPageById: async (id) => {
        return await BaseEcomServices.Get(`/api/Partner/get-partner-landing-page-byId/${id}`);
    },
    GetPartnerCampaign: async (landingPageId) => {
        return await BaseEcomServices.Get(`/api/Partner/get-campaign-active/${landingPageId}`);
    },
    GetPartnerLandingPage: async () => {
        return await BaseEcomServices.Get(`/api/Partner/get-partner-landing-page`);
    },
    GetPartnerUserWin: async (model) => {
        return await BaseEcomServices.Post(`/api/Partner/get-partner-user-win`, model);
    },
    ChangePIN: async (pin, campaignId) => {
        return await BaseEcomServices.Get(`/api/Partner/change-pin/${pin}/${campaignId}`);
    },
    GetGiftByCampaignId: async (id, type) => {
        return await BaseEcomServices.Get(`/api/Partner/get-gift-by-campaignId/${id}/${type}`);
    },
    GetGiftCollabByCampaignId: async (campaignId, creatorId) => {
        return await BaseEcomServices.Get(`/api/Partner/get-gift-collab-by-campaignId/${campaignId}/${creatorId}`);
    },
    CreateUpdateGift: async (model) => {
        return await BaseEcomServices.Post(`/api/Partner/create-update-gift`, model);
    },
    CreateUpdateSpecialDay: async (model) => {
        return await BaseEcomServices.Post(`/api/Partner/create-update-special-day`, model);
    },
    CreateUpdateMarketingGiftConfig: async (model) => {
        return await BaseEcomServices.Post(`/api/Partner/create-update-gift-config`, model);
    },
    CreateUpdateMarketingGiftSpecialDayConfig: async (model) => {
        return await BaseEcomServices.Post(`/api/Partner/create-update-gift-special-day-config`, model);
    },
    CreateOrDeleteSpecialDayApply: async (model) => {
        return await BaseEcomServices.Post(`/api/Partner/create-or-delete-special-day-apply`, model);
    },
    GetSpecialDayByGiftId: async (id) => {
        return await BaseEcomServices.Get(`/api/Partner/get-special-day-by-giftId/${id}`);
    },
    GetGiftConfigByGiftId: async (id) => {
        return await BaseEcomServices.Get(`/api/Partner/get-gift-config-by-giftId/${id}`);
    },
    GetMonthAndSpecialDayByGiftId: async (month, year ,id) => {
        return await BaseEcomServices.Get(`/api/Partner/get-full-month-by-giftId/${month}/${year}/${id}`);
    },
    


    DeleteGiftConfigById: async (id) => {
        return await BaseEcomServices.Get(`/api/Partner/delete-gift-config-by-id/${id}`);
    },
    DeleteSpecialDayGifyConfigById: async (id) => {
        return await BaseEcomServices.Get(`/api/Partner/delete-special-day-gift-config-by-id/${id}`);
    },
    DeleteSpecialDayById: async (id) => {
        return await BaseEcomServices.Get(`/api/Partner/delete-special-day-by-id/${id}`);
    },
    DeleteGift: async (id) => {
        return await BaseEcomServices.Get(`/api/Partner/delete-gift/${id}`);
    },

    GetStatisticMonth: async (landingPageId) => {
        return await BaseEcomServices.Get(`/api/Partner/get-statistic-month/${landingPageId}`);
    },
    GetPartnerCollabByCampaign: async (campaignId) => {
        return await BaseEcomServices.Get(`/api/Partner/get-partner-collab/${campaignId}`);
    },
}

export default PartnerServices;