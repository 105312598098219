import React, {  useEffect, useState } from "react";
import styled from "styled-components";
import {BasedModalStyle} from "../PartnerHome/PartnerHome";
import PartnerServices from "../../based/services/PartnerServices"

const ModalChangePin = ({isShow , onClose , colorCode, PIN , campaignId, onSave, setShowToast}) => {
    const [pin, setPin] = useState(PIN)

    useEffect(()=>{
        if(isShow)
            setPin(PIN)
    },[isShow])

    const handleChange = async () => {
        if(pin.length > 2 && pin.length <7){
            let [err, data] = await PartnerServices.ChangePIN(pin , campaignId);
            if (!err && data) {
                onClose()
                onSave()
            } else {
                setShowToast({
                    isShow: true, message: "Lưu thất bại", type: "ERROR"
                })
            }
        }else{
            setShowToast({
                isShow: true, message: "Mã pin từ 3 - 6 số", type: "WARNING"
            })
        }
    };


    return (
            <BasedModalStyle onClick={()=>{onClose()}} className={`modal_center ${isShow ? 'show_modal':''}`}>
                <ModalChangePinStyle 
                    onClick={(e)=>{e.stopPropagation()}}
                    className={`partner_modal `} >
                    <div className="partner_modal_header">
                        <div className="partner_modal_header-title">
                            Đổi mã PIN
                        </div> 
                        <span className="partner_modal_close" onClick={()=>{onClose()}}>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L14 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round"/>
                                <path d="M14 1L0.999999 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round"/>
                            </svg>
                        </span>
                    </div>
                    <div className="partner_modal_body">
                        <div className="partner_modal_body-comfirm-desc">
                            Mã pin phải từ 3 - 6 số </div>
                        <input type="number" pattern="[0-9]*" inputmode="numeric" max='9999'
                            value={pin} onChange={(e) =>{
                                setPin(e.target.value);
                            }}
                         />
                        <div className="partner_modal_body-comfirm-btn">
                            <button onClick={()=>{onClose()}}>Đóng</button>
                            <button className="accept"
                                onClick={()=>{
                                    handleChange()
                                }}
                            >Đổi</button>
                        </div>
                    </div>
                </ModalChangePinStyle>
            </BasedModalStyle>
    );
};

const ModalChangePinStyle = styled.div`
    width: 300px;
    font-family: 'Lexend' ;


    input{
        width: 100%;
        border-radius: 10px;
        border: 1px solid #E1E1E1;
        background: #FFF;
        color: #B9B9B9;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        padding: 0 10px;
        height: 40px;
        margin: 10px 0 0 0;
        outline: none;
    }
    .partner_modal_body-comfirm-desc{
        color: #A0A0A0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.14px;
        margin-top: 5px;
    }
    .partner_modal_body-comfirm-btn{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            margin: 20px auto 0;
            border: none;
            outline: none;
            border-radius: 10px;
            width: calc(50% - 10px);
            height: 38px;
            color: #FFF;
            font-size: 14px;
            font-weight: 600;
            line-height: 38px;
            text-align: center;
            border: 2px solid #f4f4f4;
            color: #B3282D;
            background-color: #fff;
            line-height: 1;
           

            &.accept{
                background: #B3282D;
                color: #fff;
            }
        }

    }
    


    

    
`

export default ModalChangePin

