import React, {useEffect , useState, useContext, useRef} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Common from "../../based/Common";
import AffiliateServices from "../../based/services/AffiliateServices";
import { AuthContext } from "../../contexts/AuthContext";
import { INITIAL_PAGING , NOTIFY, STATUS_TRANSACTION } from "../../based/Constants";
import { Notify } from "../../based/Notify";
import PartnerServices from "../../based/services/PartnerServices";

const INIT_ORDER_COUNT = {
    completedOrderCount: 0,
    inCompletedOrderCount: 0,
    totalUser: 0,
};

export class BasicInformationCompressModel {
    constructor() {
        this.email = "";
        this.phoneNumber = "";
    }
}

const PartnerAffiliate = ({ setShowToast , landingPage}) => {
    const history = useHistory()
    const [profileInfo, setProfileInfo] = useState(new BasicInformationCompressModel());
    const [isSignUp, setIsSignUp] = useState(false);
    const { personal } = useContext(AuthContext);
    const [orderCommission, setOrderCommission] = useState(null);
    const [pagingCommission, setPagingCommission] = useState(INITIAL_PAGING);
    const [orderCount, setOrderCount] = useState(INIT_ORDER_COUNT);
    const [affiliateTraffic, setAffiliateTraffic] = useState([]);
    const [landingPageTraffic, setLandingPageTraffic] = useState([]);
    const [code , setCode] = useState("");
    const [update , setUpdate] = useState(false);
    const isDay = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const activeDayRef = useRef(null)
    const activeDayLPRef = useRef(null)

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    },[])

    useEffect(() => {
        setTimeout(() => {
            if(activeDayRef && activeDayRef.current){
                activeDayRef.current.scrollIntoView({
                    behavior: 'smooth',block: "end"
                });
            }
            if(activeDayLPRef && activeDayLPRef.current){
                activeDayLPRef.current.scrollIntoView({
                    behavior: 'smooth',block: "end"
                });
            }
        },2000)
    },[])

    useEffect(() => {
        let today = new Date();
        let month = today.getMonth() + 1;
        let year = today.getFullYear();
        getBasicInfo();
        _fetchData({ ...pagingCommission, month: month, year: year, orderStatus: STATUS_TRANSACTION.SUCCEED });
        _fetchTraffic();
        
    }, []);

    useEffect(() => {
        getLatestVoucherAffiliate();
        getStatisticMonth()
    }, []);
    
    useEffect(() => {
        getStatisticMonth()
    }, [landingPage]);


    const getLatestVoucherAffiliate = async () => {
        let [err, data] = await AffiliateServices.GetLatestVoucherAffiliate();
        if (!err && data) setCode(data.thirdPartyVoucherCode);
        else setCode("");
    };

    const getStatisticMonth = async () => {
        let [err, data] = await PartnerServices.GetStatisticMonth(landingPage.id);
        if (!err && data) setLandingPageTraffic(data);
        else setLandingPageTraffic([]);
    };


    const getBasicInfo = async () => {
        let [err, data] = await AffiliateServices.GetThirdPartyInfo();
        if (!err && data) {
            if (data.email && data.phoneNumber && data.isVerified) setIsSignUp(true);
            else {
                setIsSignUp(false);
                data.gotEmailVerified = data.email && data.isVerified;
            }
            setProfileInfo(data);
        } else {
            if (personal) setProfileInfo({ ...profileInfo, email: personal.email, phoneNumber: personal.phoneNumber });
            else setProfileInfo(new BasicInformationCompressModel());
            setIsSignUp(false);
        }
    };

    const _fetchData = async (pagingModel) => {
        const [err, resData] = await AffiliateServices.GetOrderCommission(pagingModel);
        if (!err && resData) {
            setOrderCommission(resData.listObjects);
            setPagingCommission(resData.paging);
            setOrderCount({
                inCompletedOrderCount: resData.paging.inCompletedOrderCount,
                completedOrderCount: resData.paging.completedOrderCount,
                totalUser: resData.paging.totalUser,
            });
        } else {
        }
    };
    
    const _fetchTraffic = async () => {
        const [err, data] = await AffiliateServices.GetAffiliateTraffic();
        if (!err && data) setAffiliateTraffic(data);
        else setAffiliateTraffic([]);
    };


    const handleChangeCode = async () => {
        let [err, data] = await AffiliateServices.SaveAffiliateVoucher(code);
        if (!err && data) {
            navigator.clipboard.writeText(code);
            setShowToast({
                isShow: true, message: "Lưu thành công", type: ""
            })
            setUpdate(false);
        } else {
            setShowToast({
                isShow: true, message:err.data, type: "ERROR"
            })
        }
    };

    




    return (
        <PartnerAffiliateStyle className="partner-home-body">
            <div className="body-container">
                <div className="partner-affiliate-link">
                    <strong>{landingPage.redirectUrl}</strong>
                    <span
                        onClick={()=>{
                            window.open(landingPage.redirectUrl,'_blank')
                        }}
                    >
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 1L17 8.5L10 16" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17 8.5H1" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </span>
                </div>
                <div className="affiliate-title">
                    Thống kê tiếp thị liên kết
                </div>
                <div className="affiliate-flex">
                    <div className="affiliate-item">
                        <div className="affiliate-item-top">
                            <span>
                                Mã tiếp thị liên kết cá nhân
                            </span>
                            <div className="affiliate-item-top-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 13.5709L9 7.28516" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 4.42969H9.01214" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> 
                            </div>

                        </div>
                        <div className="affiliate-item-bottom">
                            <input className="affiliate-code" value={code} onChange={(e)=>{
                                setCode(e.target.value);
                            }} maxLength={6} disabled={update ? "": "disabled"} />
                                
                            <div className="affiliate-code-copy"
                                onClick={()=>{
                                    navigator.clipboard.writeText(code);
                                    setShowToast({
                                        isShow: true, message: "Copy thành công", type: ""
                                    })
                                }}
                            >
                                <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5002 19.8571H13.6C15.2568 19.8571 16.6 18.514 16.6 16.8571V4C16.6 2.34315 15.2568 1 13.6 1H5.14282C3.48597 1 2.14282 2.34315 2.14282 4V4" stroke="#B3282D" strokeWidth="2"/>
                                    <rect x="1" y="4.14062" width="12.4571" height="16.8571" rx="2" stroke="#B3282D" strokeWidth="2"/>
                                </svg>
                            </div>
                            {update ? 
                            <div className="affiliate-code-change"
                                onClick={()=>{
                                    handleChangeCode()
                                }}
                            >Lưu</div>
                                : 
                                <div className="affiliate-code-change" onClick={()=>{
                                    setUpdate(true)
                                }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.0738 6.3997L7.77616 16.7104L1 19L3.28667 12.2152L13.6072 1.9274C13.9003 1.63341 14.2484 1.40018 14.6316 1.24104C15.0148 1.08191 15.4256 1 15.8405 1C16.2553 1 16.6661 1.08191 17.0493 1.24104C17.4326 1.40018 17.7807 1.63341 18.0738 1.9274C18.3674 2.22087 18.6003 2.56943 18.7593 2.95313C18.9182 3.33684 19 3.74816 19 4.16355C19 4.57895 18.9182 4.99026 18.7593 5.37397C18.6003 5.75767 18.3674 6.10623 18.0738 6.3997V6.3997Z" stroke="#B3282D" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.5898 3.91797L15.7678 8.33656" stroke="#B3282D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
    
                                </div>
                            }
                            
                        </div>
                    </div>
                    <div className="affiliate-item">
                        <div className="affiliate-item-top">
                            <span>
                                Số người dùng đã liên kết
                            </span>
                            <div className="affiliate-item-top-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 13.5709L9 7.28516" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 4.42969H9.01214" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> 
                            </div>
                        </div>
                        <div className="affiliate-item-bottom">
                            <strong>{orderCount.totalUser}</strong>
                        </div>
                    </div>
                </div>

                <div className="affiliate-trafic">
                    <div className="affiliate-trafic-title">
                        <h5>Lượt nhấn vào landing page</h5>
                        <span>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 13.5709L9 7.28516" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 4.42969H9.01214" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> 
                        </span>
                    </div>
                    <div className="affiliate-trafic-box">
                        <div className="arrow-icon">
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.59362 1L1.31934 7L7.59362 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </div>
                        <div className="affiliate-trafic-list">
                            {landingPageTraffic.map((item , idx)=>{
                                return <div key={idx} ref={isDay == new Date(item.date).getDate() ? activeDayLPRef : null} className={`affiliate-trafic-item ${isDay == new Date(item.date).getDate() ? "active" : ""}`}>
                                    <span>{new Date(item.date).getDate()}</span>
                                    <strong>{item.countView}</strong>
                                </div>
                            })}
                        </div>
                        <div className="arrow-icon">
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.4513 13L7.72559 7L1.4513 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    
                </div>
                <div className="affiliate-trafic">
                    <div className="affiliate-trafic-title">
                        <h5>Lượt nhấn sản phẩm theo tuần</h5>
                        <span>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 13.5709L9 7.28516" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 4.42969H9.01214" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> 
                        </span>
                    </div>
                    <div className="affiliate-trafic-box">
                        <div className="arrow-icon">
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.59362 1L1.31934 7L7.59362 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </div>
                        <div className="affiliate-trafic-list">
                            {affiliateTraffic.map((item , idx)=>{
                                return <div key={idx} ref={isDay == item.day ? activeDayRef : null} className={`affiliate-trafic-item ${isDay == item.day ? "active" : ""}`}>
                                    <span>{item.day}</span>
                                    <strong>{item.affiliate}</strong>
                                </div>
                            })}
                        </div>
                        <div className="arrow-icon">
                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.4513 13L7.72559 7L1.4513 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    
                </div>


                
                <div className="affiliate-statistic">
                    <div className="affiliate-statistic-title">
                        <h5>Thống kê theo tháng</h5>
                        <div className="affiliate-statistic-box"
                            
                        >
                            <span onClick={()=>{
                                let pagingMonth = pagingCommission.month;
                                let pagingYear = pagingCommission.year;
                                if (pagingMonth == 1) {
                                    pagingMonth = 12;
                                    pagingYear = pagingYear - 1;
                                } else pagingMonth -= 1;

                                _fetchData({ ...pagingCommission, month: pagingMonth, year: pagingYear });
                            }}>
                                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 1L1 8L8 15" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <strong>{pagingCommission.month}/{pagingCommission.year}</strong>
                            <span
                                onClick={(e) => {
                                    let pagingMonth = pagingCommission.month;
                                    let pagingYear = pagingCommission.year;
                                    if (pagingMonth == 12) {
                                        pagingMonth = 1;
                                        pagingYear = pagingYear + 1;
                                    } else pagingMonth += 1;
                                    _fetchData({ ...pagingCommission, month: pagingMonth, year: pagingYear });
                                }}
                            >
                                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999998 15L8 8L1 1" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="affiliate-statistic-table">
                        <div className="affiliate-statistic-row">
                            <div className="affiliate-statistic-column w-70">
                            Số đơn hàng chưa hoàn thành
                            </div>
                            <div className="affiliate-statistic-column w-30">
                            {orderCount.inCompletedOrderCount}
                            </div>
                        </div>
                        <div className="affiliate-statistic-row">
                            <div className="affiliate-statistic-column w-70">
                            Số đơn hàng đã hoàn thành
                            </div>
                            <div className="affiliate-statistic-column w-30">
                            {orderCount.completedOrderCount}
                            </div>
                        </div>

                        <div className="affiliate-statistic-row">
                            <div className="affiliate-statistic-column w-70">
                            Số tiền tiếp thị liên kết
                            </div>
                            <div className="affiliate-statistic-column w-30">
                            {Common.formatCurrency(pagingCommission.totalCommission ? Number(pagingCommission.totalCommission) : 0)}
                            </div>
                        </div>
                        <div className="affiliate-statistic-row">
                            <div className="affiliate-statistic-column w-70">
                            Thuế thu nhập cá nhân
                            </div>
                            <div className="affiliate-statistic-column w-30">
                            {Common.formatCurrency(
                                (pagingCommission.totalCommission ? Number(pagingCommission.totalCommission) : 0) >= 2000000 
                                ? (pagingCommission.totalCommission ? Number(pagingCommission.totalCommission) : 0) / 10 : 0)}
                            </div>
                        </div>
                        <div className="affiliate-statistic-row">
                            <div className="affiliate-statistic-column w-70">
                            Số tiền thực lĩnh
                            </div>
                            <div className="affiliate-statistic-column red w-30">
                            {Common.formatCurrency(
                                (pagingCommission.totalCommission ? Number(pagingCommission.totalCommission) : 0)
                                -
                                ((pagingCommission.totalCommission ? Number(pagingCommission.totalCommission) : 0) >= 2000000 ? 
                                (pagingCommission.totalCommission ? Number(pagingCommission.totalCommission) : 0) / 10 : 0)
                            )}
                            </div>
                        </div>
                        <div className="affiliate-statistic-row">
                            <div className="affiliate-statistic-column red w-100">
                            Dự kiến thanh toán vào đầu tháng sau
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PartnerAffiliateStyle>
    );
};
const PartnerAffiliateStyle = styled.div`

    .partner-affiliate-link{
        width: 100%;
        padding: 16px;
        border-radius: 10px;
        background: #B3282D;
        display: flex;
        align-items: center;
        justify-content: space-between;

        strong{
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #FFF;
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        span{
            display: grid;
            place-items: center;
            min-width: 42px;
            height: 42px;
            border-radius: 50%;
            background-color: #fff;

            svg{
                margin: auto;
            }
        }
    }

    .affiliate-title{
        color: #505050;
        font-family: Lexend;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        margin-top: 20px;

    }
    
    .affiliate-flex{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .affiliate-item{    
            width: calc(50% - 5px);
            border-radius: 12px;
            background: #fff;
            padding: 12px;

            .affiliate-item-top{
                display: flex;
                align-items: start;
                justify-content: space-between;
                span{
                    color: #444;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .affiliate-item-top-icon{
                    margin-left: 15px;
                }
            }
            .affiliate-item-bottom{
                display: flex;
                align-items: center;
                margin-top: 10px;
                height: 29px;

                .affiliate-code{
                    border: none;
                    outline: none;
                    border-radius: 5px;
                    background: #E0E0E0;
                    height: 29px;
                    width: 60%;
                }
                .affiliate-code-copy , .affiliate-code-change{
                    width: 20%;
                    display: grid;
                    place-items: center;


                    color: #B3282D;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }



                strong{
                    color: #B3282D;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 29px;

                }
            }
        }
    }

    .affiliate-trafic{
        background-color: #fff;
        border-radius: 10px;
        margin-top: 10px;
        padding: 12px;

        .affiliate-trafic-title{
            display: flex;
            align-items: center;

            h5{
                color: #444;
                font-family: Lexend;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0 5px 0 0;
            }
        }   
        .affiliate-trafic-box{
            margin-top: 10px;
            border-radius: 10px;
            border: 2px solid #B3282D;
            width: 100%;
            height: 75px;
            display: flex;
            overflow: hidden;

            .arrow-icon{
                width: 20px;
                height: 100%;
                display: grid;
                place-items: center;
                background-color: #B3282D;
            }

            .affiliate-trafic-list{
                flex: 1;
                height: 100%;
                display: flex;
                overflow-y: auto;

                ::-webkit-scrollbar {
                    display: none;
                }

                .affiliate-trafic-item{
                    width: 14.2857%;
                    max-width: 14.2857%;
                    min-width: 14.2857%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                    padding: 10px 0;
                    border: 1px solid #F3F3F3;

                    span{
                        color: #979797;
                        text-align: center;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    strong{
                        color: #444;
                        text-align: center;
                        font-family: Lexend;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                   
                    
                    &.active{
                        background-color: rgba(179, 40, 45, 0.1);


                        strong{
                            color: #B3282D;
                        }
                    }
                }
            }
        } 
    }


    .affiliate-statistic{
        padding: 12px;
        border-radius: 12px;
        background-color: #fff;
        margin-top: 10px;

        .affiliate-statistic-title{
            display: flex;
            align-items: center;
            justify-content: space-between;



            h5{
                color: #444;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                margin: 0;
            }

            .affiliate-statistic-box{
                width: 130px;
                display: flex;
                border-radius: 10px;
                background: #F7F7F7;
                padding: 6px 12px;
                align-items: center;
                justify-content: space-between;

                strong{
                    color: #444;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1;
                }

            }


            
        }
        .affiliate-statistic-table{
            margin-top: 10px;
            border-radius: 10px;
            border: 1px solid #E5E5E5;
            background: #FFF;
            overflow: hidden;

            .affiliate-statistic-row{
                display: flex;

                .affiliate-statistic-column{
                    border: 1px solid #E5E5E5;
                    padding: 12px;

                    &.w-70{
                        width: 70%;
                        min-width: 70%;
                        max-width: 70%;
                        color: #979797;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    &.w-30{
                        width: 30%;
                        min-width: 30%;
                        max-width: 30%;
                        text-align: center;
                        color: #444;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    &.w-100{
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        background-color: rgba(179, 40, 45, 0.1);

                    }
                    

                    &.red{
                        color: #B3282D;

                    }
                }

            }


        }
    }
`
export default PartnerAffiliate;






