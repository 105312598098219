import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import cookie from "react-cookies";
import styled from "styled-components";
import LogoRanus from "../../_assets/image/LogoRanus.png";
import Common from "../../based/Common";
import { INITIAL_PAGING } from "../../based/Constants";
import { NotifyDialog } from "../../based/NotifyDialog";
import SpinnerServices from "../../based/services/SpinnerServices";
import { AuthContext } from "../../contexts/AuthContext";
import ArchiveModal from "./ArchiveModal";
import CreateQRModal from "./CreateQRModal";
import GiftModal from "./GiftModal";
import QRModal from "./QRModal";

export const camSpinnerLinkDTO = {
    id: 0, // Set a default value or replace with actual data
    createdBy: null,
    createdDate: null,
    modifiedBy: null,
    modifiedDate: null,
    isDelete: false,
    linkUrl: "",
    linkCode: "",
    status: 0, // Replace with actual StatusVoucher object or enum value
    statusName: "", // Replace with actual StatusVoucher object or enum value
    note: "",
    campaignId: 0,
    partnerId: 0,
    partnerCode: "",
    isArchive: false,
    isChecked: false,
    credit: 0,
};

const statusVoucherEnum = {
    Normal: 0,
    AlreadyUse: 1,
    Expired: 2,
};

function MainPage(props) {
    const [showModalGift, setShowModalGift] = useState(false);
    const [showModalArchive, setShowModalArchive] = useState(false);
    const [showModalQR, setShowModalQR] = useState(false);
    const [showCreateModalQR, setShowCreateModalQR] = useState(false);
    const [lstLink, setLstLink] = useState([camSpinnerLinkDTO]);
    const [paging, setPaging] = useState(INITIAL_PAGING);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef(null);
    const campaignId = 1;
    const authContext = useContext(AuthContext);

    useEffect(() => {
        getData({ ...paging, campaignId: campaignId });
    }, []);

    const getData = async (paging) => {
        setLoading(true);

        const [err, data] = await SpinnerServices.GetSpinnerLinkByPaging(paging);
        if (!err && data) {
            if (paging.pageNumber == 1) setLstLink(data.listObjects);
            else setLstLink([...lstLink, ...data.listObjects]);
            setPaging(data.paging);
            if (data.listObjects.length == 0) setHasMore(false);
        } else {
            if (paging.pageNumber == 1) setLstLink([]);
            setPaging(paging);
            setHasMore(false);
        }
        setLoading(false);
    };

    const setLastCollectionHandler = (element) => {
        if (loading || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                let updatePaging = { ...paging };
                updatePaging.pageNumber += 1;
                getData(updatePaging);
            }
        });
        if (element) observer.current.observe(element);
    };

    const downloadQr = async (model) => {
        window.location.href = `https://api.ranus.vn/api/spinner/create-qr-share/${model.id}`;
    };

    const checkedItem = useMemo(() => {
        let checked = lstLink.find((x) => x.isChecked);
        return checked;
    }, [lstLink]);

    const handleSignOut = () => {
        cookie.remove("token", { path: "/" });
        authContext.logout();
        window.location.replace("/login");
    };

    return (
        <React.Fragment>
            <Wrapper>
                <div className="header-section">
                    <div className="header-left">
                        <div className="logo-box">
                            <img src={LogoRanus} alt="logo" />
                            <span>Partner</span>
                        </div>
                    </div>
                    <div className="header-right">
                        {authContext && authContext.personal && <div className="partner-name">{authContext.personal.fullName}</div>}
                        <div
                            className="logout"
                            onClick={(e) => {
                                handleSignOut();
                            }}
                        >
                            Đăng xuất
                        </div>
                    </div>
                </div>
                <div className="action-button">
                    {/* <div className="action-left">
                        <div className="archive-button">
                            <div className="icon">
                                <ArchiveIcon />
                            </div>
                            <span>Ẩn link</span>
                        </div>
                    </div> */}
                    {/* <div className="action-right">
                        <div
                            className="archive-overview"
                            onClick={(e) => {
                                setShowModalArchive(true);
                            }}
                        >
                            <span>Link đã ẩn (2)</span>
                        </div>
                    </div> */}
                </div>
                <div className="body-section">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th colSpan={2}>Link</th>
                                <th>Trạng thái</th>
                                <th colSpan={2}>Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lstLink && lstLink.length > 0 ? (
                                lstLink.map((item, idx) => {
                                    let clsStatus = item.status == statusVoucherEnum.Normal ? "not-get" : item.status == statusVoucherEnum.AlreadyUse ? "use" : "get";
                                    return (
                                        <tr key={idx} ref={setLastCollectionHandler}>
                                            <td>
                                                <input
                                                    type={"checkbox"}
                                                    checked={item.isChecked}
                                                    onChange={(e) => {
                                                        let newList = [...lstLink];
                                                        newList.map((ele) => {
                                                            ele.isChecked = false;
                                                        });
                                                        newList[idx].isChecked = e.target.checked;
                                                        setLstLink(newList);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <p
                                                    className="link"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigator.clipboard.writeText(item.linkUrl);
                                                        NotifyDialog("Đã copy link vào clipboard");
                                                    }}
                                                >
                                                    {item.linkUrl}
                                                </p>
                                                <p className="link-code">Mã: {item.partnerCode}</p>
                                                <p className="link-date">Ngày tạo: {Common.formatDate(new Date(item.createdDate))}</p>
                                            </td>
                                            <td>
                                                <p className={clsStatus}>{item.statusName}</p>
                                            </td>
                                            <td>
                                                <p className="note">{item.note}</p>
                                                <div className="edit-note">
                                                    <div className="icon">
                                                        <EditNoteIcon />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn download-btn"
                                                    onClick={(e) => {
                                                        downloadQr(item);
                                                    }}
                                                >
                                                    <div className="icon">
                                                        <DownloadIcon />
                                                    </div>
                                                </button>
                                                <button
                                                    className="btn share-btn"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        let newList = [...lstLink];
                                                        newList.map((ele) => {
                                                            ele.isChecked = false;
                                                        });

                                                        let val = !newList[idx].isChecked;
                                                        newList[idx].isChecked = val;
                                                        setLstLink(newList);

                                                        if (val) setShowModalQR(true);
                                                    }}
                                                >
                                                    <div className="icon">
                                                        <ShareIcon />
                                                    </div>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5} style={{ textAlign: "center" }}>
                                        No data
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="bottom-bar">
                    <div className="filter-list"></div>
                    <div
                        className="gift-modal"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowModalGift(true);
                        }}
                    >
                        <div className="icon">
                            <GiftBlackIcon />
                        </div>
                        <div className="label-bottom-bar">Quà tặng</div>
                    </div>
                </div>

                <div
                    className="create-link"
                    onClick={(e) => {
                        setShowCreateModalQR(true);
                    }}
                >
                    <div className="qr-icon">
                        <div className="icon">
                            <QRIcon />
                        </div>
                    </div>
                    <p>Tạo mã</p>
                </div>
            </Wrapper>
            <GiftModal show={showModalGift} onClose={() => setShowModalGift(false)} />
            <ArchiveModal show={showModalArchive} onClose={() => setShowModalArchive(false)} />
            {checkedItem && <QRModal show={showModalQR} onClose={() => setShowModalQR(false)} item={checkedItem} />}
            <CreateQRModal
                show={showCreateModalQR}
                onClose={() => setShowCreateModalQR(false)}
                campaignId={campaignId}
                onSave={() => {
                    setShowCreateModalQR(false);
                    getData({ ...paging, pageNumber: 1, campaignId: campaignId });
                }}
            />
        </React.Fragment>
    );
}

export default MainPage;

const Wrapper = styled.div`
    height: 100vh;
    padding: 12px 16px 0 16px;

    td button {
        outline: none !important;
        border: none !important;
    }

    .bottom-bar {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 70px;
        background: #fff;
        box-shadow: 0px -1px 0px 0px #f3f3f3;
        align-items: center;
        padding-right: 80px;
    }

    .gift-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
    }

    .label-bottom-bar {
        color: #a0a0a0;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 6px;
    }

    .header-section {
        display: flex;
    }

    .header-right {
        margin-left: auto;
    }

    .logo-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .logo-box span {
        color: #b3282d;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 111.111% */
        margin-top: 4px;
    }

    .partner-name {
        color: #303030;
        text-align: right;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .logout {
        color: #b3282d;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-top: 5px;
    }

    .action-button {
        display: flex;
        align-items: center;
    }

    .archive-button {
        display: flex;
        align-items: center;
    }

    .action-right {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .icon {
        display: flex;
    }

    .archive-button span {
        color: #303030;
        text-align: center;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 4px;
    }

    .archive-overview span {
        color: #303030;
        text-align: right;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .body-section {
        margin-top: 10px;
    }

    tr th {
        vertical-align: middle !important;
        text-align: center;
        color: var(--Tiu, #444);
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    tr {
    }

    tr td {
        vertical-align: middle !important;
    }

    td p {
        margin-bottom: 0 !important;
    }

    p.link {
        overflow: hidden;
        color: var(--Ca-hng, #1ba6f9);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        width: 150px;
    }

    p.link-code {
        color: #a0a0a0;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.link-date {
        color: #a0a0a0;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.not-get {
        color: #b3282d;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.get {
        color: #35aa49;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.use {
        color: #35aa49;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.user-info {
        color: #35aa49;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    p.note {
        color: #303030;
        font-family: Lexend;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .edit-note {
        display: flex;
    }

    tr.active {
        background: #ebf7ed;
    }

    .create-link {
        display: flex;
        position: fixed;
        bottom: 5px;
        right: 0;
        border-radius: 16px 0 0 16px;
        background: #b3282d;
        flex-direction: column;
        justify-content: center;
        padding: 12px 18px;
        align-items: center;
    }

    .create-link p {
        color: #fff;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0 !important;
        margin-top: 4px;
    }

    .qr-icon {
        display: flex;
    }

    .header-right {
        text-align: right;
    }
`;

const ArchiveIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5.39453H1V15.521H15V5.39453Z" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.3659 1.47852H7.99517H3.63404L1 5.39419H7.99517H15L12.3659 1.47852Z" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.99512 7.87695L7.99512 12.083" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.7739 10.668L7.99514 13.2534L5.21637 10.668" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const EditNoteIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 15H15.75" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M12.375 2.62419C12.6734 2.32582 13.078 2.1582 13.5 2.1582C13.7089 2.1582 13.9158 2.19936 14.1088 2.27931C14.3019 2.35926 14.4773 2.47646 14.625 2.62419C14.7727 2.77193 14.8899 2.94732 14.9699 3.14035C15.0498 3.33338 15.091 3.54026 15.091 3.74919C15.091 3.95813 15.0498 4.16501 14.9699 4.35804C14.8899 4.55107 14.7727 4.72646 14.625 4.87419L5.25 14.2492L2.25 14.9992L3 11.9992L12.375 2.62419Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const DownloadIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 11.25V2.25" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const ShareIcon = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6556 9.92737L14.2447 6.49214C14.6563 6.0982 14.6561 5.4405 14.2442 5.04683L10.6551 1.61627C10.0189 1.00819 8.96417 1.4591 8.96417 2.33916V3.57928C8.90146 3.58713 8.84658 3.59498 8.79171 3.59498C7.79619 3.66562 6.8477 3.90895 5.93841 4.32496C2.89698 5.72998 1 8.68917 1 12.0408V12.1925C1 12.8103 1.86741 12.9893 2.1523 12.4411C3.31243 10.2041 5.1467 8.79906 7.58455 8.19466C8.03135 8.07692 8.486 8.03768 8.96417 7.95918V9.20495C8.96417 10.0853 10.0196 10.5361 10.6556 9.92737Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

const GiftIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4751_1196)">
                <path d="M15 9V16.5H3V9" stroke="#35AA49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.5 5.25H1.5V9H16.5V5.25Z" stroke="#35AA49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 16.5V5.25" stroke="#35AA49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M9 5.25H5.625C5.12772 5.25 4.65081 5.05246 4.29917 4.70083C3.94754 4.34919 3.75 3.87228 3.75 3.375C3.75 2.87772 3.94754 2.40081 4.29917 2.04917C4.65081 1.69754 5.12772 1.5 5.625 1.5C8.25 1.5 9 5.25 9 5.25Z"
                    stroke="#35AA49"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9 5.25H12.375C12.8723 5.25 13.3492 5.05246 13.7008 4.70083C14.0525 4.34919 14.25 3.87228 14.25 3.375C14.25 2.87772 14.0525 2.40081 13.7008 2.04917C13.3492 1.69754 12.8723 1.5 12.375 1.5C9.75 1.5 9 5.25 9 5.25Z"
                    stroke="#35AA49"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4751_1196">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const QRIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.64326 15.8953C2.7618 15.8953 1.88034 15.8953 0.994426 15.8953C0.406784 15.8953 0.0996082 15.5925 0.0996083 15.0094C0.0996086 13.2331 0.0996089 11.4568 0.0996092 9.67607C0.0996093 9.09288 0.411237 8.78125 0.989975 8.78125C2.76625 8.78125 4.54699 8.78125 6.32327 8.78125C6.88865 8.78125 7.21363 9.10178 7.21363 9.66271C7.21363 11.4479 7.21363 13.2286 7.21363 15.0138C7.21363 15.5792 6.89755 15.8908 6.32772 15.8953C5.4329 15.8953 4.53808 15.8953 3.64326 15.8953ZM3.64326 13.5224C3.77237 13.5224 3.90147 13.5224 4.03057 13.5224C4.48911 13.5135 4.80964 13.2064 4.8319 12.7523C4.84526 12.4807 4.84526 12.2092 4.8319 11.9376C4.8141 11.488 4.49802 11.1763 4.05283 11.1585C3.78127 11.1452 3.50971 11.1452 3.23815 11.1585C2.77961 11.1808 2.47243 11.5013 2.46798 11.9599C2.46353 12.2359 2.45908 12.5163 2.46798 12.7923C2.48579 13.1886 2.79742 13.5002 3.19363 13.518C3.34054 13.5269 3.48745 13.518 3.63881 13.518L3.64326 13.5224Z"
                fill="white"
            />
            <path
                d="M3.66552 0.09961C4.55589 0.0996102 5.4418 0.0996103 6.33217 0.0996105C6.89755 0.0996106 7.21363 0.415691 7.21363 0.981073C7.21363 2.75735 7.21363 4.53363 7.21363 6.31436C7.21363 6.88865 6.8931 7.20918 6.31881 7.20918C4.54253 7.20918 2.7618 7.20918 0.985522 7.20918C0.415688 7.20918 0.0996082 6.8931 0.0996083 6.32772C0.0996086 4.54699 0.0996089 2.7618 0.0996092 0.981072C0.0996093 0.406786 0.406786 0.0996094 0.981072 0.0996095C1.87589 0.0996097 2.77071 0.0996098 3.66552 0.09961ZM2.47243 3.64327C2.47243 3.77237 2.47243 3.90147 2.47243 4.03057C2.48134 4.48911 2.78406 4.8141 3.2426 4.83636C3.51416 4.84971 3.78572 4.84971 4.05728 4.83636C4.50692 4.81855 4.81855 4.50692 4.84081 4.05729C4.85416 3.78572 4.85416 3.51416 4.84081 3.2426C4.81855 2.78406 4.49802 2.47689 4.03948 2.46798C3.76792 2.46353 3.49635 2.45908 3.22479 2.46798C2.81077 2.48134 2.49469 2.79742 2.47689 3.21144C2.47243 3.3539 2.47689 3.49636 2.47689 3.63881L2.47243 3.64327Z"
                fill="white"
            />
            <path
                d="M12.3466 0.09961C13.237 0.0996102 14.1229 0.0996103 15.0133 0.0996105C15.5875 0.0996106 15.8947 0.402335 15.8947 0.976621C15.8947 2.7618 15.8947 4.54254 15.8947 6.32772C15.8947 6.89755 15.5786 7.20918 15.0133 7.21363C13.2281 7.21363 11.4473 7.21363 9.66216 7.21363C9.11013 7.21363 8.78515 6.88865 8.7807 6.34107C8.7807 4.55144 8.7807 2.7618 8.7807 0.972168C8.7807 0.415689 9.10123 0.104061 9.65771 0.104061C10.5525 0.104062 11.4473 0.104062 12.3422 0.104062L12.3466 0.09961ZM13.5263 3.64772C13.5263 3.51416 13.5263 3.37616 13.5263 3.2426C13.513 2.79742 13.1925 2.48134 12.7473 2.47243C12.4891 2.46798 12.2309 2.46798 11.9682 2.47243C11.5008 2.48134 11.1758 2.79297 11.158 3.25596C11.1491 3.51861 11.1491 3.78572 11.158 4.05283C11.1758 4.49802 11.4874 4.8141 11.9326 4.83636C12.2042 4.84971 12.4757 4.84971 12.7473 4.83636C13.2058 4.8141 13.513 4.49357 13.5219 4.03948C13.5219 3.91038 13.5219 3.78127 13.5219 3.65217L13.5263 3.64772Z"
                fill="white"
            />
            <path
                d="M11.1579 10.365C11.4206 10.365 11.661 10.365 11.9014 10.365C12.4178 10.365 12.7428 10.6944 12.7428 11.2153C12.7428 11.7094 12.7428 12.2036 12.7428 12.6977C12.7428 13.1919 12.4178 13.5169 11.9192 13.5258C11.6833 13.5258 11.4518 13.5258 11.2158 13.5258C10.695 13.5213 10.3744 13.2008 10.3744 12.6799C10.3744 12.1902 10.3744 11.705 10.3744 11.2153C10.3744 11.2019 10.37 11.193 10.3566 11.1529C10.1385 11.1529 9.91144 11.1529 9.68439 11.1529C9.1012 11.1529 8.79403 10.8413 8.78958 10.2626C8.78958 10.04 8.78512 9.81739 8.78958 9.5948C8.80293 9.11845 9.12346 8.79347 9.59536 8.78012C9.84911 8.77121 10.1029 8.77121 10.3566 8.78012C10.833 8.79347 11.149 9.114 11.1579 9.59035C11.1579 9.8352 11.1579 10.0845 11.1579 10.3605L11.1579 10.365Z"
                fill="white"
            />
            <path
                d="M13.9226 11.1532C13.7756 11.1532 13.6243 11.1577 13.4774 11.1532C13.0767 11.131 12.7562 10.8149 12.7428 10.4098C12.7339 10.1204 12.7339 9.83104 12.7428 9.54167C12.7562 9.12765 13.0812 8.81157 13.513 8.78931C13.789 8.77596 14.0695 8.77596 14.3455 8.78931C14.7996 8.81157 15.1067 9.14546 15.1112 9.59955C15.1112 9.8533 15.1112 10.1071 15.1112 10.3564C15.1023 10.8104 14.7818 11.1354 14.3321 11.1488C14.1986 11.1488 14.0606 11.1488 13.927 11.1488L13.9226 11.1532Z"
                fill="white"
            />
            <path
                d="M11.1535 14.7282C11.1535 14.8707 11.1579 15.0131 11.1535 15.1556C11.1357 15.5607 10.8196 15.8768 10.4189 15.8946C10.1429 15.9035 9.86247 15.9035 9.58645 15.8946C9.13237 15.8857 8.81183 15.5741 8.78958 15.1155C8.78067 14.8529 8.78067 14.5857 8.78958 14.3186C8.80738 13.8645 9.12346 13.544 9.57755 13.5262C9.83576 13.5173 10.094 13.5173 10.3566 13.5262C10.833 13.5396 11.149 13.8645 11.1579 14.3409C11.1579 14.47 11.1579 14.5991 11.1579 14.7282L11.1535 14.7282Z"
                fill="white"
            />
            <path
                d="M13.9136 15.8907C13.7712 15.8907 13.6287 15.8951 13.4863 15.8907C13.0767 15.8729 12.7562 15.5479 12.7428 15.1383C12.7339 14.8668 12.7384 14.5952 12.7428 14.3236C12.7473 13.9007 13.0322 13.5713 13.4507 13.5357C13.7623 13.5089 14.0784 13.5089 14.3944 13.5357C14.8218 13.5713 15.1067 13.9007 15.1112 14.3325C15.1112 14.5907 15.1156 14.8489 15.1112 15.1116C15.1023 15.5523 14.7817 15.8773 14.341 15.8951C14.1986 15.8996 14.0561 15.8951 13.9136 15.8951L13.9136 15.8907Z"
                fill="white"
            />
        </svg>
    );
};

const GiftBlackIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4760_936)">
                <path d="M16.6666 10L16.6666 18.3333L3.33331 18.3333L3.33331 10" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.3334 5.83399L1.66669 5.83398L1.66669 10.0007L18.3334 10.0007L18.3334 5.83399Z" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 18.334L10 5.83398" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M10 5.83268L6.25002 5.83268C5.69749 5.83268 5.16758 5.61319 4.77688 5.22249C4.38618 4.83179 4.16669 4.30188 4.16669 3.74935C4.16669 3.19681 4.38618 2.66691 4.77688 2.27621C5.16758 1.88551 5.69749 1.66602 6.25002 1.66602C9.16669 1.66602 10 5.83268 10 5.83268Z"
                    stroke="#303030"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10 5.83268L13.75 5.83268C14.3025 5.83268 14.8324 5.61319 15.2231 5.22249C15.6138 4.83179 15.8333 4.30188 15.8333 3.74935C15.8333 3.19682 15.6138 2.66691 15.2231 2.27621C14.8324 1.88551 14.3025 1.66602 13.75 1.66602C10.8333 1.66602 10 5.83268 10 5.83268Z"
                    stroke="#303030"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4760_936">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
