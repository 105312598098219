import React, { useCallback, useEffect, useReducer } from "react";
import * as actionTypes from "../actions/actionTypes";
import Common from "../based/Common";
import { THIRD_PARTY_TYPE } from "../based/Constants";
import AccountServices from "../based/services/AccountServices";
import { AuthContext } from "../contexts/AuthContext";
import { authReducer } from "../reducers/authReducer";

const AuthProvider = (props) => {
    const [auth, dispatch] = useReducer(authReducer, null, () => {
        return {
            facebookUserId: null,
            googleUserId: null,
            email: null,
            data: null,
        };
    });

    useEffect(() => {
        getUserById();
    }, [Common.GetToken()]);

    const loginHandler = (props) => {
        dispatch({
            type: actionTypes.AUTH_LOGIN,
            data: props,
        });
    };

    const logoutHandler = useCallback(() => {
        dispatch({ type: actionTypes.AUTH_LOGOUT });
    }, []);

    const getUserById = async () => {
        if (Common.GetToken()) {
            let [err, datas] = await AccountServices.GetUserById();
            if (!err && datas) {
                datas.isAffiliate = (datas.thirdPartyType & THIRD_PARTY_TYPE.Affiliator) == THIRD_PARTY_TYPE.Affiliator;
                dispatch({
                    type: actionTypes.AUTH_UPDATE_PERSONAL,
                    data: datas,
                });
            }
        }
    };

    const authValues = {
        facebookUserId: auth.facebookUserId,
        googleUserId: auth.googleUserId,
        email: auth.email,
        login: loginHandler,
        logout: logoutHandler,
        personal: auth.data,
        getUserById,
    };

    return <AuthContext.Provider value={authValues}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
