import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BasedModalStyle } from "../PartnerHome/PartnerHome";
import Commom from "../../based/Common";
import PartnerServices from "../../based/services/PartnerServices";

const TAB_CONFIG = {
    SHOP: 0,
    COLLAB: 1,
};
const TAB_PARTNER = {
    AFFILIATE: 0,
    GIFT: 1,
    WINNIN_LIST: 2,
    COLLAB: 3,
};

const ModalChangeLandingPage = ({ isShow, onClose, colorCode, setShowToast, landingActive, setLandingActive, landingPage, setTab, tab, setCampaignCollabActive, campaignCollabActive, campaignCollab }) => {
    const isDesktop = Commom.isDesktop();
    const [tabIndex, setTabIndex] = useState(TAB_CONFIG.SHOP);

    return (
        <BasedModalStyle
            onClick={(e) => {
                e.stopPropagation();
                onClose();
            }}
            className={`${isDesktop ? "modal_center" : "relative_modal bottom_modal"} ${isShow ? "show_modal" : ""}`}
        >
            <ModalChangeLandingPageStyle
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className={`partner_modal `}
            >
                <div className="partner_modal_header">
                    <div className="partner_modal_header-title">Danh sách cửa hàng</div>
                    <span
                        className="partner_modal_close"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L14 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M14 1L0.999999 14" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </span>
                </div>

                <div className="partner_modal_body">
                    {tabIndex == TAB_CONFIG.SHOP
                        ? landingPage &&
                          landingPage.length > 0 &&
                          landingPage.map((item, idx) => {
                              return (
                                  <div
                                      key={idx}
                                      onClick={() => {
                                          setLandingActive(item);
                                          if (tab === TAB_PARTNER.COLLAB) {
                                              setTab(TAB_PARTNER.AFFILIATE);
                                          }
                                          onClose();
                                      }}
                                      className={`item-landing ${item.id == landingActive.id ? "active" : ""}`}
                                  >
                                      <img src={item.thumbnail} />
                                      <span>{item.name}</span>
                                      <div className="tick">
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <circle cx="8" cy="8" r="8" fill="#B3282D" />
                                              <path d="M4 8L7 11L12 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                      </div>
                                  </div>
                              );
                          })
                        : campaignCollab &&
                          campaignCollab.length > 0 &&
                          campaignCollab.map((item, idx) => {
                              return (
                                  <div
                                      key={idx}
                                      onClick={() => {
                                          setCampaignCollabActive(item);
                                          if (tab !== TAB_PARTNER.COLLAB) {
                                              setTab(TAB_PARTNER.COLLAB);
                                          }

                                          onClose();
                                      }}
                                      className={`item-landing ${item.id == campaignCollabActive.id && tab == TAB_PARTNER.COLLAB ? "active" : ""}`}
                                  >
                                      <span>{item.brandName}{" - "}{item.name}</span>
                                      <div className="tick">
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <circle cx="8" cy="8" r="8" fill="#B3282D" />
                                              <path d="M4 8L7 11L12 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </svg>
                                      </div>
                                  </div>
                              );
                          })}
                </div>

                <div className="box-footer-modal">
                    <div className="partner_modal_tab">
                        <div
                            className={`partner_modal_tab-item ${tabIndex === TAB_CONFIG.SHOP ? "active" : ""}`}
                            onClick={() => {
                                setTabIndex(TAB_CONFIG.SHOP);
                            }}
                        >
                            Cửa hàng
                            <div className="partner_modal_tab-item-line"></div>
                        </div>
                        <div
                            className={`partner_modal_tab-item ${tabIndex === TAB_CONFIG.COLLAB ? "active" : ""}`}
                            onClick={() => {
                                setTabIndex(TAB_CONFIG.COLLAB);
                            }}
                        >
                            Chiến dịch Collab
                            <div className="partner_modal_tab-item-line"></div>
                        </div>
                    </div>
                </div>
            </ModalChangeLandingPageStyle>
        </BasedModalStyle>
    );
};

const ModalChangeLandingPageStyle = styled.div`
    width: 100%;
    bottom: 0;
    height: 350px;
    border-radius: 16px 16px 0 0 !important;
    position: relative;
    background-color: #fff !important;
    font-family: "Lexend";

    @media (min-width: 768px) {
        max-width: 350px;
        border-radius: 16px !important;
        overflow: hidden;
    }

    .partner_modal_body {
        padding-bottom: 60px;
        margin-top: 10px;

        .item-landing {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 12px;
            border: 1px solid #fff;
            background: #f3f3f3;
            position: relative;

            ::first-child {
                border-radius: 12px 12px 0px 0px;
            }

            ::last-child {
                border-radius: 0px 0px 12px 12px;
            }

            img {
                width: 33px;
                height: 33px;
                object-fit: cover;
                border-radius: 50%;
            }

            span {
                color: #444;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 10px;
            }

            .tick {
                display: none;
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
            }

            &.active {
                border: 1px solid #fff;
                background: #f7eaea;

                .tick {
                    display: block;
                }
                span {
                    color: #b3282d;
                }
            }
        }
    }

    .box-footer-modal {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #fff;
        height: 40px;

        .partner_modal_tab {
            height: 40px;
            display: flex;

            .partner_modal_tab-item {
                height: 40px;
                width: 50%;
                background: #f8f8f8;
                color: #7a7a7a;
                font-family: Lexend;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                text-align: center;
                position: relative;

                :first-child {
                    border-radius: 0 0 10px 0;
                }
                :last-child {
                    border-radius: 0 0 0 10px;
                }

                .partner_modal_tab-item-line {
                    display: none;
                }

                &.active {
                    background-color: #fff;
                    color: #b3282d;

                    .partner_modal_tab-item-line {
                        display: block;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 4px;
                        background: #b3282d;
                        left: 0;
                        z-index: 5;
                    }
                }
            }
        }
    }
`;

export default ModalChangeLandingPage;
