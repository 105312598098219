import Common from "../Common";
import RequestEcom from "../RequestEcom";

const BaseEcomServices = {
    isContainPage: () => {
        let excludes = ["/login", "/register", "/forgot-password", "/waiting-for-verification", "/af", "/login-social-callback"];
        let isHomepage = window.location.pathname === "/";
        let isContain = excludes.some((x) => window.location.href.includes(x));
        return isContain;
    },
    Get: async (url) => {
        try {
            let isInAppBrowser = Common.isInAppBrowser();
            var res = null;
            if (!isInAppBrowser) {
                res = await RequestEcom.Get(url);
                if (res.success) return [null, res.data];
                return [res, null];
            }
            let token = Common.GetToken();
            if (!token) window.location.href = "/login?redirect=" + window.location.href;
            else {
                res = await RequestEcom.Get(url);
                if (res.success) return [null, res.data];
                return [res, null];
            }
        } catch (err) {
            return [err, null];
        }
    },
    Post: async (url, params) => {
        try {
            let isInAppBrowser = Common.isInAppBrowser();
            var res = null;
            if (!isInAppBrowser) {
                res = await RequestEcom.Post(url, params);
                if (res.success) return [null, res.data];
                return [res, null];
            }
            let token = Common.GetToken();
            if (!token) window.location.href = "/login?redirect=" + window.location.href;
            else {
                res = await RequestEcom.Post(url, params);
                if (res.success) return [null, res.data];
                return [res, null];
            }
        } catch (err) {
            return [err, null];
        }
    },
    Delete: async (url) => {
        try {
            const res = await RequestEcom.Delete(url);
            if (res.success) return [null, res.data];
            return [res, null];
        } catch (err) {
            return [err, null];
        }
    },
};

export default BaseEcomServices;
