import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden !important;
    touch-action: none;
  }
`;

export default GlobalStyle;
